import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Authority } from 'src/app/shared/models';

export const AuthorityActions = createActionGroup({
  source: 'Authority',
  events: {
    'Load Authorities': emptyProps(),
    'Load Authorities Success': props<{ authorities: Authority[] }>(),
    'Load Authorities Failure': props<{ error: any }>(),
    'Load Authority': props<{ authorityId: string }>(),
    'Load Authority Success': props<{ authority: Authority }>(),
    'Load Authority Failure': props<{ error: any }>(),
    'Add Authority': props<{ authority: Authority }>(),
    'Add Authority Success': props<{ authority: Authority }>(),
    'Add Authority Failure': props<{ error: any }>(),
    'Remove Authority': props<{ authorityId: string }>(),
    'Remove Authority Success': props<{ authorityId: string }>(),
    'Remove Authority Failure': props<{ error: any }>(),
    'Update Authority': props<{
      authorityId: string;
      authority: Partial<Authority>;
    }>(),
    'Update Authority Success': props<{
      authorityId: string;
      authority: Partial<Authority>;
    }>(),
    'Update Authority Failure': props<{ error: any }>(),
  },
});
