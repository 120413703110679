import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  getIdTokenResult,
  User as AuthUser,
  ParsedToken,
  GoogleAuthProvider,
  signInWithPopup,
  AuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithCustomToken,
  Unsubscribe,
  UserCredential,
} from '@angular/fire/auth';
import { from, Observable, ReplaySubject, throwError } from 'rxjs';
import { switchMap, take, takeWhile } from 'rxjs/operators';
import {
  BaseUser,
  UserType,
  userTypeFromJSON,
  userTypeToJSON,
} from '../models';
import { Auth } from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConsumerActions } from '../store/consumer/+state/consumer.actions';
import { LeadSellerActions } from '../store/lead-seller/+state/lead-seller.actions';
import { AgentActions } from '../store/agent/+state/agent.actions';
import { AuthorityActions } from '../store/authority/+state/authority.actions';
import { AgencyActions } from '../store/agency/+state/agency.actions';
import { CarrierActions } from '../store/carrier/+state/carrier.actions';
import { environment } from 'src/environments/environment';
import { getWindow } from 'ssr-window';
import { Firestore, doc, onSnapshot } from '@angular/fire/firestore';
import { CookieService } from './cookie.service';
import { EmployeeActions } from '../store/employee/+state/employee.actions';
import { isPlatformBrowser } from '@angular/common';

export interface UserInfo {
  user: AuthUser;
  claims: ParsedToken;
  type: UserType;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: ReplaySubject<UserInfo | null> = new ReplaySubject(1);

  user: AuthUser | null = null;
  type?: UserType;

  tokenUpdateSubscription?: Unsubscribe;

  _lastClaimChange?: number;
  _currentUserSubscription?: {
    unsubscribe: () => void;
    userId: string;
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private auth: Auth,
    private cookieService: CookieService,
    private functions: Functions,
    private firestore: Firestore,
    private router: Router,
    private store: Store
  ) {
    // this.user$ = new ReplaySubject(subscriber => {

    this.user$.subscribe(user => {
      this.user = user?.user || null;
      this.type = user?.type;
      console.log('USER', user?.user?.uid, this.user);

      // necessary because ngrx store is not ready yet
      setTimeout(() => {
        if (user && this.user?.uid) {
          console.log(
            'loadUser',
            user.type ? userTypeToJSON(user.type) : 'unset'
          );
          switch (user.type) {
            case UserType.CONSUMER:
              this.store.dispatch(
                ConsumerActions.loadConsumer({ consumerId: this.user.uid })
              );
              break;
            case UserType.AGENT:
              this.store.dispatch(
                AgentActions.loadAgent({ agentId: this.user.uid })
              );
              break;
            case UserType.EMPLOYEE:
              this.store.dispatch(
                EmployeeActions.loadEmployee({ employeeId: this.user.uid })
              );
              break;
            case UserType.CARRIER:
              this.store.dispatch(
                CarrierActions.loadCarrier({ carrierId: this.user.uid })
              );
              break;
            case UserType.LEADSELLER:
              this.store.dispatch(
                LeadSellerActions.loadLeadSeller({
                  leadsellerId: this.user.uid,
                })
              );
              break;
            case UserType.AGENCY:
              console.log('loadAgency', this.user.uid);
              this.store.dispatch(
                AgencyActions.loadAgency({ agencyId: this.user.uid })
              );
              break;
            case UserType.AUTHORITY:
              this.store.dispatch(
                AuthorityActions.loadAuthority({ authorityId: this.user.uid })
              );
              break;
          }
        }
      }, 100);
    });

    if (
      this.tokenUpdateSubscription &&
      typeof this.tokenUpdateSubscription === 'function'
    ) {
      this.tokenUpdateSubscription();
    }
    this.tokenUpdateSubscription = onAuthStateChanged(auth, async user => {
      console.log('onAuthStateChanged', user);
      if (user) {
        const tokenResult = await getIdTokenResult(user);
        console.log('tokenResult', tokenResult);
        const type = userTypeFromJSON(tokenResult.claims['userType']);
        this.user$.next({ user: user, claims: tokenResult.claims, type });

        if (
          !this._currentUserSubscription ||
          this._currentUserSubscription.userId !== user.uid
        ) {
          if (
            this._currentUserSubscription &&
            typeof this._currentUserSubscription.unsubscribe === 'function'
          ) {
            this._currentUserSubscription.unsubscribe();
          }

          this._currentUserSubscription = {
            unsubscribe: onSnapshot(
              doc(this.firestore, 'users', user.uid),
              snapshot => {
                const data = snapshot.data();
                console.log('USERDATA', data, this._lastClaimChange);

                if (
                  data?.['lastClaimChange'] &&
                  this._lastClaimChange !== data?.['lastClaimChange']
                ) {
                  console.log('RELOAD', user);
                  user.getIdToken(true);
                }

                this._lastClaimChange = data?.['lastClaimChange'];
              }
            ),
            userId: user.uid,
          };
        }
      } else {
        this.user$.next(null);
      }
    });
  }

  myUserId = (): string | null => {
    return this.user ? this.user.uid : null;
  };

  checkEmail(email: string): Observable<boolean> {
    return from(fetchSignInMethodsForEmail(this.auth, email)).pipe(
      switchMap(methods => {
        console.log('methods', methods);
        return methods.length > 0 ? [true] : [false];
      })
    );
  }

  getSignInMethodsForEmail(email: string): Observable<string[]> {
    return from(fetchSignInMethodsForEmail(this.auth, email)).pipe(
      switchMap(methods => {
        console.log('methods', methods);
        return [methods];
      })
    );
  }

  registerWithEmailLink(email: string, path?: string): Observable<void> {
    const window = getWindow();
    const currentDomain = window.location.href.split('/')[2];

    const currentLink =
      'https://' + currentDomain + (path ?? '/login/complete');

    console.log('currentLink', currentLink);

    return from(
      sendSignInLinkToEmail(this.auth, email, {
        url: currentLink,
        handleCodeInApp: true,
      }).then(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.localStorage.setItem('emailForSignIn', email);
        }
      })
    );
  }

  sendEmailLink(email: string, path?: string): Observable<void> {
    const window = getWindow();
    const currentDomain = window.location.href.split('/')[2];

    const currentLink =
      'https://' + currentDomain + (path ?? '/login/complete');

    return from(
      sendSignInLinkToEmail(this.auth, email, {
        url: currentLink,
        handleCodeInApp: true,
      }).then(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.localStorage.setItem('emailForSignIn', email);
        }
      })
    );
  }

  registerWithEmailLinkComplete = (
    email: string,
    type: UserType
  ): Promise<void> =>
    new Promise((resolve, reject) => {
      const window = getWindow();

      this.deleteCurrentUser();

      signInWithEmailLink(this.auth, email, window.location.href)
        .then(() => {
          // Clear email from storage.

          this._registerSuccess(type, email)
            .then(() => {
              if (isPlatformBrowser(this.platformId)) {
                window.localStorage.removeItem('emailForSignIn');
              }
              resolve();
            })
            .catch(reject);

          // res.user.getIdTokenResult().then(token => {
          //   const user = {
          //     user: res.user,
          //     claims: token.claims,
          //     type: userTypeFromJSON(token.claims['userType']),
          //   };

          //   resolve(user);
          // });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  signInWithEmailLink = (email: string): Promise<UserInfo> =>
    new Promise((resolve, reject) => {
      const window = getWindow();

      this.deleteCurrentUser();

      signInWithEmailLink(this.auth, email, window.location.href)
        .then(res => {
          // Clear email from storage.
          if (isPlatformBrowser(this.platformId)) {
            window.localStorage.removeItem('emailForSignIn');
          }

          res.user.getIdTokenResult().then(token => {
            const user = {
              user: res.user,
              claims: token.claims,
              type: userTypeFromJSON(token.claims['userType']),
            };

            resolve(user);
          });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });

  signUp(
    email: string,
    password: string,
    type: UserType
  ): Observable<UserInfo> {
    return from(
      createUserWithEmailAndPassword(this.auth, email, password)
    ).pipe(
      switchMap(async userCredential => {
        try {
          await this._registerSuccess(type, email);
        } catch (e) {
          throw throwError(() => new Error('User created'));
        }
        return { user: userCredential.user, claims: { type }, type };
      })
    );
  }

  signUpWithGoogle(type: UserType): Observable<UserInfo> {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    return this._signUpWithProvider(provider, type);
  }

  signUpWithFacebook(type: UserType): Observable<UserInfo> {
    const provider = new FacebookAuthProvider();
    return this._signUpWithProvider(provider, type);
  }

  signUpWithApple = (type: UserType): Observable<UserInfo> => {
    const provider = new OAuthProvider('apple.com');
    return this._signUpWithProvider(provider, type);
  };

  private _signUpWithProvider(
    provider: AuthProvider,
    type: UserType
  ): Observable<UserInfo> {
    return new Observable(subscriber => {
      signInWithPopup(this.auth, provider).then(userCredential => {
        // return from(signInWithPopup(this.auth, provider)).pipe(
        // switchMap(async userCredential => {
        console.log('userCredential', userCredential);
        this._registerSuccess(
          type,
          userCredential.user.email ?? undefined
        ).then(() => {
          console.log('registerSuccess');

          subscriber.next({
            user: userCredential.user,
            claims: { type: type },
            type,
          });
        });
        // );
      });
    });
  }

  signInWithPassword(email: string, password: string): Observable<UserInfo> {
    return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
      switchMap(async userCredential => {
        const tokenResult = await getIdTokenResult(userCredential.user);
        return {
          user: userCredential.user,
          claims: tokenResult.claims,
          type: userTypeFromJSON(tokenResult.claims['userType']),
        };
      })
    );
  }

  signInWithGoogle(): Observable<UserInfo> {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    return this._signInWithProvider(provider);
  }

  signInWithFacebook(): Observable<UserInfo> {
    const provider = new FacebookAuthProvider();
    return this._signInWithProvider(provider);
  }

  signInWithApple = (): Observable<UserInfo> => {
    const provider = new OAuthProvider('apple.com');
    return this._signInWithProvider(provider);
  };

  private _signInWithProvider(provider: AuthProvider): Observable<UserInfo> {
    this.deleteCurrentUser();
    return from(signInWithPopup(this.auth, provider)).pipe(
      switchMap(async userCredential => {
        const tokenResult = await getIdTokenResult(userCredential.user);
        return {
          user: userCredential.user,
          claims: tokenResult.claims,
          type: userTypeFromJSON(tokenResult.claims['type']),
        };
      })
    );
  }

  private _registerSuccess = (type: UserType, email?: string): Promise<void> =>
    new Promise((resolve, reject) => {
      console.log('registerSuccess');
      const registerSuccess = httpsCallable(
        this.functions,
        'authRegisterSuccess'
      );
      this.deleteCurrentUser();
      registerSuccess({ userType: userTypeToJSON(type), email })
        .then(res => {
          console.log('authRegisterSuccess', res);

          this.auth.currentUser?.getIdToken(true).then(() => {
            switch (type) {
              case UserType.CONSUMER:
                this.router.navigate(['/consumer/enroll/phone']);
                break;
              case UserType.LEADSELLER:
                this.router.navigate(['/lead-seller/register/phone']);
                break;
              case UserType.AGENT:
                this.router.navigate(['/agent']);
                break;
            }
            resolve();
          });
        })
        .catch(err => {
          reject(err);
        });
    });

  logIn(email: string, password: string): Observable<UserInfo> {
    return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
      switchMap(async userCredential => {
        const tokenResult = await getIdTokenResult(userCredential.user);
        return {
          user: userCredential.user,
          claims: tokenResult.claims,
          type: userTypeFromJSON(tokenResult.claims['type']),
        };
      })
    );
  }

  logout(): Observable<void> {
    this.cookieService.delete('currentUser', { crossSubdomain: true });

    return from(signOut(this.auth));
  }

  getExternalToken = (target: string) =>
    new Promise<{ url?: string }>((resolve, reject) => {
      const window = getWindow();
      const isLocal = window.location.href.includes('localhost');

      this.currentUser$.pipe(take(1)).subscribe(currentUser => {
        const generateToken = httpsCallable(this.functions, 'generateToken');
        generateToken({ target, isLocal })
          .then(res => {
            const data = res.data as { url?: string };
            console.log('generateToken', res);

            if (data?.url && (environment.isDemo || !environment.production)) {
              const item = this.cookieService.get('demo-pass');
              if (item) {
                const baseData = btoa(JSON.stringify(item));
                data.url += '&d=' + baseData;
              }
            }

            if (currentUser) {
              console.log('currentUser', currentUser);
              console.log('currentUserBase', BaseUser.toJSON(currentUser));
              const baseData = btoa(
                JSON.stringify(BaseUser.toJSON(currentUser))
              );
              data.url += '&c=' + baseData;
            }

            resolve(data);
          })
          .catch(reject);
      });
    });

  signInWithToken = (token: string) =>
    new Promise<UserCredential | null>((resolve, reject) => {
      signInWithCustomToken(this.auth, token)
        .then(r => {
          resolve(r);
        })
        .catch(err => reject(err));
    });

  private currentUser?: BaseUser;
  currentUser$ = new ReplaySubject<BaseUser>(1);
  getCurrentUser = (): Promise<BaseUser> =>
    new Promise(resolve => {
      if (this.currentUser) {
        resolve(this.currentUser);
      } else {
        const savedUser = this.cookieService.get('currentUser');

        if (savedUser) {
          const user = BaseUser.fromJSON(savedUser);

          this.user$
            .pipe(takeWhile(u => !u?.user?.uid, true))
            .subscribe(authUser => {
              if (authUser?.user.uid) {
                console.log('CURRENT USER', authUser);
                // const claims = authUser?.claims as {
                //   [key: string]: { [key: string]: { active: boolean } };
                // };
                // if (user.id && claims['permissions'][user.id]) {
                if (user.id) {
                  this.currentUser = user;
                  this.currentUser$.next(user);
                } else {
                  this.setCurrentUser(undefined, undefined, true);
                }
              }
            });

          // this.setCurrentUser(user.id, user);
          resolve(user);
        } else {
          this.setCurrentUser(undefined, undefined, true);
          // this.user$
          //   .pipe(takeWhile(user => !user, true))
          //   .subscribe(async user => {
          //     if (user?.user) {
          //       this.setCurrentUser(user.user.uid, undefined, true);
          //       resolve({ userType: user.type, id: user.user.uid });
          //     }
          //   });
        }
      }
    });

  setCurrentUser = (userId?: string, user?: BaseUser, isAuthUser = false) =>
    new Promise(resolve => {
      if (!isAuthUser && !user?.userType) {
        console.error('userType missing');
      }
      if (userId && user && !isAuthUser) {
        this.cookieService.set(
          'currentUser',
          BaseUser.toJSON({ ...user, id: userId }),
          { crossSubdomain: true }
        );

        const baseUser = { ...user, id: userId };
        this.currentUser = baseUser;
        this.currentUser$.next(baseUser);
        resolve(baseUser);

        // const homePath = getHomePath(user?.userType);
        // if (homePath) {
        //   this.router.navigate([homePath]);
        // }
      } else {
        this.cookieService.delete('currentUser', { crossSubdomain: true });
        this.user$
          .pipe(takeWhile(user => !user?.user, true))
          .subscribe(async user => {
            if (user?.user) {
              const baseUser = { userType: user.type, id: user.user.uid };
              console.log('setCurrentUser', baseUser);
              this.currentUser = baseUser;
              this.currentUser$.next(baseUser);
              resolve(baseUser);
            }
          });
      }
      // this.store.dispatch(
      //   UserActions.updateAgent({
      //     agentId: this.user.id,
      //     agent: {
      //       currentAgency: {
      //         id: agency.id,
      //         name: agency.name,
      //       },
      //     },
      //   })
      // );
    });

  deleteCurrentUser = () => {
    // this.cookieService.delete('currentUser', { crossSubdomain: true });
    this.setCurrentUser(undefined, undefined, true);
  };
}
