/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { SignaturePeriod, signaturePeriodFromJSON, signaturePeriodToJSON } from "./enrollment";
import { Struct } from "./google/protobuf/struct";
import { Timestamp } from "./google/protobuf/timestamp";
import { Address, Features } from "./misc";

export const protobufPackage = "";

export interface SfWe {
}

export enum SfWe_PolicyStatusCategory {
  PSCAT_UNKNOWN = 0,
  PCAT_ACTIVE = 1,
  PCAT_INACTIVE = 2,
  UNRECOGNIZED = -1,
}

export function sfWe_PolicyStatusCategoryFromJSON(object: any): SfWe_PolicyStatusCategory {
  switch (object) {
    case 0:
    case "PSCAT_UNKNOWN":
      return SfWe_PolicyStatusCategory.PSCAT_UNKNOWN;
    case 1:
    case "PCAT_ACTIVE":
      return SfWe_PolicyStatusCategory.PCAT_ACTIVE;
    case 2:
    case "PCAT_INACTIVE":
      return SfWe_PolicyStatusCategory.PCAT_INACTIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SfWe_PolicyStatusCategory.UNRECOGNIZED;
  }
}

export function sfWe_PolicyStatusCategoryToJSON(object: SfWe_PolicyStatusCategory): string {
  switch (object) {
    case SfWe_PolicyStatusCategory.PSCAT_UNKNOWN:
      return "PSCAT_UNKNOWN";
    case SfWe_PolicyStatusCategory.PCAT_ACTIVE:
      return "PCAT_ACTIVE";
    case SfWe_PolicyStatusCategory.PCAT_INACTIVE:
      return "PCAT_INACTIVE";
    case SfWe_PolicyStatusCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SfWe_PolicyStatus {
  POL_UNKNOWN = 0,
  POL_PENDING = 1,
  POL_SUBMITTED = 2,
  POL_PAID = 21,
  POL_APPROVED = 22,
  POL_INFORCE = 23,
  POL_LAPSED = 41,
  POL_TERMINATED = 42,
  POL_DECEASED = 43,
  POL_LOST = 61,
  POL_REVERSED = 62,
  POL_DECLINED = 63,
  UNRECOGNIZED = -1,
}

export function sfWe_PolicyStatusFromJSON(object: any): SfWe_PolicyStatus {
  switch (object) {
    case 0:
    case "POL_UNKNOWN":
      return SfWe_PolicyStatus.POL_UNKNOWN;
    case 1:
    case "POL_PENDING":
      return SfWe_PolicyStatus.POL_PENDING;
    case 2:
    case "POL_SUBMITTED":
      return SfWe_PolicyStatus.POL_SUBMITTED;
    case 21:
    case "POL_PAID":
      return SfWe_PolicyStatus.POL_PAID;
    case 22:
    case "POL_APPROVED":
      return SfWe_PolicyStatus.POL_APPROVED;
    case 23:
    case "POL_INFORCE":
      return SfWe_PolicyStatus.POL_INFORCE;
    case 41:
    case "POL_LAPSED":
      return SfWe_PolicyStatus.POL_LAPSED;
    case 42:
    case "POL_TERMINATED":
      return SfWe_PolicyStatus.POL_TERMINATED;
    case 43:
    case "POL_DECEASED":
      return SfWe_PolicyStatus.POL_DECEASED;
    case 61:
    case "POL_LOST":
      return SfWe_PolicyStatus.POL_LOST;
    case 62:
    case "POL_REVERSED":
      return SfWe_PolicyStatus.POL_REVERSED;
    case 63:
    case "POL_DECLINED":
      return SfWe_PolicyStatus.POL_DECLINED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SfWe_PolicyStatus.UNRECOGNIZED;
  }
}

export function sfWe_PolicyStatusToJSON(object: SfWe_PolicyStatus): string {
  switch (object) {
    case SfWe_PolicyStatus.POL_UNKNOWN:
      return "POL_UNKNOWN";
    case SfWe_PolicyStatus.POL_PENDING:
      return "POL_PENDING";
    case SfWe_PolicyStatus.POL_SUBMITTED:
      return "POL_SUBMITTED";
    case SfWe_PolicyStatus.POL_PAID:
      return "POL_PAID";
    case SfWe_PolicyStatus.POL_APPROVED:
      return "POL_APPROVED";
    case SfWe_PolicyStatus.POL_INFORCE:
      return "POL_INFORCE";
    case SfWe_PolicyStatus.POL_LAPSED:
      return "POL_LAPSED";
    case SfWe_PolicyStatus.POL_TERMINATED:
      return "POL_TERMINATED";
    case SfWe_PolicyStatus.POL_DECEASED:
      return "POL_DECEASED";
    case SfWe_PolicyStatus.POL_LOST:
      return "POL_LOST";
    case SfWe_PolicyStatus.POL_REVERSED:
      return "POL_REVERSED";
    case SfWe_PolicyStatus.POL_DECLINED:
      return "POL_DECLINED";
    case SfWe_PolicyStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SfWe_PlanCategory {
  PCAT_UNKNOWN = 0,
  PCAT_ACCIDENT = 1,
  PCAT_ACCIDENTAL_DEATH = 2,
  PCAT_ACA = 3,
  PCAT_CANCER = 4,
  PCAT_CANCER_HEART_STROKE = 5,
  PCAT_DENTAL = 6,
  PCAT_FIXED_INDEXED = 7,
  PCAT_FIXED = 8,
  PCAT_GUARANTEED_UNIVERSAL = 9,
  PCAT_HOSPITAL_INDEMNITY = 10,
  PCAT_INDEXED_UNIVERSAL = 11,
  PCAT_TERM = 12,
  PCAT_UNIVERSAL = 13,
  PCAT_VISION = 14,
  PCAT_WHOLE = 15,
  UNRECOGNIZED = -1,
}

export function sfWe_PlanCategoryFromJSON(object: any): SfWe_PlanCategory {
  switch (object) {
    case 0:
    case "PCAT_UNKNOWN":
      return SfWe_PlanCategory.PCAT_UNKNOWN;
    case 1:
    case "PCAT_ACCIDENT":
      return SfWe_PlanCategory.PCAT_ACCIDENT;
    case 2:
    case "PCAT_ACCIDENTAL_DEATH":
      return SfWe_PlanCategory.PCAT_ACCIDENTAL_DEATH;
    case 3:
    case "PCAT_ACA":
      return SfWe_PlanCategory.PCAT_ACA;
    case 4:
    case "PCAT_CANCER":
      return SfWe_PlanCategory.PCAT_CANCER;
    case 5:
    case "PCAT_CANCER_HEART_STROKE":
      return SfWe_PlanCategory.PCAT_CANCER_HEART_STROKE;
    case 6:
    case "PCAT_DENTAL":
      return SfWe_PlanCategory.PCAT_DENTAL;
    case 7:
    case "PCAT_FIXED_INDEXED":
      return SfWe_PlanCategory.PCAT_FIXED_INDEXED;
    case 8:
    case "PCAT_FIXED":
      return SfWe_PlanCategory.PCAT_FIXED;
    case 9:
    case "PCAT_GUARANTEED_UNIVERSAL":
      return SfWe_PlanCategory.PCAT_GUARANTEED_UNIVERSAL;
    case 10:
    case "PCAT_HOSPITAL_INDEMNITY":
      return SfWe_PlanCategory.PCAT_HOSPITAL_INDEMNITY;
    case 11:
    case "PCAT_INDEXED_UNIVERSAL":
      return SfWe_PlanCategory.PCAT_INDEXED_UNIVERSAL;
    case 12:
    case "PCAT_TERM":
      return SfWe_PlanCategory.PCAT_TERM;
    case 13:
    case "PCAT_UNIVERSAL":
      return SfWe_PlanCategory.PCAT_UNIVERSAL;
    case 14:
    case "PCAT_VISION":
      return SfWe_PlanCategory.PCAT_VISION;
    case 15:
    case "PCAT_WHOLE":
      return SfWe_PlanCategory.PCAT_WHOLE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SfWe_PlanCategory.UNRECOGNIZED;
  }
}

export function sfWe_PlanCategoryToJSON(object: SfWe_PlanCategory): string {
  switch (object) {
    case SfWe_PlanCategory.PCAT_UNKNOWN:
      return "PCAT_UNKNOWN";
    case SfWe_PlanCategory.PCAT_ACCIDENT:
      return "PCAT_ACCIDENT";
    case SfWe_PlanCategory.PCAT_ACCIDENTAL_DEATH:
      return "PCAT_ACCIDENTAL_DEATH";
    case SfWe_PlanCategory.PCAT_ACA:
      return "PCAT_ACA";
    case SfWe_PlanCategory.PCAT_CANCER:
      return "PCAT_CANCER";
    case SfWe_PlanCategory.PCAT_CANCER_HEART_STROKE:
      return "PCAT_CANCER_HEART_STROKE";
    case SfWe_PlanCategory.PCAT_DENTAL:
      return "PCAT_DENTAL";
    case SfWe_PlanCategory.PCAT_FIXED_INDEXED:
      return "PCAT_FIXED_INDEXED";
    case SfWe_PlanCategory.PCAT_FIXED:
      return "PCAT_FIXED";
    case SfWe_PlanCategory.PCAT_GUARANTEED_UNIVERSAL:
      return "PCAT_GUARANTEED_UNIVERSAL";
    case SfWe_PlanCategory.PCAT_HOSPITAL_INDEMNITY:
      return "PCAT_HOSPITAL_INDEMNITY";
    case SfWe_PlanCategory.PCAT_INDEXED_UNIVERSAL:
      return "PCAT_INDEXED_UNIVERSAL";
    case SfWe_PlanCategory.PCAT_TERM:
      return "PCAT_TERM";
    case SfWe_PlanCategory.PCAT_UNIVERSAL:
      return "PCAT_UNIVERSAL";
    case SfWe_PlanCategory.PCAT_VISION:
      return "PCAT_VISION";
    case SfWe_PlanCategory.PCAT_WHOLE:
      return "PCAT_WHOLE";
    case SfWe_PlanCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SfWe_BaseCategory {
  BC_UNKNOWN = 0,
  BC_MEDICARE = 1,
  BC_LIFE = 2,
  BC_HEALTH = 3,
  BC_ANNUITY = 4,
  UNRECOGNIZED = -1,
}

export function sfWe_BaseCategoryFromJSON(object: any): SfWe_BaseCategory {
  switch (object) {
    case 0:
    case "BC_UNKNOWN":
      return SfWe_BaseCategory.BC_UNKNOWN;
    case 1:
    case "BC_MEDICARE":
      return SfWe_BaseCategory.BC_MEDICARE;
    case 2:
    case "BC_LIFE":
      return SfWe_BaseCategory.BC_LIFE;
    case 3:
    case "BC_HEALTH":
      return SfWe_BaseCategory.BC_HEALTH;
    case 4:
    case "BC_ANNUITY":
      return SfWe_BaseCategory.BC_ANNUITY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SfWe_BaseCategory.UNRECOGNIZED;
  }
}

export function sfWe_BaseCategoryToJSON(object: SfWe_BaseCategory): string {
  switch (object) {
    case SfWe_BaseCategory.BC_UNKNOWN:
      return "BC_UNKNOWN";
    case SfWe_BaseCategory.BC_MEDICARE:
      return "BC_MEDICARE";
    case SfWe_BaseCategory.BC_LIFE:
      return "BC_LIFE";
    case SfWe_BaseCategory.BC_HEALTH:
      return "BC_HEALTH";
    case SfWe_BaseCategory.BC_ANNUITY:
      return "BC_ANNUITY";
    case SfWe_BaseCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SfWe_Plan {
  id: string;
  altLanguageFmt?: string | undefined;
  eSignatureAgentESignatureChoices?:
    | string
    | undefined;
  /** ["email","none","sms"] */
  electionPeriod?:
    | string
    | undefined;
  /** ["False","True","undefined"] */
  ethnicityMultipleNoAnswer?:
    | boolean
    | undefined;
  /** ["False","True","undefined"] */
  ethnicityMultipleNotHispanic?:
    | boolean
    | undefined;
  /** ["bookOfBusiness","marketing","thirdParty","undefined"] */
  leadSource?:
    | string
    | undefined;
  /** ["N","Y","undefined"] */
  ltcHas?:
    | boolean
    | undefined;
  /** ["N","Y","undefined"] */
  okToEmail?: boolean | undefined;
  medicaid?: SfWe_Plan_Medicaid | undefined;
  otherDrugCoverage?: SfWe_Plan_OtherDrugCoverage | undefined;
  otherGroupCoverage?: SfWe_Plan_OtherGroupCoverage | undefined;
  partADate?: Date | undefined;
  partATimestamp?: number | undefined;
  partADateDay?: number | undefined;
  partADateMonth?: number | undefined;
  partADateYear?: number | undefined;
  partBDate?: Date | undefined;
  partBTimestamp?: number | undefined;
  partBDateDay?: number | undefined;
  partBDateMonth?: number | undefined;
  partBDateYear?:
    | number
    | undefined;
  /** ["BANK_ACCOUNT","DEDUCTION_RR","DEDUCTION_SS","DIRECT"] */
  paymentType?: string | undefined;
  pcp?: SfWe_Plan_PCP | undefined;
  plan?:
    | SfWe_Plan_Plan
    | undefined;
  /** ["False","True","undefined"] */
  raceMultipleAfricanAmerican?:
    | boolean
    | undefined;
  /** ["False","True","undefined"] */
  raceMultipleNoAnswer?:
    | boolean
    | undefined;
  /** ["False","True","undefined"] */
  raceMultipleWhite?: boolean | undefined;
  signatureDate?: Date | undefined;
  signatureTimestamp?: number | undefined;
  signatureDateDay?: number | undefined;
  signatureDateMonth?: number | undefined;
  signatureDateYear?:
    | number
    | undefined;
  /** ["NOT_VETERAN","NO_ANSWER","SELF","SPOUSE","undefined"] */
  veteranStatus?:
    | string
    | undefined;
  /** ["N","Y","undefined"] */
  work?: boolean | undefined;
  metadata?: SfWe_Plan_Metadata | undefined;
  enrollmentData?: SfWe_Plan_EnrollmentData | undefined;
  parameters?: SfWe_Plan_Parameters | undefined;
  webhookType?: string | undefined;
  webhookReceived?: Date | undefined;
  webhookReceivedTimestamp?: number | undefined;
  consumer?: SfWe_Consumer | undefined;
  agent?: SfWe_Agent | undefined;
  user?: SfWe_Plan_User | undefined;
  agency?: SfWe_Plan_User | undefined;
  carrier?: SfWe_Plan_User | undefined;
  raw?: { [key: string]: any } | undefined;
  baseCategory?: SfWe_BaseCategory | undefined;
  otherMedicareCoverage?: SfWe_Plan_OtherMedicareCoverage | undefined;
  chf?: SfWe_Plan_Chf | undefined;
  copd?: SfWe_Plan_Copd | undefined;
  cvd?: SfWe_Plan_Cvd | undefined;
  diabetes?: SfWe_Plan_Diabetes | undefined;
  medicare?: SfWe_Plan_Medicare | undefined;
  premium?: SfWe_Plan_Premium | undefined;
  commissions?: SfWe_Plan_Commissions | undefined;
  signaturePeriod?: SignaturePeriod | undefined;
  allegation?: SfWe_Plan_Allegation | undefined;
  marketingVendor?: SfWe_Plan_User | undefined;
}

export interface SfWe_Plan_User {
  id?: string | undefined;
}

export interface SfWe_Plan_Chf {
  has?: boolean | undefined;
  hasBodyFluid?: boolean | undefined;
  hasLungFluid?: boolean | undefined;
  drugsLungFluidString?: string | undefined;
}

export interface SfWe_Plan_Copd {
  has?: boolean | undefined;
  hasAsthma?: boolean | undefined;
  hasDifficultyBreathing?: boolean | undefined;
  hasEmphysema?: boolean | undefined;
  needsOxygenSupport?: boolean | undefined;
  drugsBreathing?: string | undefined;
}

export interface SfWe_Plan_Cvd {
  has?: boolean | undefined;
  hadHeartAttack?: boolean | undefined;
  hasChestLegPain?: boolean | undefined;
  drugsHeart?: boolean | undefined;
}

export interface SfWe_Plan_Diabetes {
  has?: boolean | undefined;
  checksBloodSugar?: boolean | undefined;
  hasHighBloodSugar?: boolean | undefined;
  drugsBloodSugar?: boolean | undefined;
}

export interface SfWe_Plan_ESRD {
  has?: boolean | undefined;
}

export interface SfWe_Plan_EnrollmentData {
  confirmationCode?:
    | string
    | undefined;
  /** TODO define enum */
  confirmationStatus?: string | undefined;
  enrollmentCode?: string | undefined;
  lastUpdatedDate?: Date | undefined;
  lastUpdatedTimestamp?: number | undefined;
  partnerId?: string | undefined;
  yearMonth?: string | undefined;
  policyStatusCategory?: SfWe_PolicyStatusCategory | undefined;
  policyStatus?: SfWe_PolicyStatus | undefined;
}

export interface SfWe_Plan_Medicaid {
  /** ["N","Y"] */
  has?: boolean | undefined;
  medicaidNumber?: string | undefined;
}

export interface SfWe_Plan_Medicare {
  partADate?: Date | undefined;
  partATimestamp?: number | undefined;
  partADateDay?: number | undefined;
  partADateMonth?: number | undefined;
  partADateYear?: number | undefined;
  partBDate?: Date | undefined;
  partBTimestamp?: number | undefined;
  partBDateDay?: number | undefined;
  partBDateMonth?: number | undefined;
  partBDateYear?: number | undefined;
}

export interface SfWe_Plan_Metadata {
  customerCode?: string | undefined;
  formType?: string | undefined;
  sfid?: string | undefined;
  sfmAppName?: string | undefined;
  startDate?: Date | undefined;
  startTimestamp?: number | undefined;
  url?: string | undefined;
}

export interface SfWe_Plan_OtherMedicareCoverage {
  /** ["N","Y"] */
  has?: boolean | undefined;
  droppingMedSupp?: string | undefined;
  endDate?: Date | undefined;
  endDateTimestamp?: number | undefined;
  firstTime?: string | undefined;
  name?: string | undefined;
  policyId?: string | undefined;
  startDate?: Date | undefined;
  startDateTimestamp?: number | undefined;
  stillCovered?: string | undefined;
}

export interface SfWe_Plan_OtherDrugCoverage {
  /** ["N","Y"] */
  has?: boolean | undefined;
  memberId?: string | undefined;
  memberName?: string | undefined;
  policyId?: string | undefined;
}

export interface SfWe_Plan_OtherGroupCoverage {
  /** ["N","Y"] */
  has?: boolean | undefined;
  name?: string | undefined;
  policyId?:
    | string
    | undefined;
  /** ["N","Y","undefined"] */
  coverageIncludesRx?: boolean | undefined;
  groupPolicyId?: string | undefined;
  address?: Address | undefined;
  carrier?:
    | SfWe_Plan_OtherGroupCoverage_Carrier
    | undefined;
  /** ["N","Y","undefined"] */
  coverageIncludesPrescriptionDrugCoverage?: boolean | undefined;
  memberId?: string | undefined;
}

export interface SfWe_Plan_OtherGroupCoverage_Carrier {
  id?: string | undefined;
  name?: string | undefined;
}

export interface SfWe_Plan_Parameters {
  ipAddress?: string | undefined;
  partnerId?: string | undefined;
}

export interface SfWe_Plan_PCP {
  /** ["N","Y","undefined"] */
  designatePCP?:
    | boolean
    | undefined;
  /** ["N","Y","undefined"] */
  establishedPatient?: boolean | undefined;
  id?: string | undefined;
  name?: string | undefined;
}

export interface SfWe_Plan_Plan {
  brandId?: string | undefined;
  brandName?: string | undefined;
  carrierId?: string | undefined;
  carrierName?: string | undefined;
  contractId?: string | undefined;
  displayId?: string | undefined;
  displayName?: string | undefined;
  effectiveDate?: Date | undefined;
  effectiveTimestamp?: number | undefined;
  effectiveDateDay?: number | undefined;
  effectiveDateMonth?: number | undefined;
  effectiveDateYear?:
    | number
    | undefined;
  /** applicant__plan__fmtPremium */
  fmtPremium?: string | undefined;
  id?: string | undefined;
  name?:
    | string
    | undefined;
  /** ["HMO","LPPO","PDP","PPO"] */
  planPolicyType?: string | undefined;
  premium?: number | undefined;
  providerDirectoryWebAddress?: string | undefined;
  segmentId?:
    | string
    | undefined;
  /** ["1","2","undefined"] */
  snp?:
    | string
    | undefined;
  /** ["MA","MAPD","PD"] */
  type?: string | undefined;
  year?: string | undefined;
  category?: SfWe_PlanCategory | undefined;
  annualPremium?: number | undefined;
  faceAmount?: number | undefined;
  modalPremium?: number | undefined;
}

export interface SfWe_Plan_Premium {
  deducted?: number | undefined;
  withhold?: number | undefined;
}

export interface SfWe_Plan_Commissions {
  paymentDescription?: string | undefined;
  amount?: number | undefined;
  statementDate?: Date | undefined;
  statementTimestamp?: number | undefined;
  statementDateDay?: number | undefined;
  statementDateMonth?: number | undefined;
  statementDateYear?: number | undefined;
  amountAgency?: number | undefined;
  amountAgent?: number | undefined;
}

export interface SfWe_Plan_Allegation {
  id?: string | undefined;
  active?: boolean | undefined;
  note?: string | undefined;
  result?: string | undefined;
  complaint?: string | undefined;
  date?: Date | undefined;
  dateDay?: number | undefined;
  dateMonth?: number | undefined;
  dateYear?: number | undefined;
  dateTimestamp?: number | undefined;
  dueDate?: Date | undefined;
  dueDateDay?: number | undefined;
  dueDateMonth?: number | undefined;
  dueDateYear?: number | undefined;
  dueDateTimestamp?: number | undefined;
}

export interface SfWe_Consumer {
  id: string;
  dateOfBirth?: Date | undefined;
  dateOfBirthTimestamp?: number | undefined;
  dateOfBirthDay?: number | undefined;
  dateOfBirthMonth?: number | undefined;
  dateOfBirthYear?: number | undefined;
  email?: string | undefined;
  firstName?: string | undefined;
  gender?: string | undefined;
  homeAddress?: Address | undefined;
  initial?: string | undefined;
  language?: string | undefined;
  lastName?: string | undefined;
  medicareNumber?: string | undefined;
  phone?: string | undefined;
  middleName?: string | undefined;
  nameSuffix?: string | undefined;
  hicn?: string | undefined;
}

export interface SfWe_Agent {
  /** agent__userName */
  id: string;
  affiliateTOH?: string | undefined;
  agentType?: string | undefined;
  blazeAffId?: string | undefined;
  blazeConnectAffId?: string | undefined;
  broker?: SfWe_Agent_Broker | undefined;
  carrierAgencies?:
    | string
    | undefined;
  /** agent__emailAddress */
  email?: string | undefined;
  emberAffId?: string | undefined;
  firstName?: string | undefined;
  isAppointed?: boolean | undefined;
  lastName?: string | undefined;
  npn?: string | undefined;
  partnerId?: string | undefined;
  ssn?: string | undefined;
  stateAbbr?:
    | string
    | undefined;
  /** agent__userName */
  username?: string | undefined;
  writingId?:
    | string
    | undefined;
  /** repeated KeyValueString writingIds = 19; // writingIds__y2023__dflt, writingIds__y2023__r88881220,  writingIds__y2023__r88881580,  writingIds__y2023__r88881700 */
  writingIds?: { [key: string]: any } | undefined;
  features?: Features | undefined;
  profileImage?: string | undefined;
  phone?: string | undefined;
  agencyId?: string | undefined;
}

export enum SfWe_Agent_AgentType {
  CALL_CENTER = 0,
  UNRECOGNIZED = -1,
}

export function sfWe_Agent_AgentTypeFromJSON(object: any): SfWe_Agent_AgentType {
  switch (object) {
    case 0:
    case "CALL_CENTER":
      return SfWe_Agent_AgentType.CALL_CENTER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SfWe_Agent_AgentType.UNRECOGNIZED;
  }
}

export function sfWe_Agent_AgentTypeToJSON(object: SfWe_Agent_AgentType): string {
  switch (object) {
    case SfWe_Agent_AgentType.CALL_CENTER:
      return "CALL_CENTER";
    case SfWe_Agent_AgentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SfWe_Agent_Broker {
  npn?: string | undefined;
  tin?: string | undefined;
  address?: Address | undefined;
  profileImage?: string | undefined;
}

export interface Report {
}

export enum Report_RefreshOption {
  RO_UNKNOWN = 0,
  UNRECOGNIZED = -1,
}

export function report_RefreshOptionFromJSON(object: any): Report_RefreshOption {
  switch (object) {
    case 0:
    case "RO_UNKNOWN":
      return Report_RefreshOption.RO_UNKNOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Report_RefreshOption.UNRECOGNIZED;
  }
}

export function report_RefreshOptionToJSON(object: Report_RefreshOption): string {
  switch (object) {
    case Report_RefreshOption.RO_UNKNOWN:
      return "RO_UNKNOWN";
    case Report_RefreshOption.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseSfWe(): SfWe {
  return {};
}

export const SfWe = {
  encode(_: SfWe, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SfWe {
    return {};
  },

  toJSON(_: SfWe): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe>, I>>(base?: I): SfWe {
    return SfWe.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe>, I>>(_: I): SfWe {
    const message = createBaseSfWe();
    return message;
  },
};

function createBaseSfWe_Plan(): SfWe_Plan {
  return {
    id: "",
    altLanguageFmt: undefined,
    eSignatureAgentESignatureChoices: undefined,
    electionPeriod: undefined,
    ethnicityMultipleNoAnswer: undefined,
    ethnicityMultipleNotHispanic: undefined,
    leadSource: undefined,
    ltcHas: undefined,
    okToEmail: undefined,
    medicaid: undefined,
    otherDrugCoverage: undefined,
    otherGroupCoverage: undefined,
    partADate: undefined,
    partATimestamp: undefined,
    partADateDay: undefined,
    partADateMonth: undefined,
    partADateYear: undefined,
    partBDate: undefined,
    partBTimestamp: undefined,
    partBDateDay: undefined,
    partBDateMonth: undefined,
    partBDateYear: undefined,
    paymentType: undefined,
    pcp: undefined,
    plan: undefined,
    raceMultipleAfricanAmerican: undefined,
    raceMultipleNoAnswer: undefined,
    raceMultipleWhite: undefined,
    signatureDate: undefined,
    signatureTimestamp: undefined,
    signatureDateDay: undefined,
    signatureDateMonth: undefined,
    signatureDateYear: undefined,
    veteranStatus: undefined,
    work: undefined,
    metadata: undefined,
    enrollmentData: undefined,
    parameters: undefined,
    webhookType: undefined,
    webhookReceived: undefined,
    webhookReceivedTimestamp: undefined,
    consumer: undefined,
    agent: undefined,
    user: undefined,
    agency: undefined,
    carrier: undefined,
    raw: undefined,
    baseCategory: undefined,
    otherMedicareCoverage: undefined,
    chf: undefined,
    copd: undefined,
    cvd: undefined,
    diabetes: undefined,
    medicare: undefined,
    premium: undefined,
    commissions: undefined,
    signaturePeriod: undefined,
    allegation: undefined,
    marketingVendor: undefined,
  };
}

export const SfWe_Plan = {
  encode(message: SfWe_Plan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.altLanguageFmt !== undefined) {
      writer.uint32(18).string(message.altLanguageFmt);
    }
    if (message.eSignatureAgentESignatureChoices !== undefined) {
      writer.uint32(26).string(message.eSignatureAgentESignatureChoices);
    }
    if (message.electionPeriod !== undefined) {
      writer.uint32(34).string(message.electionPeriod);
    }
    if (message.ethnicityMultipleNoAnswer !== undefined) {
      writer.uint32(40).bool(message.ethnicityMultipleNoAnswer);
    }
    if (message.ethnicityMultipleNotHispanic !== undefined) {
      writer.uint32(48).bool(message.ethnicityMultipleNotHispanic);
    }
    if (message.leadSource !== undefined) {
      writer.uint32(58).string(message.leadSource);
    }
    if (message.ltcHas !== undefined) {
      writer.uint32(64).bool(message.ltcHas);
    }
    if (message.okToEmail !== undefined) {
      writer.uint32(72).bool(message.okToEmail);
    }
    if (message.medicaid !== undefined) {
      SfWe_Plan_Medicaid.encode(message.medicaid, writer.uint32(82).fork()).ldelim();
    }
    if (message.otherDrugCoverage !== undefined) {
      SfWe_Plan_OtherDrugCoverage.encode(message.otherDrugCoverage, writer.uint32(90).fork()).ldelim();
    }
    if (message.otherGroupCoverage !== undefined) {
      SfWe_Plan_OtherGroupCoverage.encode(message.otherGroupCoverage, writer.uint32(98).fork()).ldelim();
    }
    if (message.partADate !== undefined) {
      Timestamp.encode(toTimestamp(message.partADate), writer.uint32(106).fork()).ldelim();
    }
    if (message.partATimestamp !== undefined) {
      writer.uint32(112).int64(message.partATimestamp);
    }
    if (message.partADateDay !== undefined) {
      writer.uint32(120).int32(message.partADateDay);
    }
    if (message.partADateMonth !== undefined) {
      writer.uint32(128).int32(message.partADateMonth);
    }
    if (message.partADateYear !== undefined) {
      writer.uint32(136).int32(message.partADateYear);
    }
    if (message.partBDate !== undefined) {
      Timestamp.encode(toTimestamp(message.partBDate), writer.uint32(146).fork()).ldelim();
    }
    if (message.partBTimestamp !== undefined) {
      writer.uint32(152).int64(message.partBTimestamp);
    }
    if (message.partBDateDay !== undefined) {
      writer.uint32(160).int32(message.partBDateDay);
    }
    if (message.partBDateMonth !== undefined) {
      writer.uint32(168).int32(message.partBDateMonth);
    }
    if (message.partBDateYear !== undefined) {
      writer.uint32(176).int32(message.partBDateYear);
    }
    if (message.paymentType !== undefined) {
      writer.uint32(186).string(message.paymentType);
    }
    if (message.pcp !== undefined) {
      SfWe_Plan_PCP.encode(message.pcp, writer.uint32(194).fork()).ldelim();
    }
    if (message.plan !== undefined) {
      SfWe_Plan_Plan.encode(message.plan, writer.uint32(202).fork()).ldelim();
    }
    if (message.raceMultipleAfricanAmerican !== undefined) {
      writer.uint32(208).bool(message.raceMultipleAfricanAmerican);
    }
    if (message.raceMultipleNoAnswer !== undefined) {
      writer.uint32(216).bool(message.raceMultipleNoAnswer);
    }
    if (message.raceMultipleWhite !== undefined) {
      writer.uint32(224).bool(message.raceMultipleWhite);
    }
    if (message.signatureDate !== undefined) {
      Timestamp.encode(toTimestamp(message.signatureDate), writer.uint32(234).fork()).ldelim();
    }
    if (message.signatureTimestamp !== undefined) {
      writer.uint32(240).int64(message.signatureTimestamp);
    }
    if (message.signatureDateDay !== undefined) {
      writer.uint32(248).int32(message.signatureDateDay);
    }
    if (message.signatureDateMonth !== undefined) {
      writer.uint32(256).int32(message.signatureDateMonth);
    }
    if (message.signatureDateYear !== undefined) {
      writer.uint32(264).int32(message.signatureDateYear);
    }
    if (message.veteranStatus !== undefined) {
      writer.uint32(274).string(message.veteranStatus);
    }
    if (message.work !== undefined) {
      writer.uint32(280).bool(message.work);
    }
    if (message.metadata !== undefined) {
      SfWe_Plan_Metadata.encode(message.metadata, writer.uint32(290).fork()).ldelim();
    }
    if (message.enrollmentData !== undefined) {
      SfWe_Plan_EnrollmentData.encode(message.enrollmentData, writer.uint32(298).fork()).ldelim();
    }
    if (message.parameters !== undefined) {
      SfWe_Plan_Parameters.encode(message.parameters, writer.uint32(306).fork()).ldelim();
    }
    if (message.webhookType !== undefined) {
      writer.uint32(314).string(message.webhookType);
    }
    if (message.webhookReceived !== undefined) {
      Timestamp.encode(toTimestamp(message.webhookReceived), writer.uint32(322).fork()).ldelim();
    }
    if (message.webhookReceivedTimestamp !== undefined) {
      writer.uint32(328).int64(message.webhookReceivedTimestamp);
    }
    if (message.consumer !== undefined) {
      SfWe_Consumer.encode(message.consumer, writer.uint32(338).fork()).ldelim();
    }
    if (message.agent !== undefined) {
      SfWe_Agent.encode(message.agent, writer.uint32(346).fork()).ldelim();
    }
    if (message.user !== undefined) {
      SfWe_Plan_User.encode(message.user, writer.uint32(354).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      SfWe_Plan_User.encode(message.agency, writer.uint32(362).fork()).ldelim();
    }
    if (message.carrier !== undefined) {
      SfWe_Plan_User.encode(message.carrier, writer.uint32(370).fork()).ldelim();
    }
    if (message.raw !== undefined) {
      Struct.encode(Struct.wrap(message.raw), writer.uint32(378).fork()).ldelim();
    }
    if (message.baseCategory !== undefined) {
      writer.uint32(384).int32(message.baseCategory);
    }
    if (message.otherMedicareCoverage !== undefined) {
      SfWe_Plan_OtherMedicareCoverage.encode(message.otherMedicareCoverage, writer.uint32(394).fork()).ldelim();
    }
    if (message.chf !== undefined) {
      SfWe_Plan_Chf.encode(message.chf, writer.uint32(402).fork()).ldelim();
    }
    if (message.copd !== undefined) {
      SfWe_Plan_Copd.encode(message.copd, writer.uint32(410).fork()).ldelim();
    }
    if (message.cvd !== undefined) {
      SfWe_Plan_Cvd.encode(message.cvd, writer.uint32(418).fork()).ldelim();
    }
    if (message.diabetes !== undefined) {
      SfWe_Plan_Diabetes.encode(message.diabetes, writer.uint32(426).fork()).ldelim();
    }
    if (message.medicare !== undefined) {
      SfWe_Plan_Medicare.encode(message.medicare, writer.uint32(434).fork()).ldelim();
    }
    if (message.premium !== undefined) {
      SfWe_Plan_Premium.encode(message.premium, writer.uint32(442).fork()).ldelim();
    }
    if (message.commissions !== undefined) {
      SfWe_Plan_Commissions.encode(message.commissions, writer.uint32(450).fork()).ldelim();
    }
    if (message.signaturePeriod !== undefined) {
      writer.uint32(456).int32(message.signaturePeriod);
    }
    if (message.allegation !== undefined) {
      SfWe_Plan_Allegation.encode(message.allegation, writer.uint32(466).fork()).ldelim();
    }
    if (message.marketingVendor !== undefined) {
      SfWe_Plan_User.encode(message.marketingVendor, writer.uint32(474).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.altLanguageFmt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.eSignatureAgentESignatureChoices = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.electionPeriod = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.ethnicityMultipleNoAnswer = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ethnicityMultipleNotHispanic = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.leadSource = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.ltcHas = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.okToEmail = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.medicaid = SfWe_Plan_Medicaid.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.otherDrugCoverage = SfWe_Plan_OtherDrugCoverage.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.otherGroupCoverage = SfWe_Plan_OtherGroupCoverage.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.partADate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.partATimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.partADateDay = reader.int32();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.partADateMonth = reader.int32();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.partADateYear = reader.int32();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.partBDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.partBTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.partBDateDay = reader.int32();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.partBDateMonth = reader.int32();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.partBDateYear = reader.int32();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.paymentType = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.pcp = SfWe_Plan_PCP.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.plan = SfWe_Plan_Plan.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.raceMultipleAfricanAmerican = reader.bool();
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.raceMultipleNoAnswer = reader.bool();
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.raceMultipleWhite = reader.bool();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.signatureDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.signatureTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.signatureDateDay = reader.int32();
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.signatureDateMonth = reader.int32();
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.signatureDateYear = reader.int32();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.veteranStatus = reader.string();
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.work = reader.bool();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.metadata = SfWe_Plan_Metadata.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.enrollmentData = SfWe_Plan_EnrollmentData.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.parameters = SfWe_Plan_Parameters.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.webhookType = reader.string();
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.webhookReceived = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 41:
          if (tag !== 328) {
            break;
          }

          message.webhookReceivedTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.consumer = SfWe_Consumer.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.agent = SfWe_Agent.decode(reader, reader.uint32());
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.user = SfWe_Plan_User.decode(reader, reader.uint32());
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.agency = SfWe_Plan_User.decode(reader, reader.uint32());
          continue;
        case 46:
          if (tag !== 370) {
            break;
          }

          message.carrier = SfWe_Plan_User.decode(reader, reader.uint32());
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.raw = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 48:
          if (tag !== 384) {
            break;
          }

          message.baseCategory = reader.int32() as any;
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.otherMedicareCoverage = SfWe_Plan_OtherMedicareCoverage.decode(reader, reader.uint32());
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.chf = SfWe_Plan_Chf.decode(reader, reader.uint32());
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.copd = SfWe_Plan_Copd.decode(reader, reader.uint32());
          continue;
        case 52:
          if (tag !== 418) {
            break;
          }

          message.cvd = SfWe_Plan_Cvd.decode(reader, reader.uint32());
          continue;
        case 53:
          if (tag !== 426) {
            break;
          }

          message.diabetes = SfWe_Plan_Diabetes.decode(reader, reader.uint32());
          continue;
        case 54:
          if (tag !== 434) {
            break;
          }

          message.medicare = SfWe_Plan_Medicare.decode(reader, reader.uint32());
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.premium = SfWe_Plan_Premium.decode(reader, reader.uint32());
          continue;
        case 56:
          if (tag !== 450) {
            break;
          }

          message.commissions = SfWe_Plan_Commissions.decode(reader, reader.uint32());
          continue;
        case 57:
          if (tag !== 456) {
            break;
          }

          message.signaturePeriod = reader.int32() as any;
          continue;
        case 58:
          if (tag !== 466) {
            break;
          }

          message.allegation = SfWe_Plan_Allegation.decode(reader, reader.uint32());
          continue;
        case 59:
          if (tag !== 474) {
            break;
          }

          message.marketingVendor = SfWe_Plan_User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      altLanguageFmt: isSet(object.altLanguageFmt) ? String(object.altLanguageFmt) : undefined,
      eSignatureAgentESignatureChoices: isSet(object.eSignatureAgentESignatureChoices)
        ? String(object.eSignatureAgentESignatureChoices)
        : undefined,
      electionPeriod: isSet(object.electionPeriod) ? String(object.electionPeriod) : undefined,
      ethnicityMultipleNoAnswer: isSet(object.ethnicityMultipleNoAnswer)
        ? Boolean(object.ethnicityMultipleNoAnswer)
        : undefined,
      ethnicityMultipleNotHispanic: isSet(object.ethnicityMultipleNotHispanic)
        ? Boolean(object.ethnicityMultipleNotHispanic)
        : undefined,
      leadSource: isSet(object.leadSource) ? String(object.leadSource) : undefined,
      ltcHas: isSet(object.ltcHas) ? Boolean(object.ltcHas) : undefined,
      okToEmail: isSet(object.okToEmail) ? Boolean(object.okToEmail) : undefined,
      medicaid: isSet(object.medicaid) ? SfWe_Plan_Medicaid.fromJSON(object.medicaid) : undefined,
      otherDrugCoverage: isSet(object.otherDrugCoverage)
        ? SfWe_Plan_OtherDrugCoverage.fromJSON(object.otherDrugCoverage)
        : undefined,
      otherGroupCoverage: isSet(object.otherGroupCoverage)
        ? SfWe_Plan_OtherGroupCoverage.fromJSON(object.otherGroupCoverage)
        : undefined,
      partADate: isSet(object.partADate) ? fromJsonTimestamp(object.partADate) : undefined,
      partATimestamp: isSet(object.partATimestamp) ? Number(object.partATimestamp) : undefined,
      partADateDay: isSet(object.partADateDay) ? Number(object.partADateDay) : undefined,
      partADateMonth: isSet(object.partADateMonth) ? Number(object.partADateMonth) : undefined,
      partADateYear: isSet(object.partADateYear) ? Number(object.partADateYear) : undefined,
      partBDate: isSet(object.partBDate) ? fromJsonTimestamp(object.partBDate) : undefined,
      partBTimestamp: isSet(object.partBTimestamp) ? Number(object.partBTimestamp) : undefined,
      partBDateDay: isSet(object.partBDateDay) ? Number(object.partBDateDay) : undefined,
      partBDateMonth: isSet(object.partBDateMonth) ? Number(object.partBDateMonth) : undefined,
      partBDateYear: isSet(object.partBDateYear) ? Number(object.partBDateYear) : undefined,
      paymentType: isSet(object.paymentType) ? String(object.paymentType) : undefined,
      pcp: isSet(object.pcp) ? SfWe_Plan_PCP.fromJSON(object.pcp) : undefined,
      plan: isSet(object.plan) ? SfWe_Plan_Plan.fromJSON(object.plan) : undefined,
      raceMultipleAfricanAmerican: isSet(object.raceMultipleAfricanAmerican)
        ? Boolean(object.raceMultipleAfricanAmerican)
        : undefined,
      raceMultipleNoAnswer: isSet(object.raceMultipleNoAnswer) ? Boolean(object.raceMultipleNoAnswer) : undefined,
      raceMultipleWhite: isSet(object.raceMultipleWhite) ? Boolean(object.raceMultipleWhite) : undefined,
      signatureDate: isSet(object.signatureDate) ? fromJsonTimestamp(object.signatureDate) : undefined,
      signatureTimestamp: isSet(object.signatureTimestamp) ? Number(object.signatureTimestamp) : undefined,
      signatureDateDay: isSet(object.signatureDateDay) ? Number(object.signatureDateDay) : undefined,
      signatureDateMonth: isSet(object.signatureDateMonth) ? Number(object.signatureDateMonth) : undefined,
      signatureDateYear: isSet(object.signatureDateYear) ? Number(object.signatureDateYear) : undefined,
      veteranStatus: isSet(object.veteranStatus) ? String(object.veteranStatus) : undefined,
      work: isSet(object.work) ? Boolean(object.work) : undefined,
      metadata: isSet(object.metadata) ? SfWe_Plan_Metadata.fromJSON(object.metadata) : undefined,
      enrollmentData: isSet(object.enrollmentData)
        ? SfWe_Plan_EnrollmentData.fromJSON(object.enrollmentData)
        : undefined,
      parameters: isSet(object.parameters) ? SfWe_Plan_Parameters.fromJSON(object.parameters) : undefined,
      webhookType: isSet(object.webhookType) ? String(object.webhookType) : undefined,
      webhookReceived: isSet(object.webhookReceived) ? fromJsonTimestamp(object.webhookReceived) : undefined,
      webhookReceivedTimestamp: isSet(object.webhookReceivedTimestamp)
        ? Number(object.webhookReceivedTimestamp)
        : undefined,
      consumer: isSet(object.consumer) ? SfWe_Consumer.fromJSON(object.consumer) : undefined,
      agent: isSet(object.agent) ? SfWe_Agent.fromJSON(object.agent) : undefined,
      user: isSet(object.user) ? SfWe_Plan_User.fromJSON(object.user) : undefined,
      agency: isSet(object.agency) ? SfWe_Plan_User.fromJSON(object.agency) : undefined,
      carrier: isSet(object.carrier) ? SfWe_Plan_User.fromJSON(object.carrier) : undefined,
      raw: isObject(object.raw) ? object.raw : undefined,
      baseCategory: isSet(object.baseCategory) ? sfWe_BaseCategoryFromJSON(object.baseCategory) : undefined,
      otherMedicareCoverage: isSet(object.otherMedicareCoverage)
        ? SfWe_Plan_OtherMedicareCoverage.fromJSON(object.otherMedicareCoverage)
        : undefined,
      chf: isSet(object.chf) ? SfWe_Plan_Chf.fromJSON(object.chf) : undefined,
      copd: isSet(object.copd) ? SfWe_Plan_Copd.fromJSON(object.copd) : undefined,
      cvd: isSet(object.cvd) ? SfWe_Plan_Cvd.fromJSON(object.cvd) : undefined,
      diabetes: isSet(object.diabetes) ? SfWe_Plan_Diabetes.fromJSON(object.diabetes) : undefined,
      medicare: isSet(object.medicare) ? SfWe_Plan_Medicare.fromJSON(object.medicare) : undefined,
      premium: isSet(object.premium) ? SfWe_Plan_Premium.fromJSON(object.premium) : undefined,
      commissions: isSet(object.commissions) ? SfWe_Plan_Commissions.fromJSON(object.commissions) : undefined,
      signaturePeriod: isSet(object.signaturePeriod) ? signaturePeriodFromJSON(object.signaturePeriod) : undefined,
      allegation: isSet(object.allegation) ? SfWe_Plan_Allegation.fromJSON(object.allegation) : undefined,
      marketingVendor: isSet(object.marketingVendor) ? SfWe_Plan_User.fromJSON(object.marketingVendor) : undefined,
    };
  },

  toJSON(message: SfWe_Plan): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.altLanguageFmt !== undefined && (obj.altLanguageFmt = message.altLanguageFmt);
    message.eSignatureAgentESignatureChoices !== undefined &&
      (obj.eSignatureAgentESignatureChoices = message.eSignatureAgentESignatureChoices);
    message.electionPeriod !== undefined && (obj.electionPeriod = message.electionPeriod);
    message.ethnicityMultipleNoAnswer !== undefined &&
      (obj.ethnicityMultipleNoAnswer = message.ethnicityMultipleNoAnswer);
    message.ethnicityMultipleNotHispanic !== undefined &&
      (obj.ethnicityMultipleNotHispanic = message.ethnicityMultipleNotHispanic);
    message.leadSource !== undefined && (obj.leadSource = message.leadSource);
    message.ltcHas !== undefined && (obj.ltcHas = message.ltcHas);
    message.okToEmail !== undefined && (obj.okToEmail = message.okToEmail);
    message.medicaid !== undefined &&
      (obj.medicaid = message.medicaid ? SfWe_Plan_Medicaid.toJSON(message.medicaid) : undefined);
    message.otherDrugCoverage !== undefined && (obj.otherDrugCoverage = message.otherDrugCoverage
      ? SfWe_Plan_OtherDrugCoverage.toJSON(message.otherDrugCoverage)
      : undefined);
    message.otherGroupCoverage !== undefined && (obj.otherGroupCoverage = message.otherGroupCoverage
      ? SfWe_Plan_OtherGroupCoverage.toJSON(message.otherGroupCoverage)
      : undefined);
    message.partADate !== undefined && (obj.partADate = message.partADate.toISOString());
    message.partATimestamp !== undefined && (obj.partATimestamp = Math.round(message.partATimestamp));
    message.partADateDay !== undefined && (obj.partADateDay = Math.round(message.partADateDay));
    message.partADateMonth !== undefined && (obj.partADateMonth = Math.round(message.partADateMonth));
    message.partADateYear !== undefined && (obj.partADateYear = Math.round(message.partADateYear));
    message.partBDate !== undefined && (obj.partBDate = message.partBDate.toISOString());
    message.partBTimestamp !== undefined && (obj.partBTimestamp = Math.round(message.partBTimestamp));
    message.partBDateDay !== undefined && (obj.partBDateDay = Math.round(message.partBDateDay));
    message.partBDateMonth !== undefined && (obj.partBDateMonth = Math.round(message.partBDateMonth));
    message.partBDateYear !== undefined && (obj.partBDateYear = Math.round(message.partBDateYear));
    message.paymentType !== undefined && (obj.paymentType = message.paymentType);
    message.pcp !== undefined && (obj.pcp = message.pcp ? SfWe_Plan_PCP.toJSON(message.pcp) : undefined);
    message.plan !== undefined && (obj.plan = message.plan ? SfWe_Plan_Plan.toJSON(message.plan) : undefined);
    message.raceMultipleAfricanAmerican !== undefined &&
      (obj.raceMultipleAfricanAmerican = message.raceMultipleAfricanAmerican);
    message.raceMultipleNoAnswer !== undefined && (obj.raceMultipleNoAnswer = message.raceMultipleNoAnswer);
    message.raceMultipleWhite !== undefined && (obj.raceMultipleWhite = message.raceMultipleWhite);
    message.signatureDate !== undefined && (obj.signatureDate = message.signatureDate.toISOString());
    message.signatureTimestamp !== undefined && (obj.signatureTimestamp = Math.round(message.signatureTimestamp));
    message.signatureDateDay !== undefined && (obj.signatureDateDay = Math.round(message.signatureDateDay));
    message.signatureDateMonth !== undefined && (obj.signatureDateMonth = Math.round(message.signatureDateMonth));
    message.signatureDateYear !== undefined && (obj.signatureDateYear = Math.round(message.signatureDateYear));
    message.veteranStatus !== undefined && (obj.veteranStatus = message.veteranStatus);
    message.work !== undefined && (obj.work = message.work);
    message.metadata !== undefined &&
      (obj.metadata = message.metadata ? SfWe_Plan_Metadata.toJSON(message.metadata) : undefined);
    message.enrollmentData !== undefined &&
      (obj.enrollmentData = message.enrollmentData
        ? SfWe_Plan_EnrollmentData.toJSON(message.enrollmentData)
        : undefined);
    message.parameters !== undefined &&
      (obj.parameters = message.parameters ? SfWe_Plan_Parameters.toJSON(message.parameters) : undefined);
    message.webhookType !== undefined && (obj.webhookType = message.webhookType);
    message.webhookReceived !== undefined && (obj.webhookReceived = message.webhookReceived.toISOString());
    message.webhookReceivedTimestamp !== undefined &&
      (obj.webhookReceivedTimestamp = Math.round(message.webhookReceivedTimestamp));
    message.consumer !== undefined &&
      (obj.consumer = message.consumer ? SfWe_Consumer.toJSON(message.consumer) : undefined);
    message.agent !== undefined && (obj.agent = message.agent ? SfWe_Agent.toJSON(message.agent) : undefined);
    message.user !== undefined && (obj.user = message.user ? SfWe_Plan_User.toJSON(message.user) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? SfWe_Plan_User.toJSON(message.agency) : undefined);
    message.carrier !== undefined &&
      (obj.carrier = message.carrier ? SfWe_Plan_User.toJSON(message.carrier) : undefined);
    message.raw !== undefined && (obj.raw = message.raw);
    message.baseCategory !== undefined &&
      (obj.baseCategory = message.baseCategory !== undefined
        ? sfWe_BaseCategoryToJSON(message.baseCategory)
        : undefined);
    message.otherMedicareCoverage !== undefined && (obj.otherMedicareCoverage = message.otherMedicareCoverage
      ? SfWe_Plan_OtherMedicareCoverage.toJSON(message.otherMedicareCoverage)
      : undefined);
    message.chf !== undefined && (obj.chf = message.chf ? SfWe_Plan_Chf.toJSON(message.chf) : undefined);
    message.copd !== undefined && (obj.copd = message.copd ? SfWe_Plan_Copd.toJSON(message.copd) : undefined);
    message.cvd !== undefined && (obj.cvd = message.cvd ? SfWe_Plan_Cvd.toJSON(message.cvd) : undefined);
    message.diabetes !== undefined &&
      (obj.diabetes = message.diabetes ? SfWe_Plan_Diabetes.toJSON(message.diabetes) : undefined);
    message.medicare !== undefined &&
      (obj.medicare = message.medicare ? SfWe_Plan_Medicare.toJSON(message.medicare) : undefined);
    message.premium !== undefined &&
      (obj.premium = message.premium ? SfWe_Plan_Premium.toJSON(message.premium) : undefined);
    message.commissions !== undefined &&
      (obj.commissions = message.commissions ? SfWe_Plan_Commissions.toJSON(message.commissions) : undefined);
    message.signaturePeriod !== undefined && (obj.signaturePeriod = message.signaturePeriod !== undefined
      ? signaturePeriodToJSON(message.signaturePeriod)
      : undefined);
    message.allegation !== undefined &&
      (obj.allegation = message.allegation ? SfWe_Plan_Allegation.toJSON(message.allegation) : undefined);
    message.marketingVendor !== undefined &&
      (obj.marketingVendor = message.marketingVendor ? SfWe_Plan_User.toJSON(message.marketingVendor) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan>, I>>(base?: I): SfWe_Plan {
    return SfWe_Plan.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan>, I>>(object: I): SfWe_Plan {
    const message = createBaseSfWe_Plan();
    message.id = object.id ?? "";
    message.altLanguageFmt = object.altLanguageFmt ?? undefined;
    message.eSignatureAgentESignatureChoices = object.eSignatureAgentESignatureChoices ?? undefined;
    message.electionPeriod = object.electionPeriod ?? undefined;
    message.ethnicityMultipleNoAnswer = object.ethnicityMultipleNoAnswer ?? undefined;
    message.ethnicityMultipleNotHispanic = object.ethnicityMultipleNotHispanic ?? undefined;
    message.leadSource = object.leadSource ?? undefined;
    message.ltcHas = object.ltcHas ?? undefined;
    message.okToEmail = object.okToEmail ?? undefined;
    message.medicaid = (object.medicaid !== undefined && object.medicaid !== null)
      ? SfWe_Plan_Medicaid.fromPartial(object.medicaid)
      : undefined;
    message.otherDrugCoverage = (object.otherDrugCoverage !== undefined && object.otherDrugCoverage !== null)
      ? SfWe_Plan_OtherDrugCoverage.fromPartial(object.otherDrugCoverage)
      : undefined;
    message.otherGroupCoverage = (object.otherGroupCoverage !== undefined && object.otherGroupCoverage !== null)
      ? SfWe_Plan_OtherGroupCoverage.fromPartial(object.otherGroupCoverage)
      : undefined;
    message.partADate = object.partADate ?? undefined;
    message.partATimestamp = object.partATimestamp ?? undefined;
    message.partADateDay = object.partADateDay ?? undefined;
    message.partADateMonth = object.partADateMonth ?? undefined;
    message.partADateYear = object.partADateYear ?? undefined;
    message.partBDate = object.partBDate ?? undefined;
    message.partBTimestamp = object.partBTimestamp ?? undefined;
    message.partBDateDay = object.partBDateDay ?? undefined;
    message.partBDateMonth = object.partBDateMonth ?? undefined;
    message.partBDateYear = object.partBDateYear ?? undefined;
    message.paymentType = object.paymentType ?? undefined;
    message.pcp = (object.pcp !== undefined && object.pcp !== null) ? SfWe_Plan_PCP.fromPartial(object.pcp) : undefined;
    message.plan = (object.plan !== undefined && object.plan !== null)
      ? SfWe_Plan_Plan.fromPartial(object.plan)
      : undefined;
    message.raceMultipleAfricanAmerican = object.raceMultipleAfricanAmerican ?? undefined;
    message.raceMultipleNoAnswer = object.raceMultipleNoAnswer ?? undefined;
    message.raceMultipleWhite = object.raceMultipleWhite ?? undefined;
    message.signatureDate = object.signatureDate ?? undefined;
    message.signatureTimestamp = object.signatureTimestamp ?? undefined;
    message.signatureDateDay = object.signatureDateDay ?? undefined;
    message.signatureDateMonth = object.signatureDateMonth ?? undefined;
    message.signatureDateYear = object.signatureDateYear ?? undefined;
    message.veteranStatus = object.veteranStatus ?? undefined;
    message.work = object.work ?? undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? SfWe_Plan_Metadata.fromPartial(object.metadata)
      : undefined;
    message.enrollmentData = (object.enrollmentData !== undefined && object.enrollmentData !== null)
      ? SfWe_Plan_EnrollmentData.fromPartial(object.enrollmentData)
      : undefined;
    message.parameters = (object.parameters !== undefined && object.parameters !== null)
      ? SfWe_Plan_Parameters.fromPartial(object.parameters)
      : undefined;
    message.webhookType = object.webhookType ?? undefined;
    message.webhookReceived = object.webhookReceived ?? undefined;
    message.webhookReceivedTimestamp = object.webhookReceivedTimestamp ?? undefined;
    message.consumer = (object.consumer !== undefined && object.consumer !== null)
      ? SfWe_Consumer.fromPartial(object.consumer)
      : undefined;
    message.agent = (object.agent !== undefined && object.agent !== null)
      ? SfWe_Agent.fromPartial(object.agent)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null)
      ? SfWe_Plan_User.fromPartial(object.user)
      : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? SfWe_Plan_User.fromPartial(object.agency)
      : undefined;
    message.carrier = (object.carrier !== undefined && object.carrier !== null)
      ? SfWe_Plan_User.fromPartial(object.carrier)
      : undefined;
    message.raw = object.raw ?? undefined;
    message.baseCategory = object.baseCategory ?? undefined;
    message.otherMedicareCoverage =
      (object.otherMedicareCoverage !== undefined && object.otherMedicareCoverage !== null)
        ? SfWe_Plan_OtherMedicareCoverage.fromPartial(object.otherMedicareCoverage)
        : undefined;
    message.chf = (object.chf !== undefined && object.chf !== null) ? SfWe_Plan_Chf.fromPartial(object.chf) : undefined;
    message.copd = (object.copd !== undefined && object.copd !== null)
      ? SfWe_Plan_Copd.fromPartial(object.copd)
      : undefined;
    message.cvd = (object.cvd !== undefined && object.cvd !== null) ? SfWe_Plan_Cvd.fromPartial(object.cvd) : undefined;
    message.diabetes = (object.diabetes !== undefined && object.diabetes !== null)
      ? SfWe_Plan_Diabetes.fromPartial(object.diabetes)
      : undefined;
    message.medicare = (object.medicare !== undefined && object.medicare !== null)
      ? SfWe_Plan_Medicare.fromPartial(object.medicare)
      : undefined;
    message.premium = (object.premium !== undefined && object.premium !== null)
      ? SfWe_Plan_Premium.fromPartial(object.premium)
      : undefined;
    message.commissions = (object.commissions !== undefined && object.commissions !== null)
      ? SfWe_Plan_Commissions.fromPartial(object.commissions)
      : undefined;
    message.signaturePeriod = object.signaturePeriod ?? undefined;
    message.allegation = (object.allegation !== undefined && object.allegation !== null)
      ? SfWe_Plan_Allegation.fromPartial(object.allegation)
      : undefined;
    message.marketingVendor = (object.marketingVendor !== undefined && object.marketingVendor !== null)
      ? SfWe_Plan_User.fromPartial(object.marketingVendor)
      : undefined;
    return message;
  },
};

function createBaseSfWe_Plan_User(): SfWe_Plan_User {
  return { id: undefined };
}

export const SfWe_Plan_User = {
  encode(message: SfWe_Plan_User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_User();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_User {
    return { id: isSet(object.id) ? String(object.id) : undefined };
  },

  toJSON(message: SfWe_Plan_User): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_User>, I>>(base?: I): SfWe_Plan_User {
    return SfWe_Plan_User.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_User>, I>>(object: I): SfWe_Plan_User {
    const message = createBaseSfWe_Plan_User();
    message.id = object.id ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Chf(): SfWe_Plan_Chf {
  return { has: undefined, hasBodyFluid: undefined, hasLungFluid: undefined, drugsLungFluidString: undefined };
}

export const SfWe_Plan_Chf = {
  encode(message: SfWe_Plan_Chf, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.hasBodyFluid !== undefined) {
      writer.uint32(16).bool(message.hasBodyFluid);
    }
    if (message.hasLungFluid !== undefined) {
      writer.uint32(24).bool(message.hasLungFluid);
    }
    if (message.drugsLungFluidString !== undefined) {
      writer.uint32(34).string(message.drugsLungFluidString);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Chf {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Chf();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasBodyFluid = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasLungFluid = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.drugsLungFluidString = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Chf {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      hasBodyFluid: isSet(object.hasBodyFluid) ? Boolean(object.hasBodyFluid) : undefined,
      hasLungFluid: isSet(object.hasLungFluid) ? Boolean(object.hasLungFluid) : undefined,
      drugsLungFluidString: isSet(object.drugsLungFluidString) ? String(object.drugsLungFluidString) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Chf): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.hasBodyFluid !== undefined && (obj.hasBodyFluid = message.hasBodyFluid);
    message.hasLungFluid !== undefined && (obj.hasLungFluid = message.hasLungFluid);
    message.drugsLungFluidString !== undefined && (obj.drugsLungFluidString = message.drugsLungFluidString);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Chf>, I>>(base?: I): SfWe_Plan_Chf {
    return SfWe_Plan_Chf.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Chf>, I>>(object: I): SfWe_Plan_Chf {
    const message = createBaseSfWe_Plan_Chf();
    message.has = object.has ?? undefined;
    message.hasBodyFluid = object.hasBodyFluid ?? undefined;
    message.hasLungFluid = object.hasLungFluid ?? undefined;
    message.drugsLungFluidString = object.drugsLungFluidString ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Copd(): SfWe_Plan_Copd {
  return {
    has: undefined,
    hasAsthma: undefined,
    hasDifficultyBreathing: undefined,
    hasEmphysema: undefined,
    needsOxygenSupport: undefined,
    drugsBreathing: undefined,
  };
}

export const SfWe_Plan_Copd = {
  encode(message: SfWe_Plan_Copd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.hasAsthma !== undefined) {
      writer.uint32(16).bool(message.hasAsthma);
    }
    if (message.hasDifficultyBreathing !== undefined) {
      writer.uint32(24).bool(message.hasDifficultyBreathing);
    }
    if (message.hasEmphysema !== undefined) {
      writer.uint32(32).bool(message.hasEmphysema);
    }
    if (message.needsOxygenSupport !== undefined) {
      writer.uint32(40).bool(message.needsOxygenSupport);
    }
    if (message.drugsBreathing !== undefined) {
      writer.uint32(50).string(message.drugsBreathing);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Copd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Copd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasAsthma = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasDifficultyBreathing = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hasEmphysema = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.needsOxygenSupport = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.drugsBreathing = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Copd {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      hasAsthma: isSet(object.hasAsthma) ? Boolean(object.hasAsthma) : undefined,
      hasDifficultyBreathing: isSet(object.hasDifficultyBreathing) ? Boolean(object.hasDifficultyBreathing) : undefined,
      hasEmphysema: isSet(object.hasEmphysema) ? Boolean(object.hasEmphysema) : undefined,
      needsOxygenSupport: isSet(object.needsOxygenSupport) ? Boolean(object.needsOxygenSupport) : undefined,
      drugsBreathing: isSet(object.drugsBreathing) ? String(object.drugsBreathing) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Copd): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.hasAsthma !== undefined && (obj.hasAsthma = message.hasAsthma);
    message.hasDifficultyBreathing !== undefined && (obj.hasDifficultyBreathing = message.hasDifficultyBreathing);
    message.hasEmphysema !== undefined && (obj.hasEmphysema = message.hasEmphysema);
    message.needsOxygenSupport !== undefined && (obj.needsOxygenSupport = message.needsOxygenSupport);
    message.drugsBreathing !== undefined && (obj.drugsBreathing = message.drugsBreathing);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Copd>, I>>(base?: I): SfWe_Plan_Copd {
    return SfWe_Plan_Copd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Copd>, I>>(object: I): SfWe_Plan_Copd {
    const message = createBaseSfWe_Plan_Copd();
    message.has = object.has ?? undefined;
    message.hasAsthma = object.hasAsthma ?? undefined;
    message.hasDifficultyBreathing = object.hasDifficultyBreathing ?? undefined;
    message.hasEmphysema = object.hasEmphysema ?? undefined;
    message.needsOxygenSupport = object.needsOxygenSupport ?? undefined;
    message.drugsBreathing = object.drugsBreathing ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Cvd(): SfWe_Plan_Cvd {
  return { has: undefined, hadHeartAttack: undefined, hasChestLegPain: undefined, drugsHeart: undefined };
}

export const SfWe_Plan_Cvd = {
  encode(message: SfWe_Plan_Cvd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.hadHeartAttack !== undefined) {
      writer.uint32(16).bool(message.hadHeartAttack);
    }
    if (message.hasChestLegPain !== undefined) {
      writer.uint32(24).bool(message.hasChestLegPain);
    }
    if (message.drugsHeart !== undefined) {
      writer.uint32(32).bool(message.drugsHeart);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Cvd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Cvd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hadHeartAttack = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasChestLegPain = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.drugsHeart = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Cvd {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      hadHeartAttack: isSet(object.hadHeartAttack) ? Boolean(object.hadHeartAttack) : undefined,
      hasChestLegPain: isSet(object.hasChestLegPain) ? Boolean(object.hasChestLegPain) : undefined,
      drugsHeart: isSet(object.drugsHeart) ? Boolean(object.drugsHeart) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Cvd): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.hadHeartAttack !== undefined && (obj.hadHeartAttack = message.hadHeartAttack);
    message.hasChestLegPain !== undefined && (obj.hasChestLegPain = message.hasChestLegPain);
    message.drugsHeart !== undefined && (obj.drugsHeart = message.drugsHeart);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Cvd>, I>>(base?: I): SfWe_Plan_Cvd {
    return SfWe_Plan_Cvd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Cvd>, I>>(object: I): SfWe_Plan_Cvd {
    const message = createBaseSfWe_Plan_Cvd();
    message.has = object.has ?? undefined;
    message.hadHeartAttack = object.hadHeartAttack ?? undefined;
    message.hasChestLegPain = object.hasChestLegPain ?? undefined;
    message.drugsHeart = object.drugsHeart ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Diabetes(): SfWe_Plan_Diabetes {
  return { has: undefined, checksBloodSugar: undefined, hasHighBloodSugar: undefined, drugsBloodSugar: undefined };
}

export const SfWe_Plan_Diabetes = {
  encode(message: SfWe_Plan_Diabetes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.checksBloodSugar !== undefined) {
      writer.uint32(16).bool(message.checksBloodSugar);
    }
    if (message.hasHighBloodSugar !== undefined) {
      writer.uint32(24).bool(message.hasHighBloodSugar);
    }
    if (message.drugsBloodSugar !== undefined) {
      writer.uint32(32).bool(message.drugsBloodSugar);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Diabetes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Diabetes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.checksBloodSugar = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasHighBloodSugar = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.drugsBloodSugar = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Diabetes {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      checksBloodSugar: isSet(object.checksBloodSugar) ? Boolean(object.checksBloodSugar) : undefined,
      hasHighBloodSugar: isSet(object.hasHighBloodSugar) ? Boolean(object.hasHighBloodSugar) : undefined,
      drugsBloodSugar: isSet(object.drugsBloodSugar) ? Boolean(object.drugsBloodSugar) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Diabetes): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.checksBloodSugar !== undefined && (obj.checksBloodSugar = message.checksBloodSugar);
    message.hasHighBloodSugar !== undefined && (obj.hasHighBloodSugar = message.hasHighBloodSugar);
    message.drugsBloodSugar !== undefined && (obj.drugsBloodSugar = message.drugsBloodSugar);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Diabetes>, I>>(base?: I): SfWe_Plan_Diabetes {
    return SfWe_Plan_Diabetes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Diabetes>, I>>(object: I): SfWe_Plan_Diabetes {
    const message = createBaseSfWe_Plan_Diabetes();
    message.has = object.has ?? undefined;
    message.checksBloodSugar = object.checksBloodSugar ?? undefined;
    message.hasHighBloodSugar = object.hasHighBloodSugar ?? undefined;
    message.drugsBloodSugar = object.drugsBloodSugar ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_ESRD(): SfWe_Plan_ESRD {
  return { has: undefined };
}

export const SfWe_Plan_ESRD = {
  encode(message: SfWe_Plan_ESRD, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_ESRD {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_ESRD();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_ESRD {
    return { has: isSet(object.has) ? Boolean(object.has) : undefined };
  },

  toJSON(message: SfWe_Plan_ESRD): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_ESRD>, I>>(base?: I): SfWe_Plan_ESRD {
    return SfWe_Plan_ESRD.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_ESRD>, I>>(object: I): SfWe_Plan_ESRD {
    const message = createBaseSfWe_Plan_ESRD();
    message.has = object.has ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_EnrollmentData(): SfWe_Plan_EnrollmentData {
  return {
    confirmationCode: undefined,
    confirmationStatus: undefined,
    enrollmentCode: undefined,
    lastUpdatedDate: undefined,
    lastUpdatedTimestamp: undefined,
    partnerId: undefined,
    yearMonth: undefined,
    policyStatusCategory: undefined,
    policyStatus: undefined,
  };
}

export const SfWe_Plan_EnrollmentData = {
  encode(message: SfWe_Plan_EnrollmentData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.confirmationCode !== undefined) {
      writer.uint32(10).string(message.confirmationCode);
    }
    if (message.confirmationStatus !== undefined) {
      writer.uint32(18).string(message.confirmationStatus);
    }
    if (message.enrollmentCode !== undefined) {
      writer.uint32(26).string(message.enrollmentCode);
    }
    if (message.lastUpdatedDate !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedDate), writer.uint32(34).fork()).ldelim();
    }
    if (message.lastUpdatedTimestamp !== undefined) {
      writer.uint32(40).int64(message.lastUpdatedTimestamp);
    }
    if (message.partnerId !== undefined) {
      writer.uint32(50).string(message.partnerId);
    }
    if (message.yearMonth !== undefined) {
      writer.uint32(58).string(message.yearMonth);
    }
    if (message.policyStatusCategory !== undefined) {
      writer.uint32(64).int32(message.policyStatusCategory);
    }
    if (message.policyStatus !== undefined) {
      writer.uint32(72).int32(message.policyStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_EnrollmentData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_EnrollmentData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.confirmationCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.confirmationStatus = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.enrollmentCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastUpdatedDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lastUpdatedTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.partnerId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.yearMonth = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.policyStatusCategory = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.policyStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_EnrollmentData {
    return {
      confirmationCode: isSet(object.confirmationCode) ? String(object.confirmationCode) : undefined,
      confirmationStatus: isSet(object.confirmationStatus) ? String(object.confirmationStatus) : undefined,
      enrollmentCode: isSet(object.enrollmentCode) ? String(object.enrollmentCode) : undefined,
      lastUpdatedDate: isSet(object.lastUpdatedDate) ? fromJsonTimestamp(object.lastUpdatedDate) : undefined,
      lastUpdatedTimestamp: isSet(object.lastUpdatedTimestamp) ? Number(object.lastUpdatedTimestamp) : undefined,
      partnerId: isSet(object.partnerId) ? String(object.partnerId) : undefined,
      yearMonth: isSet(object.yearMonth) ? String(object.yearMonth) : undefined,
      policyStatusCategory: isSet(object.policyStatusCategory)
        ? sfWe_PolicyStatusCategoryFromJSON(object.policyStatusCategory)
        : undefined,
      policyStatus: isSet(object.policyStatus) ? sfWe_PolicyStatusFromJSON(object.policyStatus) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_EnrollmentData): unknown {
    const obj: any = {};
    message.confirmationCode !== undefined && (obj.confirmationCode = message.confirmationCode);
    message.confirmationStatus !== undefined && (obj.confirmationStatus = message.confirmationStatus);
    message.enrollmentCode !== undefined && (obj.enrollmentCode = message.enrollmentCode);
    message.lastUpdatedDate !== undefined && (obj.lastUpdatedDate = message.lastUpdatedDate.toISOString());
    message.lastUpdatedTimestamp !== undefined && (obj.lastUpdatedTimestamp = Math.round(message.lastUpdatedTimestamp));
    message.partnerId !== undefined && (obj.partnerId = message.partnerId);
    message.yearMonth !== undefined && (obj.yearMonth = message.yearMonth);
    message.policyStatusCategory !== undefined && (obj.policyStatusCategory = message.policyStatusCategory !== undefined
      ? sfWe_PolicyStatusCategoryToJSON(message.policyStatusCategory)
      : undefined);
    message.policyStatus !== undefined &&
      (obj.policyStatus = message.policyStatus !== undefined
        ? sfWe_PolicyStatusToJSON(message.policyStatus)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_EnrollmentData>, I>>(base?: I): SfWe_Plan_EnrollmentData {
    return SfWe_Plan_EnrollmentData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_EnrollmentData>, I>>(object: I): SfWe_Plan_EnrollmentData {
    const message = createBaseSfWe_Plan_EnrollmentData();
    message.confirmationCode = object.confirmationCode ?? undefined;
    message.confirmationStatus = object.confirmationStatus ?? undefined;
    message.enrollmentCode = object.enrollmentCode ?? undefined;
    message.lastUpdatedDate = object.lastUpdatedDate ?? undefined;
    message.lastUpdatedTimestamp = object.lastUpdatedTimestamp ?? undefined;
    message.partnerId = object.partnerId ?? undefined;
    message.yearMonth = object.yearMonth ?? undefined;
    message.policyStatusCategory = object.policyStatusCategory ?? undefined;
    message.policyStatus = object.policyStatus ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Medicaid(): SfWe_Plan_Medicaid {
  return { has: undefined, medicaidNumber: undefined };
}

export const SfWe_Plan_Medicaid = {
  encode(message: SfWe_Plan_Medicaid, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.medicaidNumber !== undefined) {
      writer.uint32(18).string(message.medicaidNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Medicaid {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Medicaid();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.medicaidNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Medicaid {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      medicaidNumber: isSet(object.medicaidNumber) ? String(object.medicaidNumber) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Medicaid): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.medicaidNumber !== undefined && (obj.medicaidNumber = message.medicaidNumber);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Medicaid>, I>>(base?: I): SfWe_Plan_Medicaid {
    return SfWe_Plan_Medicaid.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Medicaid>, I>>(object: I): SfWe_Plan_Medicaid {
    const message = createBaseSfWe_Plan_Medicaid();
    message.has = object.has ?? undefined;
    message.medicaidNumber = object.medicaidNumber ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Medicare(): SfWe_Plan_Medicare {
  return {
    partADate: undefined,
    partATimestamp: undefined,
    partADateDay: undefined,
    partADateMonth: undefined,
    partADateYear: undefined,
    partBDate: undefined,
    partBTimestamp: undefined,
    partBDateDay: undefined,
    partBDateMonth: undefined,
    partBDateYear: undefined,
  };
}

export const SfWe_Plan_Medicare = {
  encode(message: SfWe_Plan_Medicare, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.partADate !== undefined) {
      Timestamp.encode(toTimestamp(message.partADate), writer.uint32(10).fork()).ldelim();
    }
    if (message.partATimestamp !== undefined) {
      writer.uint32(16).int64(message.partATimestamp);
    }
    if (message.partADateDay !== undefined) {
      writer.uint32(24).int32(message.partADateDay);
    }
    if (message.partADateMonth !== undefined) {
      writer.uint32(32).int32(message.partADateMonth);
    }
    if (message.partADateYear !== undefined) {
      writer.uint32(40).int32(message.partADateYear);
    }
    if (message.partBDate !== undefined) {
      Timestamp.encode(toTimestamp(message.partBDate), writer.uint32(50).fork()).ldelim();
    }
    if (message.partBTimestamp !== undefined) {
      writer.uint32(56).int64(message.partBTimestamp);
    }
    if (message.partBDateDay !== undefined) {
      writer.uint32(64).int32(message.partBDateDay);
    }
    if (message.partBDateMonth !== undefined) {
      writer.uint32(72).int32(message.partBDateMonth);
    }
    if (message.partBDateYear !== undefined) {
      writer.uint32(80).int32(message.partBDateYear);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Medicare {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Medicare();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.partADate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.partATimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.partADateDay = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.partADateMonth = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.partADateYear = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.partBDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.partBTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.partBDateDay = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.partBDateMonth = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.partBDateYear = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Medicare {
    return {
      partADate: isSet(object.partADate) ? fromJsonTimestamp(object.partADate) : undefined,
      partATimestamp: isSet(object.partATimestamp) ? Number(object.partATimestamp) : undefined,
      partADateDay: isSet(object.partADateDay) ? Number(object.partADateDay) : undefined,
      partADateMonth: isSet(object.partADateMonth) ? Number(object.partADateMonth) : undefined,
      partADateYear: isSet(object.partADateYear) ? Number(object.partADateYear) : undefined,
      partBDate: isSet(object.partBDate) ? fromJsonTimestamp(object.partBDate) : undefined,
      partBTimestamp: isSet(object.partBTimestamp) ? Number(object.partBTimestamp) : undefined,
      partBDateDay: isSet(object.partBDateDay) ? Number(object.partBDateDay) : undefined,
      partBDateMonth: isSet(object.partBDateMonth) ? Number(object.partBDateMonth) : undefined,
      partBDateYear: isSet(object.partBDateYear) ? Number(object.partBDateYear) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Medicare): unknown {
    const obj: any = {};
    message.partADate !== undefined && (obj.partADate = message.partADate.toISOString());
    message.partATimestamp !== undefined && (obj.partATimestamp = Math.round(message.partATimestamp));
    message.partADateDay !== undefined && (obj.partADateDay = Math.round(message.partADateDay));
    message.partADateMonth !== undefined && (obj.partADateMonth = Math.round(message.partADateMonth));
    message.partADateYear !== undefined && (obj.partADateYear = Math.round(message.partADateYear));
    message.partBDate !== undefined && (obj.partBDate = message.partBDate.toISOString());
    message.partBTimestamp !== undefined && (obj.partBTimestamp = Math.round(message.partBTimestamp));
    message.partBDateDay !== undefined && (obj.partBDateDay = Math.round(message.partBDateDay));
    message.partBDateMonth !== undefined && (obj.partBDateMonth = Math.round(message.partBDateMonth));
    message.partBDateYear !== undefined && (obj.partBDateYear = Math.round(message.partBDateYear));
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Medicare>, I>>(base?: I): SfWe_Plan_Medicare {
    return SfWe_Plan_Medicare.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Medicare>, I>>(object: I): SfWe_Plan_Medicare {
    const message = createBaseSfWe_Plan_Medicare();
    message.partADate = object.partADate ?? undefined;
    message.partATimestamp = object.partATimestamp ?? undefined;
    message.partADateDay = object.partADateDay ?? undefined;
    message.partADateMonth = object.partADateMonth ?? undefined;
    message.partADateYear = object.partADateYear ?? undefined;
    message.partBDate = object.partBDate ?? undefined;
    message.partBTimestamp = object.partBTimestamp ?? undefined;
    message.partBDateDay = object.partBDateDay ?? undefined;
    message.partBDateMonth = object.partBDateMonth ?? undefined;
    message.partBDateYear = object.partBDateYear ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Metadata(): SfWe_Plan_Metadata {
  return {
    customerCode: undefined,
    formType: undefined,
    sfid: undefined,
    sfmAppName: undefined,
    startDate: undefined,
    startTimestamp: undefined,
    url: undefined,
  };
}

export const SfWe_Plan_Metadata = {
  encode(message: SfWe_Plan_Metadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerCode !== undefined) {
      writer.uint32(10).string(message.customerCode);
    }
    if (message.formType !== undefined) {
      writer.uint32(18).string(message.formType);
    }
    if (message.sfid !== undefined) {
      writer.uint32(26).string(message.sfid);
    }
    if (message.sfmAppName !== undefined) {
      writer.uint32(34).string(message.sfmAppName);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(42).fork()).ldelim();
    }
    if (message.startTimestamp !== undefined) {
      writer.uint32(48).int32(message.startTimestamp);
    }
    if (message.url !== undefined) {
      writer.uint32(58).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Metadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Metadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.formType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sfid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sfmAppName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.startTimestamp = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Metadata {
    return {
      customerCode: isSet(object.customerCode) ? String(object.customerCode) : undefined,
      formType: isSet(object.formType) ? String(object.formType) : undefined,
      sfid: isSet(object.sfid) ? String(object.sfid) : undefined,
      sfmAppName: isSet(object.sfmAppName) ? String(object.sfmAppName) : undefined,
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      startTimestamp: isSet(object.startTimestamp) ? Number(object.startTimestamp) : undefined,
      url: isSet(object.url) ? String(object.url) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Metadata): unknown {
    const obj: any = {};
    message.customerCode !== undefined && (obj.customerCode = message.customerCode);
    message.formType !== undefined && (obj.formType = message.formType);
    message.sfid !== undefined && (obj.sfid = message.sfid);
    message.sfmAppName !== undefined && (obj.sfmAppName = message.sfmAppName);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.startTimestamp !== undefined && (obj.startTimestamp = Math.round(message.startTimestamp));
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Metadata>, I>>(base?: I): SfWe_Plan_Metadata {
    return SfWe_Plan_Metadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Metadata>, I>>(object: I): SfWe_Plan_Metadata {
    const message = createBaseSfWe_Plan_Metadata();
    message.customerCode = object.customerCode ?? undefined;
    message.formType = object.formType ?? undefined;
    message.sfid = object.sfid ?? undefined;
    message.sfmAppName = object.sfmAppName ?? undefined;
    message.startDate = object.startDate ?? undefined;
    message.startTimestamp = object.startTimestamp ?? undefined;
    message.url = object.url ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_OtherMedicareCoverage(): SfWe_Plan_OtherMedicareCoverage {
  return {
    has: undefined,
    droppingMedSupp: undefined,
    endDate: undefined,
    endDateTimestamp: undefined,
    firstTime: undefined,
    name: undefined,
    policyId: undefined,
    startDate: undefined,
    startDateTimestamp: undefined,
    stillCovered: undefined,
  };
}

export const SfWe_Plan_OtherMedicareCoverage = {
  encode(message: SfWe_Plan_OtherMedicareCoverage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.droppingMedSupp !== undefined) {
      writer.uint32(18).string(message.droppingMedSupp);
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.endDateTimestamp !== undefined) {
      writer.uint32(32).int64(message.endDateTimestamp);
    }
    if (message.firstTime !== undefined) {
      writer.uint32(42).string(message.firstTime);
    }
    if (message.name !== undefined) {
      writer.uint32(50).string(message.name);
    }
    if (message.policyId !== undefined) {
      writer.uint32(58).string(message.policyId);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(66).fork()).ldelim();
    }
    if (message.startDateTimestamp !== undefined) {
      writer.uint32(72).int64(message.startDateTimestamp);
    }
    if (message.stillCovered !== undefined) {
      writer.uint32(82).string(message.stillCovered);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_OtherMedicareCoverage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_OtherMedicareCoverage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.droppingMedSupp = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.endDateTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firstTime = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.policyId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.startDateTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.stillCovered = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_OtherMedicareCoverage {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      droppingMedSupp: isSet(object.droppingMedSupp) ? String(object.droppingMedSupp) : undefined,
      endDate: isSet(object.endDate) ? fromJsonTimestamp(object.endDate) : undefined,
      endDateTimestamp: isSet(object.endDateTimestamp) ? Number(object.endDateTimestamp) : undefined,
      firstTime: isSet(object.firstTime) ? String(object.firstTime) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      policyId: isSet(object.policyId) ? String(object.policyId) : undefined,
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      startDateTimestamp: isSet(object.startDateTimestamp) ? Number(object.startDateTimestamp) : undefined,
      stillCovered: isSet(object.stillCovered) ? String(object.stillCovered) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_OtherMedicareCoverage): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.droppingMedSupp !== undefined && (obj.droppingMedSupp = message.droppingMedSupp);
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    message.endDateTimestamp !== undefined && (obj.endDateTimestamp = Math.round(message.endDateTimestamp));
    message.firstTime !== undefined && (obj.firstTime = message.firstTime);
    message.name !== undefined && (obj.name = message.name);
    message.policyId !== undefined && (obj.policyId = message.policyId);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.startDateTimestamp !== undefined && (obj.startDateTimestamp = Math.round(message.startDateTimestamp));
    message.stillCovered !== undefined && (obj.stillCovered = message.stillCovered);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_OtherMedicareCoverage>, I>>(base?: I): SfWe_Plan_OtherMedicareCoverage {
    return SfWe_Plan_OtherMedicareCoverage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_OtherMedicareCoverage>, I>>(
    object: I,
  ): SfWe_Plan_OtherMedicareCoverage {
    const message = createBaseSfWe_Plan_OtherMedicareCoverage();
    message.has = object.has ?? undefined;
    message.droppingMedSupp = object.droppingMedSupp ?? undefined;
    message.endDate = object.endDate ?? undefined;
    message.endDateTimestamp = object.endDateTimestamp ?? undefined;
    message.firstTime = object.firstTime ?? undefined;
    message.name = object.name ?? undefined;
    message.policyId = object.policyId ?? undefined;
    message.startDate = object.startDate ?? undefined;
    message.startDateTimestamp = object.startDateTimestamp ?? undefined;
    message.stillCovered = object.stillCovered ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_OtherDrugCoverage(): SfWe_Plan_OtherDrugCoverage {
  return { has: undefined, memberId: undefined, memberName: undefined, policyId: undefined };
}

export const SfWe_Plan_OtherDrugCoverage = {
  encode(message: SfWe_Plan_OtherDrugCoverage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.memberId !== undefined) {
      writer.uint32(18).string(message.memberId);
    }
    if (message.memberName !== undefined) {
      writer.uint32(26).string(message.memberName);
    }
    if (message.policyId !== undefined) {
      writer.uint32(34).string(message.policyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_OtherDrugCoverage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_OtherDrugCoverage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.memberId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.memberName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.policyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_OtherDrugCoverage {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      memberId: isSet(object.memberId) ? String(object.memberId) : undefined,
      memberName: isSet(object.memberName) ? String(object.memberName) : undefined,
      policyId: isSet(object.policyId) ? String(object.policyId) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_OtherDrugCoverage): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.memberId !== undefined && (obj.memberId = message.memberId);
    message.memberName !== undefined && (obj.memberName = message.memberName);
    message.policyId !== undefined && (obj.policyId = message.policyId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_OtherDrugCoverage>, I>>(base?: I): SfWe_Plan_OtherDrugCoverage {
    return SfWe_Plan_OtherDrugCoverage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_OtherDrugCoverage>, I>>(object: I): SfWe_Plan_OtherDrugCoverage {
    const message = createBaseSfWe_Plan_OtherDrugCoverage();
    message.has = object.has ?? undefined;
    message.memberId = object.memberId ?? undefined;
    message.memberName = object.memberName ?? undefined;
    message.policyId = object.policyId ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_OtherGroupCoverage(): SfWe_Plan_OtherGroupCoverage {
  return {
    has: undefined,
    name: undefined,
    policyId: undefined,
    coverageIncludesRx: undefined,
    groupPolicyId: undefined,
    address: undefined,
    carrier: undefined,
    coverageIncludesPrescriptionDrugCoverage: undefined,
    memberId: undefined,
  };
}

export const SfWe_Plan_OtherGroupCoverage = {
  encode(message: SfWe_Plan_OtherGroupCoverage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.policyId !== undefined) {
      writer.uint32(26).string(message.policyId);
    }
    if (message.coverageIncludesRx !== undefined) {
      writer.uint32(32).bool(message.coverageIncludesRx);
    }
    if (message.groupPolicyId !== undefined) {
      writer.uint32(42).string(message.groupPolicyId);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(50).fork()).ldelim();
    }
    if (message.carrier !== undefined) {
      SfWe_Plan_OtherGroupCoverage_Carrier.encode(message.carrier, writer.uint32(58).fork()).ldelim();
    }
    if (message.coverageIncludesPrescriptionDrugCoverage !== undefined) {
      writer.uint32(64).bool(message.coverageIncludesPrescriptionDrugCoverage);
    }
    if (message.memberId !== undefined) {
      writer.uint32(74).string(message.memberId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_OtherGroupCoverage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_OtherGroupCoverage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.policyId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.coverageIncludesRx = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.groupPolicyId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.carrier = SfWe_Plan_OtherGroupCoverage_Carrier.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.coverageIncludesPrescriptionDrugCoverage = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.memberId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_OtherGroupCoverage {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      policyId: isSet(object.policyId) ? String(object.policyId) : undefined,
      coverageIncludesRx: isSet(object.coverageIncludesRx) ? Boolean(object.coverageIncludesRx) : undefined,
      groupPolicyId: isSet(object.groupPolicyId) ? String(object.groupPolicyId) : undefined,
      address: isSet(object.address) ? Address.fromJSON(object.address) : undefined,
      carrier: isSet(object.carrier) ? SfWe_Plan_OtherGroupCoverage_Carrier.fromJSON(object.carrier) : undefined,
      coverageIncludesPrescriptionDrugCoverage: isSet(object.coverageIncludesPrescriptionDrugCoverage)
        ? Boolean(object.coverageIncludesPrescriptionDrugCoverage)
        : undefined,
      memberId: isSet(object.memberId) ? String(object.memberId) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_OtherGroupCoverage): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.name !== undefined && (obj.name = message.name);
    message.policyId !== undefined && (obj.policyId = message.policyId);
    message.coverageIncludesRx !== undefined && (obj.coverageIncludesRx = message.coverageIncludesRx);
    message.groupPolicyId !== undefined && (obj.groupPolicyId = message.groupPolicyId);
    message.address !== undefined && (obj.address = message.address ? Address.toJSON(message.address) : undefined);
    message.carrier !== undefined &&
      (obj.carrier = message.carrier ? SfWe_Plan_OtherGroupCoverage_Carrier.toJSON(message.carrier) : undefined);
    message.coverageIncludesPrescriptionDrugCoverage !== undefined &&
      (obj.coverageIncludesPrescriptionDrugCoverage = message.coverageIncludesPrescriptionDrugCoverage);
    message.memberId !== undefined && (obj.memberId = message.memberId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_OtherGroupCoverage>, I>>(base?: I): SfWe_Plan_OtherGroupCoverage {
    return SfWe_Plan_OtherGroupCoverage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_OtherGroupCoverage>, I>>(object: I): SfWe_Plan_OtherGroupCoverage {
    const message = createBaseSfWe_Plan_OtherGroupCoverage();
    message.has = object.has ?? undefined;
    message.name = object.name ?? undefined;
    message.policyId = object.policyId ?? undefined;
    message.coverageIncludesRx = object.coverageIncludesRx ?? undefined;
    message.groupPolicyId = object.groupPolicyId ?? undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    message.carrier = (object.carrier !== undefined && object.carrier !== null)
      ? SfWe_Plan_OtherGroupCoverage_Carrier.fromPartial(object.carrier)
      : undefined;
    message.coverageIncludesPrescriptionDrugCoverage = object.coverageIncludesPrescriptionDrugCoverage ?? undefined;
    message.memberId = object.memberId ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_OtherGroupCoverage_Carrier(): SfWe_Plan_OtherGroupCoverage_Carrier {
  return { id: undefined, name: undefined };
}

export const SfWe_Plan_OtherGroupCoverage_Carrier = {
  encode(message: SfWe_Plan_OtherGroupCoverage_Carrier, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_OtherGroupCoverage_Carrier {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_OtherGroupCoverage_Carrier();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_OtherGroupCoverage_Carrier {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_OtherGroupCoverage_Carrier): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_OtherGroupCoverage_Carrier>, I>>(
    base?: I,
  ): SfWe_Plan_OtherGroupCoverage_Carrier {
    return SfWe_Plan_OtherGroupCoverage_Carrier.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_OtherGroupCoverage_Carrier>, I>>(
    object: I,
  ): SfWe_Plan_OtherGroupCoverage_Carrier {
    const message = createBaseSfWe_Plan_OtherGroupCoverage_Carrier();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Parameters(): SfWe_Plan_Parameters {
  return { ipAddress: undefined, partnerId: undefined };
}

export const SfWe_Plan_Parameters = {
  encode(message: SfWe_Plan_Parameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ipAddress !== undefined) {
      writer.uint32(10).string(message.ipAddress);
    }
    if (message.partnerId !== undefined) {
      writer.uint32(18).string(message.partnerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Parameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Parameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ipAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.partnerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Parameters {
    return {
      ipAddress: isSet(object.ipAddress) ? String(object.ipAddress) : undefined,
      partnerId: isSet(object.partnerId) ? String(object.partnerId) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Parameters): unknown {
    const obj: any = {};
    message.ipAddress !== undefined && (obj.ipAddress = message.ipAddress);
    message.partnerId !== undefined && (obj.partnerId = message.partnerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Parameters>, I>>(base?: I): SfWe_Plan_Parameters {
    return SfWe_Plan_Parameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Parameters>, I>>(object: I): SfWe_Plan_Parameters {
    const message = createBaseSfWe_Plan_Parameters();
    message.ipAddress = object.ipAddress ?? undefined;
    message.partnerId = object.partnerId ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_PCP(): SfWe_Plan_PCP {
  return { designatePCP: undefined, establishedPatient: undefined, id: undefined, name: undefined };
}

export const SfWe_Plan_PCP = {
  encode(message: SfWe_Plan_PCP, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.designatePCP !== undefined) {
      writer.uint32(8).bool(message.designatePCP);
    }
    if (message.establishedPatient !== undefined) {
      writer.uint32(16).bool(message.establishedPatient);
    }
    if (message.id !== undefined) {
      writer.uint32(26).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_PCP {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_PCP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.designatePCP = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.establishedPatient = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_PCP {
    return {
      designatePCP: isSet(object.designatePCP) ? Boolean(object.designatePCP) : undefined,
      establishedPatient: isSet(object.establishedPatient) ? Boolean(object.establishedPatient) : undefined,
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_PCP): unknown {
    const obj: any = {};
    message.designatePCP !== undefined && (obj.designatePCP = message.designatePCP);
    message.establishedPatient !== undefined && (obj.establishedPatient = message.establishedPatient);
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_PCP>, I>>(base?: I): SfWe_Plan_PCP {
    return SfWe_Plan_PCP.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_PCP>, I>>(object: I): SfWe_Plan_PCP {
    const message = createBaseSfWe_Plan_PCP();
    message.designatePCP = object.designatePCP ?? undefined;
    message.establishedPatient = object.establishedPatient ?? undefined;
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Plan(): SfWe_Plan_Plan {
  return {
    brandId: undefined,
    brandName: undefined,
    carrierId: undefined,
    carrierName: undefined,
    contractId: undefined,
    displayId: undefined,
    displayName: undefined,
    effectiveDate: undefined,
    effectiveTimestamp: undefined,
    effectiveDateDay: undefined,
    effectiveDateMonth: undefined,
    effectiveDateYear: undefined,
    fmtPremium: undefined,
    id: undefined,
    name: undefined,
    planPolicyType: undefined,
    premium: undefined,
    providerDirectoryWebAddress: undefined,
    segmentId: undefined,
    snp: undefined,
    type: undefined,
    year: undefined,
    category: undefined,
    annualPremium: undefined,
    faceAmount: undefined,
    modalPremium: undefined,
  };
}

export const SfWe_Plan_Plan = {
  encode(message: SfWe_Plan_Plan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brandId !== undefined) {
      writer.uint32(10).string(message.brandId);
    }
    if (message.brandName !== undefined) {
      writer.uint32(18).string(message.brandName);
    }
    if (message.carrierId !== undefined) {
      writer.uint32(26).string(message.carrierId);
    }
    if (message.carrierName !== undefined) {
      writer.uint32(34).string(message.carrierName);
    }
    if (message.contractId !== undefined) {
      writer.uint32(42).string(message.contractId);
    }
    if (message.displayId !== undefined) {
      writer.uint32(50).string(message.displayId);
    }
    if (message.displayName !== undefined) {
      writer.uint32(58).string(message.displayName);
    }
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(66).fork()).ldelim();
    }
    if (message.effectiveTimestamp !== undefined) {
      writer.uint32(72).int64(message.effectiveTimestamp);
    }
    if (message.effectiveDateDay !== undefined) {
      writer.uint32(80).int32(message.effectiveDateDay);
    }
    if (message.effectiveDateMonth !== undefined) {
      writer.uint32(88).int32(message.effectiveDateMonth);
    }
    if (message.effectiveDateYear !== undefined) {
      writer.uint32(96).int32(message.effectiveDateYear);
    }
    if (message.fmtPremium !== undefined) {
      writer.uint32(106).string(message.fmtPremium);
    }
    if (message.id !== undefined) {
      writer.uint32(114).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(122).string(message.name);
    }
    if (message.planPolicyType !== undefined) {
      writer.uint32(130).string(message.planPolicyType);
    }
    if (message.premium !== undefined) {
      writer.uint32(137).double(message.premium);
    }
    if (message.providerDirectoryWebAddress !== undefined) {
      writer.uint32(146).string(message.providerDirectoryWebAddress);
    }
    if (message.segmentId !== undefined) {
      writer.uint32(154).string(message.segmentId);
    }
    if (message.snp !== undefined) {
      writer.uint32(162).string(message.snp);
    }
    if (message.type !== undefined) {
      writer.uint32(170).string(message.type);
    }
    if (message.year !== undefined) {
      writer.uint32(178).string(message.year);
    }
    if (message.category !== undefined) {
      writer.uint32(184).int32(message.category);
    }
    if (message.annualPremium !== undefined) {
      writer.uint32(193).double(message.annualPremium);
    }
    if (message.faceAmount !== undefined) {
      writer.uint32(201).double(message.faceAmount);
    }
    if (message.modalPremium !== undefined) {
      writer.uint32(209).double(message.modalPremium);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Plan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Plan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brandId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brandName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.carrierId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.carrierName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.contractId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.displayId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.effectiveTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.effectiveDateDay = reader.int32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.effectiveDateMonth = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.effectiveDateYear = reader.int32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.fmtPremium = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.id = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.name = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.planPolicyType = reader.string();
          continue;
        case 17:
          if (tag !== 137) {
            break;
          }

          message.premium = reader.double();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.providerDirectoryWebAddress = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.segmentId = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.snp = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.type = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.year = reader.string();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 24:
          if (tag !== 193) {
            break;
          }

          message.annualPremium = reader.double();
          continue;
        case 25:
          if (tag !== 201) {
            break;
          }

          message.faceAmount = reader.double();
          continue;
        case 26:
          if (tag !== 209) {
            break;
          }

          message.modalPremium = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Plan {
    return {
      brandId: isSet(object.brandId) ? String(object.brandId) : undefined,
      brandName: isSet(object.brandName) ? String(object.brandName) : undefined,
      carrierId: isSet(object.carrierId) ? String(object.carrierId) : undefined,
      carrierName: isSet(object.carrierName) ? String(object.carrierName) : undefined,
      contractId: isSet(object.contractId) ? String(object.contractId) : undefined,
      displayId: isSet(object.displayId) ? String(object.displayId) : undefined,
      displayName: isSet(object.displayName) ? String(object.displayName) : undefined,
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      effectiveTimestamp: isSet(object.effectiveTimestamp) ? Number(object.effectiveTimestamp) : undefined,
      effectiveDateDay: isSet(object.effectiveDateDay) ? Number(object.effectiveDateDay) : undefined,
      effectiveDateMonth: isSet(object.effectiveDateMonth) ? Number(object.effectiveDateMonth) : undefined,
      effectiveDateYear: isSet(object.effectiveDateYear) ? Number(object.effectiveDateYear) : undefined,
      fmtPremium: isSet(object.fmtPremium) ? String(object.fmtPremium) : undefined,
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      planPolicyType: isSet(object.planPolicyType) ? String(object.planPolicyType) : undefined,
      premium: isSet(object.premium) ? Number(object.premium) : undefined,
      providerDirectoryWebAddress: isSet(object.providerDirectoryWebAddress)
        ? String(object.providerDirectoryWebAddress)
        : undefined,
      segmentId: isSet(object.segmentId) ? String(object.segmentId) : undefined,
      snp: isSet(object.snp) ? String(object.snp) : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      year: isSet(object.year) ? String(object.year) : undefined,
      category: isSet(object.category) ? sfWe_PlanCategoryFromJSON(object.category) : undefined,
      annualPremium: isSet(object.annualPremium) ? Number(object.annualPremium) : undefined,
      faceAmount: isSet(object.faceAmount) ? Number(object.faceAmount) : undefined,
      modalPremium: isSet(object.modalPremium) ? Number(object.modalPremium) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Plan): unknown {
    const obj: any = {};
    message.brandId !== undefined && (obj.brandId = message.brandId);
    message.brandName !== undefined && (obj.brandName = message.brandName);
    message.carrierId !== undefined && (obj.carrierId = message.carrierId);
    message.carrierName !== undefined && (obj.carrierName = message.carrierName);
    message.contractId !== undefined && (obj.contractId = message.contractId);
    message.displayId !== undefined && (obj.displayId = message.displayId);
    message.displayName !== undefined && (obj.displayName = message.displayName);
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.effectiveTimestamp !== undefined && (obj.effectiveTimestamp = Math.round(message.effectiveTimestamp));
    message.effectiveDateDay !== undefined && (obj.effectiveDateDay = Math.round(message.effectiveDateDay));
    message.effectiveDateMonth !== undefined && (obj.effectiveDateMonth = Math.round(message.effectiveDateMonth));
    message.effectiveDateYear !== undefined && (obj.effectiveDateYear = Math.round(message.effectiveDateYear));
    message.fmtPremium !== undefined && (obj.fmtPremium = message.fmtPremium);
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.planPolicyType !== undefined && (obj.planPolicyType = message.planPolicyType);
    message.premium !== undefined && (obj.premium = message.premium);
    message.providerDirectoryWebAddress !== undefined &&
      (obj.providerDirectoryWebAddress = message.providerDirectoryWebAddress);
    message.segmentId !== undefined && (obj.segmentId = message.segmentId);
    message.snp !== undefined && (obj.snp = message.snp);
    message.type !== undefined && (obj.type = message.type);
    message.year !== undefined && (obj.year = message.year);
    message.category !== undefined &&
      (obj.category = message.category !== undefined ? sfWe_PlanCategoryToJSON(message.category) : undefined);
    message.annualPremium !== undefined && (obj.annualPremium = message.annualPremium);
    message.faceAmount !== undefined && (obj.faceAmount = message.faceAmount);
    message.modalPremium !== undefined && (obj.modalPremium = message.modalPremium);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Plan>, I>>(base?: I): SfWe_Plan_Plan {
    return SfWe_Plan_Plan.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Plan>, I>>(object: I): SfWe_Plan_Plan {
    const message = createBaseSfWe_Plan_Plan();
    message.brandId = object.brandId ?? undefined;
    message.brandName = object.brandName ?? undefined;
    message.carrierId = object.carrierId ?? undefined;
    message.carrierName = object.carrierName ?? undefined;
    message.contractId = object.contractId ?? undefined;
    message.displayId = object.displayId ?? undefined;
    message.displayName = object.displayName ?? undefined;
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.effectiveTimestamp = object.effectiveTimestamp ?? undefined;
    message.effectiveDateDay = object.effectiveDateDay ?? undefined;
    message.effectiveDateMonth = object.effectiveDateMonth ?? undefined;
    message.effectiveDateYear = object.effectiveDateYear ?? undefined;
    message.fmtPremium = object.fmtPremium ?? undefined;
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.planPolicyType = object.planPolicyType ?? undefined;
    message.premium = object.premium ?? undefined;
    message.providerDirectoryWebAddress = object.providerDirectoryWebAddress ?? undefined;
    message.segmentId = object.segmentId ?? undefined;
    message.snp = object.snp ?? undefined;
    message.type = object.type ?? undefined;
    message.year = object.year ?? undefined;
    message.category = object.category ?? undefined;
    message.annualPremium = object.annualPremium ?? undefined;
    message.faceAmount = object.faceAmount ?? undefined;
    message.modalPremium = object.modalPremium ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Premium(): SfWe_Plan_Premium {
  return { deducted: undefined, withhold: undefined };
}

export const SfWe_Plan_Premium = {
  encode(message: SfWe_Plan_Premium, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deducted !== undefined) {
      writer.uint32(9).double(message.deducted);
    }
    if (message.withhold !== undefined) {
      writer.uint32(17).double(message.withhold);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Premium {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Premium();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.deducted = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.withhold = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Premium {
    return {
      deducted: isSet(object.deducted) ? Number(object.deducted) : undefined,
      withhold: isSet(object.withhold) ? Number(object.withhold) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Premium): unknown {
    const obj: any = {};
    message.deducted !== undefined && (obj.deducted = message.deducted);
    message.withhold !== undefined && (obj.withhold = message.withhold);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Premium>, I>>(base?: I): SfWe_Plan_Premium {
    return SfWe_Plan_Premium.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Premium>, I>>(object: I): SfWe_Plan_Premium {
    const message = createBaseSfWe_Plan_Premium();
    message.deducted = object.deducted ?? undefined;
    message.withhold = object.withhold ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Commissions(): SfWe_Plan_Commissions {
  return {
    paymentDescription: undefined,
    amount: undefined,
    statementDate: undefined,
    statementTimestamp: undefined,
    statementDateDay: undefined,
    statementDateMonth: undefined,
    statementDateYear: undefined,
    amountAgency: undefined,
    amountAgent: undefined,
  };
}

export const SfWe_Plan_Commissions = {
  encode(message: SfWe_Plan_Commissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paymentDescription !== undefined) {
      writer.uint32(10).string(message.paymentDescription);
    }
    if (message.amount !== undefined) {
      writer.uint32(17).double(message.amount);
    }
    if (message.statementDate !== undefined) {
      Timestamp.encode(toTimestamp(message.statementDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.statementTimestamp !== undefined) {
      writer.uint32(32).int64(message.statementTimestamp);
    }
    if (message.statementDateDay !== undefined) {
      writer.uint32(40).int32(message.statementDateDay);
    }
    if (message.statementDateMonth !== undefined) {
      writer.uint32(48).int32(message.statementDateMonth);
    }
    if (message.statementDateYear !== undefined) {
      writer.uint32(56).int32(message.statementDateYear);
    }
    if (message.amountAgency !== undefined) {
      writer.uint32(65).double(message.amountAgency);
    }
    if (message.amountAgent !== undefined) {
      writer.uint32(73).double(message.amountAgent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Commissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Commissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paymentDescription = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.amount = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.statementDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.statementTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.statementDateDay = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.statementDateMonth = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.statementDateYear = reader.int32();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.amountAgency = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.amountAgent = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Commissions {
    return {
      paymentDescription: isSet(object.paymentDescription) ? String(object.paymentDescription) : undefined,
      amount: isSet(object.amount) ? Number(object.amount) : undefined,
      statementDate: isSet(object.statementDate) ? fromJsonTimestamp(object.statementDate) : undefined,
      statementTimestamp: isSet(object.statementTimestamp) ? Number(object.statementTimestamp) : undefined,
      statementDateDay: isSet(object.statementDateDay) ? Number(object.statementDateDay) : undefined,
      statementDateMonth: isSet(object.statementDateMonth) ? Number(object.statementDateMonth) : undefined,
      statementDateYear: isSet(object.statementDateYear) ? Number(object.statementDateYear) : undefined,
      amountAgency: isSet(object.amountAgency) ? Number(object.amountAgency) : undefined,
      amountAgent: isSet(object.amountAgent) ? Number(object.amountAgent) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Commissions): unknown {
    const obj: any = {};
    message.paymentDescription !== undefined && (obj.paymentDescription = message.paymentDescription);
    message.amount !== undefined && (obj.amount = message.amount);
    message.statementDate !== undefined && (obj.statementDate = message.statementDate.toISOString());
    message.statementTimestamp !== undefined && (obj.statementTimestamp = Math.round(message.statementTimestamp));
    message.statementDateDay !== undefined && (obj.statementDateDay = Math.round(message.statementDateDay));
    message.statementDateMonth !== undefined && (obj.statementDateMonth = Math.round(message.statementDateMonth));
    message.statementDateYear !== undefined && (obj.statementDateYear = Math.round(message.statementDateYear));
    message.amountAgency !== undefined && (obj.amountAgency = message.amountAgency);
    message.amountAgent !== undefined && (obj.amountAgent = message.amountAgent);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Commissions>, I>>(base?: I): SfWe_Plan_Commissions {
    return SfWe_Plan_Commissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Commissions>, I>>(object: I): SfWe_Plan_Commissions {
    const message = createBaseSfWe_Plan_Commissions();
    message.paymentDescription = object.paymentDescription ?? undefined;
    message.amount = object.amount ?? undefined;
    message.statementDate = object.statementDate ?? undefined;
    message.statementTimestamp = object.statementTimestamp ?? undefined;
    message.statementDateDay = object.statementDateDay ?? undefined;
    message.statementDateMonth = object.statementDateMonth ?? undefined;
    message.statementDateYear = object.statementDateYear ?? undefined;
    message.amountAgency = object.amountAgency ?? undefined;
    message.amountAgent = object.amountAgent ?? undefined;
    return message;
  },
};

function createBaseSfWe_Plan_Allegation(): SfWe_Plan_Allegation {
  return {
    id: undefined,
    active: undefined,
    note: undefined,
    result: undefined,
    complaint: undefined,
    date: undefined,
    dateDay: undefined,
    dateMonth: undefined,
    dateYear: undefined,
    dateTimestamp: undefined,
    dueDate: undefined,
    dueDateDay: undefined,
    dueDateMonth: undefined,
    dueDateYear: undefined,
    dueDateTimestamp: undefined,
  };
}

export const SfWe_Plan_Allegation = {
  encode(message: SfWe_Plan_Allegation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.active !== undefined) {
      writer.uint32(16).bool(message.active);
    }
    if (message.note !== undefined) {
      writer.uint32(26).string(message.note);
    }
    if (message.result !== undefined) {
      writer.uint32(34).string(message.result);
    }
    if (message.complaint !== undefined) {
      writer.uint32(42).string(message.complaint);
    }
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(50).fork()).ldelim();
    }
    if (message.dateDay !== undefined) {
      writer.uint32(56).int32(message.dateDay);
    }
    if (message.dateMonth !== undefined) {
      writer.uint32(64).int32(message.dateMonth);
    }
    if (message.dateYear !== undefined) {
      writer.uint32(72).int32(message.dateYear);
    }
    if (message.dateTimestamp !== undefined) {
      writer.uint32(80).int64(message.dateTimestamp);
    }
    if (message.dueDate !== undefined) {
      Timestamp.encode(toTimestamp(message.dueDate), writer.uint32(90).fork()).ldelim();
    }
    if (message.dueDateDay !== undefined) {
      writer.uint32(96).int32(message.dueDateDay);
    }
    if (message.dueDateMonth !== undefined) {
      writer.uint32(104).int32(message.dueDateMonth);
    }
    if (message.dueDateYear !== undefined) {
      writer.uint32(112).int32(message.dueDateYear);
    }
    if (message.dueDateTimestamp !== undefined) {
      writer.uint32(120).int64(message.dueDateTimestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Plan_Allegation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Plan_Allegation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.note = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.result = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.complaint = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.dateDay = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.dateMonth = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.dateYear = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.dateTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.dueDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.dueDateDay = reader.int32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.dueDateMonth = reader.int32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.dueDateYear = reader.int32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.dueDateTimestamp = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Plan_Allegation {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
      note: isSet(object.note) ? String(object.note) : undefined,
      result: isSet(object.result) ? String(object.result) : undefined,
      complaint: isSet(object.complaint) ? String(object.complaint) : undefined,
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      dateDay: isSet(object.dateDay) ? Number(object.dateDay) : undefined,
      dateMonth: isSet(object.dateMonth) ? Number(object.dateMonth) : undefined,
      dateYear: isSet(object.dateYear) ? Number(object.dateYear) : undefined,
      dateTimestamp: isSet(object.dateTimestamp) ? Number(object.dateTimestamp) : undefined,
      dueDate: isSet(object.dueDate) ? fromJsonTimestamp(object.dueDate) : undefined,
      dueDateDay: isSet(object.dueDateDay) ? Number(object.dueDateDay) : undefined,
      dueDateMonth: isSet(object.dueDateMonth) ? Number(object.dueDateMonth) : undefined,
      dueDateYear: isSet(object.dueDateYear) ? Number(object.dueDateYear) : undefined,
      dueDateTimestamp: isSet(object.dueDateTimestamp) ? Number(object.dueDateTimestamp) : undefined,
    };
  },

  toJSON(message: SfWe_Plan_Allegation): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.active !== undefined && (obj.active = message.active);
    message.note !== undefined && (obj.note = message.note);
    message.result !== undefined && (obj.result = message.result);
    message.complaint !== undefined && (obj.complaint = message.complaint);
    message.date !== undefined && (obj.date = message.date.toISOString());
    message.dateDay !== undefined && (obj.dateDay = Math.round(message.dateDay));
    message.dateMonth !== undefined && (obj.dateMonth = Math.round(message.dateMonth));
    message.dateYear !== undefined && (obj.dateYear = Math.round(message.dateYear));
    message.dateTimestamp !== undefined && (obj.dateTimestamp = Math.round(message.dateTimestamp));
    message.dueDate !== undefined && (obj.dueDate = message.dueDate.toISOString());
    message.dueDateDay !== undefined && (obj.dueDateDay = Math.round(message.dueDateDay));
    message.dueDateMonth !== undefined && (obj.dueDateMonth = Math.round(message.dueDateMonth));
    message.dueDateYear !== undefined && (obj.dueDateYear = Math.round(message.dueDateYear));
    message.dueDateTimestamp !== undefined && (obj.dueDateTimestamp = Math.round(message.dueDateTimestamp));
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Plan_Allegation>, I>>(base?: I): SfWe_Plan_Allegation {
    return SfWe_Plan_Allegation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Plan_Allegation>, I>>(object: I): SfWe_Plan_Allegation {
    const message = createBaseSfWe_Plan_Allegation();
    message.id = object.id ?? undefined;
    message.active = object.active ?? undefined;
    message.note = object.note ?? undefined;
    message.result = object.result ?? undefined;
    message.complaint = object.complaint ?? undefined;
    message.date = object.date ?? undefined;
    message.dateDay = object.dateDay ?? undefined;
    message.dateMonth = object.dateMonth ?? undefined;
    message.dateYear = object.dateYear ?? undefined;
    message.dateTimestamp = object.dateTimestamp ?? undefined;
    message.dueDate = object.dueDate ?? undefined;
    message.dueDateDay = object.dueDateDay ?? undefined;
    message.dueDateMonth = object.dueDateMonth ?? undefined;
    message.dueDateYear = object.dueDateYear ?? undefined;
    message.dueDateTimestamp = object.dueDateTimestamp ?? undefined;
    return message;
  },
};

function createBaseSfWe_Consumer(): SfWe_Consumer {
  return {
    id: "",
    dateOfBirth: undefined,
    dateOfBirthTimestamp: undefined,
    dateOfBirthDay: undefined,
    dateOfBirthMonth: undefined,
    dateOfBirthYear: undefined,
    email: undefined,
    firstName: undefined,
    gender: undefined,
    homeAddress: undefined,
    initial: undefined,
    language: undefined,
    lastName: undefined,
    medicareNumber: undefined,
    phone: undefined,
    middleName: undefined,
    nameSuffix: undefined,
    hicn: undefined,
  };
}

export const SfWe_Consumer = {
  encode(message: SfWe_Consumer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.dateOfBirth !== undefined) {
      Timestamp.encode(toTimestamp(message.dateOfBirth), writer.uint32(18).fork()).ldelim();
    }
    if (message.dateOfBirthTimestamp !== undefined) {
      writer.uint32(24).int64(message.dateOfBirthTimestamp);
    }
    if (message.dateOfBirthDay !== undefined) {
      writer.uint32(32).int32(message.dateOfBirthDay);
    }
    if (message.dateOfBirthMonth !== undefined) {
      writer.uint32(40).int32(message.dateOfBirthMonth);
    }
    if (message.dateOfBirthYear !== undefined) {
      writer.uint32(48).int32(message.dateOfBirthYear);
    }
    if (message.email !== undefined) {
      writer.uint32(58).string(message.email);
    }
    if (message.firstName !== undefined) {
      writer.uint32(66).string(message.firstName);
    }
    if (message.gender !== undefined) {
      writer.uint32(74).string(message.gender);
    }
    if (message.homeAddress !== undefined) {
      Address.encode(message.homeAddress, writer.uint32(82).fork()).ldelim();
    }
    if (message.initial !== undefined) {
      writer.uint32(90).string(message.initial);
    }
    if (message.language !== undefined) {
      writer.uint32(98).string(message.language);
    }
    if (message.lastName !== undefined) {
      writer.uint32(106).string(message.lastName);
    }
    if (message.medicareNumber !== undefined) {
      writer.uint32(114).string(message.medicareNumber);
    }
    if (message.phone !== undefined) {
      writer.uint32(122).string(message.phone);
    }
    if (message.middleName !== undefined) {
      writer.uint32(130).string(message.middleName);
    }
    if (message.nameSuffix !== undefined) {
      writer.uint32(138).string(message.nameSuffix);
    }
    if (message.hicn !== undefined) {
      writer.uint32(146).string(message.hicn);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Consumer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Consumer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dateOfBirth = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.dateOfBirthTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dateOfBirthDay = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.dateOfBirthMonth = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.dateOfBirthYear = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.email = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.homeAddress = Address.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.initial = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.language = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.medicareNumber = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.middleName = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.nameSuffix = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.hicn = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Consumer {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      dateOfBirth: isSet(object.dateOfBirth) ? fromJsonTimestamp(object.dateOfBirth) : undefined,
      dateOfBirthTimestamp: isSet(object.dateOfBirthTimestamp) ? Number(object.dateOfBirthTimestamp) : undefined,
      dateOfBirthDay: isSet(object.dateOfBirthDay) ? Number(object.dateOfBirthDay) : undefined,
      dateOfBirthMonth: isSet(object.dateOfBirthMonth) ? Number(object.dateOfBirthMonth) : undefined,
      dateOfBirthYear: isSet(object.dateOfBirthYear) ? Number(object.dateOfBirthYear) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      gender: isSet(object.gender) ? String(object.gender) : undefined,
      homeAddress: isSet(object.homeAddress) ? Address.fromJSON(object.homeAddress) : undefined,
      initial: isSet(object.initial) ? String(object.initial) : undefined,
      language: isSet(object.language) ? String(object.language) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      medicareNumber: isSet(object.medicareNumber) ? String(object.medicareNumber) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      middleName: isSet(object.middleName) ? String(object.middleName) : undefined,
      nameSuffix: isSet(object.nameSuffix) ? String(object.nameSuffix) : undefined,
      hicn: isSet(object.hicn) ? String(object.hicn) : undefined,
    };
  },

  toJSON(message: SfWe_Consumer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.dateOfBirth !== undefined && (obj.dateOfBirth = message.dateOfBirth.toISOString());
    message.dateOfBirthTimestamp !== undefined && (obj.dateOfBirthTimestamp = Math.round(message.dateOfBirthTimestamp));
    message.dateOfBirthDay !== undefined && (obj.dateOfBirthDay = Math.round(message.dateOfBirthDay));
    message.dateOfBirthMonth !== undefined && (obj.dateOfBirthMonth = Math.round(message.dateOfBirthMonth));
    message.dateOfBirthYear !== undefined && (obj.dateOfBirthYear = Math.round(message.dateOfBirthYear));
    message.email !== undefined && (obj.email = message.email);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.gender !== undefined && (obj.gender = message.gender);
    message.homeAddress !== undefined &&
      (obj.homeAddress = message.homeAddress ? Address.toJSON(message.homeAddress) : undefined);
    message.initial !== undefined && (obj.initial = message.initial);
    message.language !== undefined && (obj.language = message.language);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.medicareNumber !== undefined && (obj.medicareNumber = message.medicareNumber);
    message.phone !== undefined && (obj.phone = message.phone);
    message.middleName !== undefined && (obj.middleName = message.middleName);
    message.nameSuffix !== undefined && (obj.nameSuffix = message.nameSuffix);
    message.hicn !== undefined && (obj.hicn = message.hicn);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Consumer>, I>>(base?: I): SfWe_Consumer {
    return SfWe_Consumer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Consumer>, I>>(object: I): SfWe_Consumer {
    const message = createBaseSfWe_Consumer();
    message.id = object.id ?? "";
    message.dateOfBirth = object.dateOfBirth ?? undefined;
    message.dateOfBirthTimestamp = object.dateOfBirthTimestamp ?? undefined;
    message.dateOfBirthDay = object.dateOfBirthDay ?? undefined;
    message.dateOfBirthMonth = object.dateOfBirthMonth ?? undefined;
    message.dateOfBirthYear = object.dateOfBirthYear ?? undefined;
    message.email = object.email ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.gender = object.gender ?? undefined;
    message.homeAddress = (object.homeAddress !== undefined && object.homeAddress !== null)
      ? Address.fromPartial(object.homeAddress)
      : undefined;
    message.initial = object.initial ?? undefined;
    message.language = object.language ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.medicareNumber = object.medicareNumber ?? undefined;
    message.phone = object.phone ?? undefined;
    message.middleName = object.middleName ?? undefined;
    message.nameSuffix = object.nameSuffix ?? undefined;
    message.hicn = object.hicn ?? undefined;
    return message;
  },
};

function createBaseSfWe_Agent(): SfWe_Agent {
  return {
    id: "",
    affiliateTOH: undefined,
    agentType: undefined,
    blazeAffId: undefined,
    blazeConnectAffId: undefined,
    broker: undefined,
    carrierAgencies: undefined,
    email: undefined,
    emberAffId: undefined,
    firstName: undefined,
    isAppointed: undefined,
    lastName: undefined,
    npn: undefined,
    partnerId: undefined,
    ssn: undefined,
    stateAbbr: undefined,
    username: undefined,
    writingId: undefined,
    writingIds: undefined,
    features: undefined,
    profileImage: undefined,
    phone: undefined,
    agencyId: undefined,
  };
}

export const SfWe_Agent = {
  encode(message: SfWe_Agent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.affiliateTOH !== undefined) {
      writer.uint32(18).string(message.affiliateTOH);
    }
    if (message.agentType !== undefined) {
      writer.uint32(26).string(message.agentType);
    }
    if (message.blazeAffId !== undefined) {
      writer.uint32(34).string(message.blazeAffId);
    }
    if (message.blazeConnectAffId !== undefined) {
      writer.uint32(42).string(message.blazeConnectAffId);
    }
    if (message.broker !== undefined) {
      SfWe_Agent_Broker.encode(message.broker, writer.uint32(50).fork()).ldelim();
    }
    if (message.carrierAgencies !== undefined) {
      writer.uint32(58).string(message.carrierAgencies);
    }
    if (message.email !== undefined) {
      writer.uint32(66).string(message.email);
    }
    if (message.emberAffId !== undefined) {
      writer.uint32(74).string(message.emberAffId);
    }
    if (message.firstName !== undefined) {
      writer.uint32(82).string(message.firstName);
    }
    if (message.isAppointed !== undefined) {
      writer.uint32(88).bool(message.isAppointed);
    }
    if (message.lastName !== undefined) {
      writer.uint32(98).string(message.lastName);
    }
    if (message.npn !== undefined) {
      writer.uint32(106).string(message.npn);
    }
    if (message.partnerId !== undefined) {
      writer.uint32(114).string(message.partnerId);
    }
    if (message.ssn !== undefined) {
      writer.uint32(122).string(message.ssn);
    }
    if (message.stateAbbr !== undefined) {
      writer.uint32(130).string(message.stateAbbr);
    }
    if (message.username !== undefined) {
      writer.uint32(138).string(message.username);
    }
    if (message.writingId !== undefined) {
      writer.uint32(146).string(message.writingId);
    }
    if (message.writingIds !== undefined) {
      Struct.encode(Struct.wrap(message.writingIds), writer.uint32(154).fork()).ldelim();
    }
    if (message.features !== undefined) {
      Features.encode(message.features, writer.uint32(162).fork()).ldelim();
    }
    if (message.profileImage !== undefined) {
      writer.uint32(170).string(message.profileImage);
    }
    if (message.phone !== undefined) {
      writer.uint32(178).string(message.phone);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(186).string(message.agencyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Agent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Agent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.affiliateTOH = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agentType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.blazeAffId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.blazeConnectAffId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.broker = SfWe_Agent_Broker.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.carrierAgencies = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.email = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.emberAffId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.isAppointed = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.partnerId = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.stateAbbr = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.username = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.writingId = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.writingIds = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.features = Features.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.agencyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Agent {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      affiliateTOH: isSet(object.affiliateTOH) ? String(object.affiliateTOH) : undefined,
      agentType: isSet(object.agentType) ? String(object.agentType) : undefined,
      blazeAffId: isSet(object.blazeAffId) ? String(object.blazeAffId) : undefined,
      blazeConnectAffId: isSet(object.blazeConnectAffId) ? String(object.blazeConnectAffId) : undefined,
      broker: isSet(object.broker) ? SfWe_Agent_Broker.fromJSON(object.broker) : undefined,
      carrierAgencies: isSet(object.carrierAgencies) ? String(object.carrierAgencies) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      emberAffId: isSet(object.emberAffId) ? String(object.emberAffId) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      isAppointed: isSet(object.isAppointed) ? Boolean(object.isAppointed) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      partnerId: isSet(object.partnerId) ? String(object.partnerId) : undefined,
      ssn: isSet(object.ssn) ? String(object.ssn) : undefined,
      stateAbbr: isSet(object.stateAbbr) ? String(object.stateAbbr) : undefined,
      username: isSet(object.username) ? String(object.username) : undefined,
      writingId: isSet(object.writingId) ? String(object.writingId) : undefined,
      writingIds: isObject(object.writingIds) ? object.writingIds : undefined,
      features: isSet(object.features) ? Features.fromJSON(object.features) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
    };
  },

  toJSON(message: SfWe_Agent): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.affiliateTOH !== undefined && (obj.affiliateTOH = message.affiliateTOH);
    message.agentType !== undefined && (obj.agentType = message.agentType);
    message.blazeAffId !== undefined && (obj.blazeAffId = message.blazeAffId);
    message.blazeConnectAffId !== undefined && (obj.blazeConnectAffId = message.blazeConnectAffId);
    message.broker !== undefined &&
      (obj.broker = message.broker ? SfWe_Agent_Broker.toJSON(message.broker) : undefined);
    message.carrierAgencies !== undefined && (obj.carrierAgencies = message.carrierAgencies);
    message.email !== undefined && (obj.email = message.email);
    message.emberAffId !== undefined && (obj.emberAffId = message.emberAffId);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.isAppointed !== undefined && (obj.isAppointed = message.isAppointed);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.npn !== undefined && (obj.npn = message.npn);
    message.partnerId !== undefined && (obj.partnerId = message.partnerId);
    message.ssn !== undefined && (obj.ssn = message.ssn);
    message.stateAbbr !== undefined && (obj.stateAbbr = message.stateAbbr);
    message.username !== undefined && (obj.username = message.username);
    message.writingId !== undefined && (obj.writingId = message.writingId);
    message.writingIds !== undefined && (obj.writingIds = message.writingIds);
    message.features !== undefined && (obj.features = message.features ? Features.toJSON(message.features) : undefined);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.phone !== undefined && (obj.phone = message.phone);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Agent>, I>>(base?: I): SfWe_Agent {
    return SfWe_Agent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Agent>, I>>(object: I): SfWe_Agent {
    const message = createBaseSfWe_Agent();
    message.id = object.id ?? "";
    message.affiliateTOH = object.affiliateTOH ?? undefined;
    message.agentType = object.agentType ?? undefined;
    message.blazeAffId = object.blazeAffId ?? undefined;
    message.blazeConnectAffId = object.blazeConnectAffId ?? undefined;
    message.broker = (object.broker !== undefined && object.broker !== null)
      ? SfWe_Agent_Broker.fromPartial(object.broker)
      : undefined;
    message.carrierAgencies = object.carrierAgencies ?? undefined;
    message.email = object.email ?? undefined;
    message.emberAffId = object.emberAffId ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.isAppointed = object.isAppointed ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.npn = object.npn ?? undefined;
    message.partnerId = object.partnerId ?? undefined;
    message.ssn = object.ssn ?? undefined;
    message.stateAbbr = object.stateAbbr ?? undefined;
    message.username = object.username ?? undefined;
    message.writingId = object.writingId ?? undefined;
    message.writingIds = object.writingIds ?? undefined;
    message.features = (object.features !== undefined && object.features !== null)
      ? Features.fromPartial(object.features)
      : undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.phone = object.phone ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    return message;
  },
};

function createBaseSfWe_Agent_Broker(): SfWe_Agent_Broker {
  return { npn: undefined, tin: undefined, address: undefined, profileImage: undefined };
}

export const SfWe_Agent_Broker = {
  encode(message: SfWe_Agent_Broker, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.npn !== undefined) {
      writer.uint32(10).string(message.npn);
    }
    if (message.tin !== undefined) {
      writer.uint32(18).string(message.tin);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(26).fork()).ldelim();
    }
    if (message.profileImage !== undefined) {
      writer.uint32(34).string(message.profileImage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SfWe_Agent_Broker {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSfWe_Agent_Broker();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tin = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.profileImage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SfWe_Agent_Broker {
    return {
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      tin: isSet(object.tin) ? String(object.tin) : undefined,
      address: isSet(object.address) ? Address.fromJSON(object.address) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
    };
  },

  toJSON(message: SfWe_Agent_Broker): unknown {
    const obj: any = {};
    message.npn !== undefined && (obj.npn = message.npn);
    message.tin !== undefined && (obj.tin = message.tin);
    message.address !== undefined && (obj.address = message.address ? Address.toJSON(message.address) : undefined);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    return obj;
  },

  create<I extends Exact<DeepPartial<SfWe_Agent_Broker>, I>>(base?: I): SfWe_Agent_Broker {
    return SfWe_Agent_Broker.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SfWe_Agent_Broker>, I>>(object: I): SfWe_Agent_Broker {
    const message = createBaseSfWe_Agent_Broker();
    message.npn = object.npn ?? undefined;
    message.tin = object.tin ?? undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    message.profileImage = object.profileImage ?? undefined;
    return message;
  },
};

function createBaseReport(): Report {
  return {};
}

export const Report = {
  encode(_: Report, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Report {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Report {
    return {};
  },

  toJSON(_: Report): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Report>, I>>(base?: I): Report {
    return Report.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Report>, I>>(_: I): Report {
    const message = createBaseReport();
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
