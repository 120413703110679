import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Carrier } from 'src/app/shared/models';

export const CarrierActions = createActionGroup({
  source: 'Carrier',
  events: {
    'Load Carriers': emptyProps(),
    'Load Carriers Success': props<{ carriers: Carrier[] }>(),
    'Load Carriers Failure': props<{ error: any }>(),
    'Load Carrier': props<{ carrierId: string }>(),
    'Load Carrier Success': props<{ carrier: Carrier }>(),
    'Load Carrier Failure': props<{ error: any }>(),
    'Add Carrier': props<{ carrier: Carrier }>(),
    'Add Carrier Success': props<{ carrier: Carrier }>(),
    'Add Carrier Failure': props<{ error: any }>(),
    'Remove Carrier': props<{ carrierId: string }>(),
    'Remove Carrier Success': props<{ carrierId: string }>(),
    'Remove Carrier Failure': props<{ error: any }>(),
    'Update Carrier': props<{
      carrierId: string;
      carrier: Partial<Carrier>;
    }>(),
    'Update Carrier Success': props<{
      carrierId: string;
      carrier: Partial<Carrier>;
    }>(),
    'Update Carrier Failure': props<{ error: any }>(),
  },
});
