import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LeadSeller } from 'src/app/shared/models';

export const LeadSellerActions = createActionGroup({
  source: 'LeadSeller',
  events: {
    'Load LeadSellers': emptyProps(),
    'Load LeadSellers Success': props<{ leadsellers: LeadSeller[] }>(),
    'Load LeadSellers Failure': props<{ error: any }>(),
    'Load LeadSeller': props<{ leadsellerId: string }>(),
    'Load LeadSeller Success': props<{ leadseller: LeadSeller }>(),
    'Load LeadSeller Failure': props<{ error: any }>(),
    'Add LeadSeller': props<{ leadseller: LeadSeller }>(),
    'Add LeadSeller Success': props<{ leadseller: LeadSeller }>(),
    'Add LeadSeller Failure': props<{ error: any }>(),
    'Remove LeadSeller': props<{ leadsellerId: string }>(),
    'Remove LeadSeller Success': props<{ leadsellerId: string }>(),
    'Remove LeadSeller Failure': props<{ error: any }>(),
    'Update LeadSeller': props<{
      leadsellerId: string;
      leadseller: Partial<LeadSeller>;
    }>(),
    'Update LeadSeller Success': props<{
      leadsellerId: string;
      leadseller: Partial<LeadSeller>;
    }>(),
    'Update LeadSeller Failure': props<{ error: any }>(),
  },
});
