/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { ExecutiveUserContainer } from "./executive";
import { Struct } from "./google/protobuf/struct";
import { DialerSettings, Features, PermanentTagsContainer, UserDisabled } from "./misc";
import {
  DialerRole,
  dialerRoleFromJSON,
  dialerRoleToJSON,
  UserRole,
  userRoleFromJSON,
  userRoleToJSON,
  UserType,
  userTypeFromJSON,
  userTypeToJSON,
} from "./user-enum";

export const protobufPackage = "";

export interface BaseUser {
  id?: string | undefined;
  userType?: UserType | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  npn?: string | undefined;
  profileImage?: string | undefined;
  name?: string | undefined;
  userRole?: UserRole | undefined;
  dialerRole?: DialerRole | undefined;
  canImpersonate?: boolean | undefined;
  skills?: { [key: string]: any } | undefined;
  currentAgency?: BaseUser_DialerAgency | undefined;
  phone?: string | undefined;
  executives?: ExecutiveUserContainer | undefined;
  features?: Features | undefined;
  disabled?: UserDisabled | undefined;
  permanentTags?: PermanentTagsContainer | undefined;
  loaParentAgency?: string | undefined;
  dialerSettings?: DialerSettings | undefined;
}

export interface BaseUser_DialerAgency {
  id?: string | undefined;
  name?: string | undefined;
}

export interface User {
  id?: string | undefined;
  type?: UserType | undefined;
  phone?: string | undefined;
  profileImage?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  npn?: string | undefined;
  userRole?: UserRole | undefined;
  canImpersonate?: boolean | undefined;
}

export interface User_Permission {
  active: boolean;
  permissions: User_Permission_Permissions | undefined;
  roles: User_Permission_Roles | undefined;
  user: BaseUser | undefined;
  invitedAt?: number | undefined;
  grantChildAccess?: boolean | undefined;
  grantedUserId?: string | undefined;
  grantedUserType?: UserType | undefined;
  hasAccessFromParent?: boolean | undefined;
  userClaimedPermission?: boolean | undefined;
}

export interface User_Permission_Roles {
  agent: boolean;
  admin: boolean;
}

export interface User_Permission_Permissions {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface User_PermissionContainer {
  permissions: { [key: string]: User_Permission };
}

export interface User_PermissionContainer_PermissionsEntry {
  key: string;
  value: User_Permission | undefined;
}

function createBaseBaseUser(): BaseUser {
  return {
    id: undefined,
    userType: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    npn: undefined,
    profileImage: undefined,
    name: undefined,
    userRole: undefined,
    dialerRole: undefined,
    canImpersonate: undefined,
    skills: undefined,
    currentAgency: undefined,
    phone: undefined,
    executives: undefined,
    features: undefined,
    disabled: undefined,
    permanentTags: undefined,
    loaParentAgency: undefined,
    dialerSettings: undefined,
  };
}

export const BaseUser = {
  encode(message: BaseUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.userType !== undefined) {
      writer.uint32(16).int32(message.userType);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.email !== undefined) {
      writer.uint32(42).string(message.email);
    }
    if (message.npn !== undefined) {
      writer.uint32(50).string(message.npn);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(58).string(message.profileImage);
    }
    if (message.name !== undefined) {
      writer.uint32(66).string(message.name);
    }
    if (message.userRole !== undefined) {
      writer.uint32(72).int32(message.userRole);
    }
    if (message.dialerRole !== undefined) {
      writer.uint32(80).int32(message.dialerRole);
    }
    if (message.canImpersonate !== undefined) {
      writer.uint32(88).bool(message.canImpersonate);
    }
    if (message.skills !== undefined) {
      Struct.encode(Struct.wrap(message.skills), writer.uint32(98).fork()).ldelim();
    }
    if (message.currentAgency !== undefined) {
      BaseUser_DialerAgency.encode(message.currentAgency, writer.uint32(106).fork()).ldelim();
    }
    if (message.phone !== undefined) {
      writer.uint32(114).string(message.phone);
    }
    if (message.executives !== undefined) {
      ExecutiveUserContainer.encode(message.executives, writer.uint32(122).fork()).ldelim();
    }
    if (message.features !== undefined) {
      Features.encode(message.features, writer.uint32(130).fork()).ldelim();
    }
    if (message.disabled !== undefined) {
      UserDisabled.encode(message.disabled, writer.uint32(138).fork()).ldelim();
    }
    if (message.permanentTags !== undefined) {
      PermanentTagsContainer.encode(message.permanentTags, writer.uint32(146).fork()).ldelim();
    }
    if (message.loaParentAgency !== undefined) {
      writer.uint32(154).string(message.loaParentAgency);
    }
    if (message.dialerSettings !== undefined) {
      DialerSettings.encode(message.dialerSettings, writer.uint32(162).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BaseUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.name = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.userRole = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.dialerRole = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.canImpersonate = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.skills = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.currentAgency = BaseUser_DialerAgency.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.executives = ExecutiveUserContainer.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.features = Features.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.disabled = UserDisabled.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.permanentTags = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.loaParentAgency = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.dialerSettings = DialerSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BaseUser {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      userType: isSet(object.userType) ? userTypeFromJSON(object.userType) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      userRole: isSet(object.userRole) ? userRoleFromJSON(object.userRole) : undefined,
      dialerRole: isSet(object.dialerRole) ? dialerRoleFromJSON(object.dialerRole) : undefined,
      canImpersonate: isSet(object.canImpersonate) ? Boolean(object.canImpersonate) : undefined,
      skills: isObject(object.skills) ? object.skills : undefined,
      currentAgency: isSet(object.currentAgency) ? BaseUser_DialerAgency.fromJSON(object.currentAgency) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      executives: isSet(object.executives) ? ExecutiveUserContainer.fromJSON(object.executives) : undefined,
      features: isSet(object.features) ? Features.fromJSON(object.features) : undefined,
      disabled: isSet(object.disabled) ? UserDisabled.fromJSON(object.disabled) : undefined,
      permanentTags: isSet(object.permanentTags) ? PermanentTagsContainer.fromJSON(object.permanentTags) : undefined,
      loaParentAgency: isSet(object.loaParentAgency) ? String(object.loaParentAgency) : undefined,
      dialerSettings: isSet(object.dialerSettings) ? DialerSettings.fromJSON(object.dialerSettings) : undefined,
    };
  },

  toJSON(message: BaseUser): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userType !== undefined &&
      (obj.userType = message.userType !== undefined ? userTypeToJSON(message.userType) : undefined);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.email !== undefined && (obj.email = message.email);
    message.npn !== undefined && (obj.npn = message.npn);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.name !== undefined && (obj.name = message.name);
    message.userRole !== undefined &&
      (obj.userRole = message.userRole !== undefined ? userRoleToJSON(message.userRole) : undefined);
    message.dialerRole !== undefined &&
      (obj.dialerRole = message.dialerRole !== undefined ? dialerRoleToJSON(message.dialerRole) : undefined);
    message.canImpersonate !== undefined && (obj.canImpersonate = message.canImpersonate);
    message.skills !== undefined && (obj.skills = message.skills);
    message.currentAgency !== undefined &&
      (obj.currentAgency = message.currentAgency ? BaseUser_DialerAgency.toJSON(message.currentAgency) : undefined);
    message.phone !== undefined && (obj.phone = message.phone);
    message.executives !== undefined &&
      (obj.executives = message.executives ? ExecutiveUserContainer.toJSON(message.executives) : undefined);
    message.features !== undefined && (obj.features = message.features ? Features.toJSON(message.features) : undefined);
    message.disabled !== undefined &&
      (obj.disabled = message.disabled ? UserDisabled.toJSON(message.disabled) : undefined);
    message.permanentTags !== undefined &&
      (obj.permanentTags = message.permanentTags ? PermanentTagsContainer.toJSON(message.permanentTags) : undefined);
    message.loaParentAgency !== undefined && (obj.loaParentAgency = message.loaParentAgency);
    message.dialerSettings !== undefined &&
      (obj.dialerSettings = message.dialerSettings ? DialerSettings.toJSON(message.dialerSettings) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<BaseUser>, I>>(base?: I): BaseUser {
    return BaseUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BaseUser>, I>>(object: I): BaseUser {
    const message = createBaseBaseUser();
    message.id = object.id ?? undefined;
    message.userType = object.userType ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.email = object.email ?? undefined;
    message.npn = object.npn ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.name = object.name ?? undefined;
    message.userRole = object.userRole ?? undefined;
    message.dialerRole = object.dialerRole ?? undefined;
    message.canImpersonate = object.canImpersonate ?? undefined;
    message.skills = object.skills ?? undefined;
    message.currentAgency = (object.currentAgency !== undefined && object.currentAgency !== null)
      ? BaseUser_DialerAgency.fromPartial(object.currentAgency)
      : undefined;
    message.phone = object.phone ?? undefined;
    message.executives = (object.executives !== undefined && object.executives !== null)
      ? ExecutiveUserContainer.fromPartial(object.executives)
      : undefined;
    message.features = (object.features !== undefined && object.features !== null)
      ? Features.fromPartial(object.features)
      : undefined;
    message.disabled = (object.disabled !== undefined && object.disabled !== null)
      ? UserDisabled.fromPartial(object.disabled)
      : undefined;
    message.permanentTags = (object.permanentTags !== undefined && object.permanentTags !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTags)
      : undefined;
    message.loaParentAgency = object.loaParentAgency ?? undefined;
    message.dialerSettings = (object.dialerSettings !== undefined && object.dialerSettings !== null)
      ? DialerSettings.fromPartial(object.dialerSettings)
      : undefined;
    return message;
  },
};

function createBaseBaseUser_DialerAgency(): BaseUser_DialerAgency {
  return { id: undefined, name: undefined };
}

export const BaseUser_DialerAgency = {
  encode(message: BaseUser_DialerAgency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BaseUser_DialerAgency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBaseUser_DialerAgency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BaseUser_DialerAgency {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: BaseUser_DialerAgency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<BaseUser_DialerAgency>, I>>(base?: I): BaseUser_DialerAgency {
    return BaseUser_DialerAgency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BaseUser_DialerAgency>, I>>(object: I): BaseUser_DialerAgency {
    const message = createBaseBaseUser_DialerAgency();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseUser(): User {
  return {
    id: undefined,
    type: undefined,
    phone: undefined,
    profileImage: undefined,
    firstName: undefined,
    lastName: undefined,
    npn: undefined,
    userRole: undefined,
    canImpersonate: undefined,
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(message.type);
    }
    if (message.phone !== undefined) {
      writer.uint32(26).string(message.phone);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(34).string(message.profileImage);
    }
    if (message.firstName !== undefined) {
      writer.uint32(42).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(50).string(message.lastName);
    }
    if (message.npn !== undefined) {
      writer.uint32(58).string(message.npn);
    }
    if (message.userRole !== undefined) {
      writer.uint32(64).int32(message.userRole);
    }
    if (message.canImpersonate !== undefined) {
      writer.uint32(72).bool(message.canImpersonate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.userRole = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.canImpersonate = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      type: isSet(object.type) ? userTypeFromJSON(object.type) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      userRole: isSet(object.userRole) ? userRoleFromJSON(object.userRole) : undefined,
      canImpersonate: isSet(object.canImpersonate) ? Boolean(object.canImpersonate) : undefined,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = message.type !== undefined ? userTypeToJSON(message.type) : undefined);
    message.phone !== undefined && (obj.phone = message.phone);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.npn !== undefined && (obj.npn = message.npn);
    message.userRole !== undefined &&
      (obj.userRole = message.userRole !== undefined ? userRoleToJSON(message.userRole) : undefined);
    message.canImpersonate !== undefined && (obj.canImpersonate = message.canImpersonate);
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = object.id ?? undefined;
    message.type = object.type ?? undefined;
    message.phone = object.phone ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.npn = object.npn ?? undefined;
    message.userRole = object.userRole ?? undefined;
    message.canImpersonate = object.canImpersonate ?? undefined;
    return message;
  },
};

function createBaseUser_Permission(): User_Permission {
  return {
    active: false,
    permissions: undefined,
    roles: undefined,
    user: undefined,
    invitedAt: undefined,
    grantChildAccess: undefined,
    grantedUserId: undefined,
    grantedUserType: undefined,
    hasAccessFromParent: undefined,
    userClaimedPermission: undefined,
  };
}

export const User_Permission = {
  encode(message: User_Permission, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.active === true) {
      writer.uint32(8).bool(message.active);
    }
    if (message.permissions !== undefined) {
      User_Permission_Permissions.encode(message.permissions, writer.uint32(18).fork()).ldelim();
    }
    if (message.roles !== undefined) {
      User_Permission_Roles.encode(message.roles, writer.uint32(26).fork()).ldelim();
    }
    if (message.user !== undefined) {
      BaseUser.encode(message.user, writer.uint32(34).fork()).ldelim();
    }
    if (message.invitedAt !== undefined) {
      writer.uint32(40).int64(message.invitedAt);
    }
    if (message.grantChildAccess !== undefined) {
      writer.uint32(48).bool(message.grantChildAccess);
    }
    if (message.grantedUserId !== undefined) {
      writer.uint32(58).string(message.grantedUserId);
    }
    if (message.grantedUserType !== undefined) {
      writer.uint32(64).int32(message.grantedUserType);
    }
    if (message.hasAccessFromParent !== undefined) {
      writer.uint32(72).bool(message.hasAccessFromParent);
    }
    if (message.userClaimedPermission !== undefined) {
      writer.uint32(80).bool(message.userClaimedPermission);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_Permission {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_Permission();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.permissions = User_Permission_Permissions.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.roles = User_Permission_Roles.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.user = BaseUser.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.invitedAt = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.grantChildAccess = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.grantedUserId = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.grantedUserType = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.hasAccessFromParent = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.userClaimedPermission = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_Permission {
    return {
      active: isSet(object.active) ? Boolean(object.active) : false,
      permissions: isSet(object.permissions) ? User_Permission_Permissions.fromJSON(object.permissions) : undefined,
      roles: isSet(object.roles) ? User_Permission_Roles.fromJSON(object.roles) : undefined,
      user: isSet(object.user) ? BaseUser.fromJSON(object.user) : undefined,
      invitedAt: isSet(object.invitedAt) ? Number(object.invitedAt) : undefined,
      grantChildAccess: isSet(object.grantChildAccess) ? Boolean(object.grantChildAccess) : undefined,
      grantedUserId: isSet(object.grantedUserId) ? String(object.grantedUserId) : undefined,
      grantedUserType: isSet(object.grantedUserType) ? userTypeFromJSON(object.grantedUserType) : undefined,
      hasAccessFromParent: isSet(object.hasAccessFromParent) ? Boolean(object.hasAccessFromParent) : undefined,
      userClaimedPermission: isSet(object.userClaimedPermission) ? Boolean(object.userClaimedPermission) : undefined,
    };
  },

  toJSON(message: User_Permission): unknown {
    const obj: any = {};
    message.active !== undefined && (obj.active = message.active);
    message.permissions !== undefined &&
      (obj.permissions = message.permissions ? User_Permission_Permissions.toJSON(message.permissions) : undefined);
    message.roles !== undefined &&
      (obj.roles = message.roles ? User_Permission_Roles.toJSON(message.roles) : undefined);
    message.user !== undefined && (obj.user = message.user ? BaseUser.toJSON(message.user) : undefined);
    message.invitedAt !== undefined && (obj.invitedAt = Math.round(message.invitedAt));
    message.grantChildAccess !== undefined && (obj.grantChildAccess = message.grantChildAccess);
    message.grantedUserId !== undefined && (obj.grantedUserId = message.grantedUserId);
    message.grantedUserType !== undefined &&
      (obj.grantedUserType = message.grantedUserType !== undefined
        ? userTypeToJSON(message.grantedUserType)
        : undefined);
    message.hasAccessFromParent !== undefined && (obj.hasAccessFromParent = message.hasAccessFromParent);
    message.userClaimedPermission !== undefined && (obj.userClaimedPermission = message.userClaimedPermission);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_Permission>, I>>(base?: I): User_Permission {
    return User_Permission.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_Permission>, I>>(object: I): User_Permission {
    const message = createBaseUser_Permission();
    message.active = object.active ?? false;
    message.permissions = (object.permissions !== undefined && object.permissions !== null)
      ? User_Permission_Permissions.fromPartial(object.permissions)
      : undefined;
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? User_Permission_Roles.fromPartial(object.roles)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null) ? BaseUser.fromPartial(object.user) : undefined;
    message.invitedAt = object.invitedAt ?? undefined;
    message.grantChildAccess = object.grantChildAccess ?? undefined;
    message.grantedUserId = object.grantedUserId ?? undefined;
    message.grantedUserType = object.grantedUserType ?? undefined;
    message.hasAccessFromParent = object.hasAccessFromParent ?? undefined;
    message.userClaimedPermission = object.userClaimedPermission ?? undefined;
    return message;
  },
};

function createBaseUser_Permission_Roles(): User_Permission_Roles {
  return { agent: false, admin: false };
}

export const User_Permission_Roles = {
  encode(message: User_Permission_Roles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.agent === true) {
      writer.uint32(8).bool(message.agent);
    }
    if (message.admin === true) {
      writer.uint32(16).bool(message.admin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_Permission_Roles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_Permission_Roles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.agent = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.admin = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_Permission_Roles {
    return {
      agent: isSet(object.agent) ? Boolean(object.agent) : false,
      admin: isSet(object.admin) ? Boolean(object.admin) : false,
    };
  },

  toJSON(message: User_Permission_Roles): unknown {
    const obj: any = {};
    message.agent !== undefined && (obj.agent = message.agent);
    message.admin !== undefined && (obj.admin = message.admin);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_Permission_Roles>, I>>(base?: I): User_Permission_Roles {
    return User_Permission_Roles.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_Permission_Roles>, I>>(object: I): User_Permission_Roles {
    const message = createBaseUser_Permission_Roles();
    message.agent = object.agent ?? false;
    message.admin = object.admin ?? false;
    return message;
  },
};

function createBaseUser_Permission_Permissions(): User_Permission_Permissions {
  return { create: false, read: false, update: false, delete: false };
}

export const User_Permission_Permissions = {
  encode(message: User_Permission_Permissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.create === true) {
      writer.uint32(8).bool(message.create);
    }
    if (message.read === true) {
      writer.uint32(16).bool(message.read);
    }
    if (message.update === true) {
      writer.uint32(24).bool(message.update);
    }
    if (message.delete === true) {
      writer.uint32(32).bool(message.delete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_Permission_Permissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_Permission_Permissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.create = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.read = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.update = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.delete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_Permission_Permissions {
    return {
      create: isSet(object.create) ? Boolean(object.create) : false,
      read: isSet(object.read) ? Boolean(object.read) : false,
      update: isSet(object.update) ? Boolean(object.update) : false,
      delete: isSet(object.delete) ? Boolean(object.delete) : false,
    };
  },

  toJSON(message: User_Permission_Permissions): unknown {
    const obj: any = {};
    message.create !== undefined && (obj.create = message.create);
    message.read !== undefined && (obj.read = message.read);
    message.update !== undefined && (obj.update = message.update);
    message.delete !== undefined && (obj.delete = message.delete);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_Permission_Permissions>, I>>(base?: I): User_Permission_Permissions {
    return User_Permission_Permissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_Permission_Permissions>, I>>(object: I): User_Permission_Permissions {
    const message = createBaseUser_Permission_Permissions();
    message.create = object.create ?? false;
    message.read = object.read ?? false;
    message.update = object.update ?? false;
    message.delete = object.delete ?? false;
    return message;
  },
};

function createBaseUser_PermissionContainer(): User_PermissionContainer {
  return { permissions: {} };
}

export const User_PermissionContainer = {
  encode(message: User_PermissionContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.permissions).forEach(([key, value]) => {
      User_PermissionContainer_PermissionsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_PermissionContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_PermissionContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = User_PermissionContainer_PermissionsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.permissions[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_PermissionContainer {
    return {
      permissions: isObject(object.permissions)
        ? Object.entries(object.permissions).reduce<{ [key: string]: User_Permission }>((acc, [key, value]) => {
          acc[key] = User_Permission.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: User_PermissionContainer): unknown {
    const obj: any = {};
    obj.permissions = {};
    if (message.permissions) {
      Object.entries(message.permissions).forEach(([k, v]) => {
        obj.permissions[k] = User_Permission.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User_PermissionContainer>, I>>(base?: I): User_PermissionContainer {
    return User_PermissionContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_PermissionContainer>, I>>(object: I): User_PermissionContainer {
    const message = createBaseUser_PermissionContainer();
    message.permissions = Object.entries(object.permissions ?? {}).reduce<{ [key: string]: User_Permission }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = User_Permission.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUser_PermissionContainer_PermissionsEntry(): User_PermissionContainer_PermissionsEntry {
  return { key: "", value: undefined };
}

export const User_PermissionContainer_PermissionsEntry = {
  encode(message: User_PermissionContainer_PermissionsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      User_Permission.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_PermissionContainer_PermissionsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_PermissionContainer_PermissionsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = User_Permission.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_PermissionContainer_PermissionsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? User_Permission.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: User_PermissionContainer_PermissionsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? User_Permission.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<User_PermissionContainer_PermissionsEntry>, I>>(
    base?: I,
  ): User_PermissionContainer_PermissionsEntry {
    return User_PermissionContainer_PermissionsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User_PermissionContainer_PermissionsEntry>, I>>(
    object: I,
  ): User_PermissionContainer_PermissionsEntry {
    const message = createBaseUser_PermissionContainer_PermissionsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? User_Permission.fromPartial(object.value)
      : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
