/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface Sunfire {
}

export interface Sunfire_Plan {
  id: string;
  contractId?: string | undefined;
  carrierName?: string | undefined;
  planName?: string | undefined;
  carrierId?: string | undefined;
  planId?: string | undefined;
  enrollmentPlanYear?: string | undefined;
  planYear?: string | undefined;
  effectiveDate?: string | undefined;
  appSubmitDate?: string | undefined;
  submitTime?: string | undefined;
  primaryCarePhysician?: string | undefined;
  pcpName?: string | undefined;
  electionPeriod?: string | undefined;
  sepReasonCode?: string | undefined;
  cmsPlanId?: string | undefined;
  cmsContractId?: string | undefined;
  planCarrierId?: string | undefined;
  confirmationNumber?: string | undefined;
  writingId?: string | undefined;
  heapAgencyAffiliation?: string | undefined;
  cmsSegmentId?: string | undefined;
  genderSpecified?: string | undefined;
  poaAddressCity?: string | undefined;
  poaAddressCountyName?: string | undefined;
  replacingOtherMedicareCoverage?: string | undefined;
  otherGroupCoverageAddressState?: string | undefined;
  ltcAddressLine2?: string | undefined;
  ltcAddressCountyName?: string | undefined;
  otherGroupCoverageAddressCountyName?: string | undefined;
  ltcAddressFips?: string | undefined;
  premiumDeducted?: string | undefined;
  ltcAddressState?: string | undefined;
  canEmailTheEnrollee?: boolean | undefined;
  creditCardExpiration?: string | undefined;
  siteId?: string | undefined;
  premiumWithhold?: string | undefined;
  dateOfBirthMonth?: string | undefined;
  ltcName?: string | undefined;
  pcpIsEstablishedPatient?: boolean | undefined;
  otherGroupCoverageBeingReplaced?: string | undefined;
  ltcAddressCity?: string | undefined;
  otherDrugCoverageMemberName?: string | undefined;
  language?: string | undefined;
  oldSubmitDate?: string | undefined;
  bankAccountNumber?: string | undefined;
  sfId?: string | undefined;
  otherGroupCoverageAddressLine1?: string | undefined;
  otherDrugCoveragePolicyId?: string | undefined;
  otherGroupCoverageEndDateMonth?: string | undefined;
  otherMedicareCoverageStartDateYear?: string | undefined;
  pcpAddress?: string | undefined;
  otherGroupCoverageStartDateYear?: string | undefined;
  medicaidPaidPremium?: string | undefined;
  otherGroupCoverageAddressZip?: string | undefined;
  emergencyContactPhone?: string | undefined;
  otherMedicareCoverageEndDateYear?: string | undefined;
  otherGroupCoverageStartDateMonth?: string | undefined;
  otherCoverage?: string | undefined;
  ltcPhone?: string | undefined;
  hasOtherDrugCoverage?: boolean | undefined;
  otherGroupCoverageEndDateDay?: string | undefined;
  nephrologistName?: string | undefined;
  ethnicity?: string | undefined;
  otherMedicareCoverageEndDateMonth?: string | undefined;
  ltcAdmitDateMonth?: string | undefined;
  applicantEsignature?: string | undefined;
  paymentType?: string | undefined;
  dateOfBirth?: string | undefined;
  currentPlan?: string | undefined;
  planType?: string | undefined;
  hasOtherGroupCoverage?: string | undefined;
  wishToDesignatePcp?: string | undefined;
  otherMedicareCoverageEndDateDay?: string | undefined;
  emergencyContactName?: string | undefined;
  creditCardType?: string | undefined;
  otherGroupCoveragePolicyId?: string | undefined;
  otherGroupCoverageIncludesPrescriptionDrugCoverage?: string | undefined;
  parameters?: string | undefined;
  emergencyContactRelationship?: string | undefined;
  premiumDirectPay?: string | undefined;
  otherGroupCoverageStartDateDay?: string | undefined;
  longTerm?: string | undefined;
  otherDrugCoverageMemberId?: string | undefined;
  otherGroupCoverageEndDate?: string | undefined;
  ltcAdmitDateDay?: string | undefined;
  dsnpVerificationCode?: string | undefined;
  hasOtherMedicareCoverage?: string | undefined;
  otherGroupCoverageAddressFips?: string | undefined;
  enrolledWithCarrierAlready?: string | undefined;
  homeAddressFips?: string | undefined;
  ipAddress?: string | undefined;
  needsContinuityOfCare?: string | undefined;
  dateOfBirthYear?: string | undefined;
  ltcAddressZip?: string | undefined;
  currentMemberId?: string | undefined;
  otherGroupCoverageAddressCity?: string | undefined;
  creditCardNumber?: string | undefined;
  ltcAddressLine1?: string | undefined;
  bankAccountType?: string | undefined;
  otherMedicareCoverageStillCovered?: string | undefined;
  isCurrentMember?: string | undefined;
  bankAccountHolderName?: string | undefined;
  alternatePhoneNumber?: string | undefined;
  dateOfBirthDay?: string | undefined;
  otherGroupCoverageAddressLine2?: string | undefined;
  otherMedicareCoverageStartDateMonth?: string | undefined;
  otherMedicareCoverageDroppingMedSupp?: string | undefined;
  otherGroupCoverageCarrierName?: string | undefined;
  sunFirePlanId?: string | undefined;
  medicaidAddBenefits?: string | undefined;
  otherMedicareCoveragePolicyId?: string | undefined;
  otherMedicareCoverageFirstTime?: string | undefined;
  otherMedicareCoverageName?: string | undefined;
  otherMedicareCoverageStartDateDay?: string | undefined;
  workStatus?: boolean | undefined;
  creditCardHolder?: string | undefined;
  paymentType2?: string | undefined;
  pcpId?: string | undefined;
  ltcAdmitDateYear?: string | undefined;
  marketingId?: string | undefined;
  county?: string | undefined;
  hasCopd?: boolean | undefined;
  copdHasEmphysema?: boolean | undefined;
  copdTakesDrugsForBreathing?: boolean | undefined;
  copdHasAsthma?: boolean | undefined;
  copdHasDifficultyBreathing?: boolean | undefined;
  copdNeedsOxygenSupport?: boolean | undefined;
  hasChf?: boolean | undefined;
  chfHasBodyFluid?: boolean | undefined;
  chfTakesDrugsForLungFluid?: string | undefined;
  chfHasLungFluid?: boolean | undefined;
  hasDiabetes?: boolean | undefined;
  diabetesChecksBloodSugar?: string | undefined;
  diabetesTakesDrugsForBloodSugar?: string | undefined;
  diabetesHasHighBloodSugar?: string | undefined;
  hasEsrd?: boolean | undefined;
  esrd?: string | undefined;
  hadSuccessfulTransplant?: boolean | undefined;
  requiresRegularDialysis?: boolean | undefined;
  dialysisCenterName?: string | undefined;
  dialysisCid?: string | undefined;
  hasCvd?: boolean | undefined;
  cvdHasChestOrLegPain?: boolean | undefined;
  cvdTakesDrugsForHeart?: boolean | undefined;
  cvdHadHeartAttack?: boolean | undefined;
  hasCardiovascularDisorder?: boolean | undefined;
  isInLongTermCareFacility?: boolean | undefined;
  medicarePartADate?: string | undefined;
  medicarePartBDate?: string | undefined;
  oldPartA?: string | undefined;
  oldPartB?: string | undefined;
  isOnMedicaid?: boolean | undefined;
  stateMedicaid?: string | undefined;
  medicaidNumber?: string | undefined;
  lastNote?: string | undefined;
  latitude?: number | undefined;
  longitude?: number | undefined;
  agentId?: string | undefined;
  agent?: Sunfire_Agent | undefined;
  consumer?: Sunfire_Consumer | undefined;
  contactId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phone?: string | undefined;
  email?: string | undefined;
  gender?: string | undefined;
  hicn?: string | undefined;
  medicareNumber?: string | undefined;
  additionalEmails?: string | undefined;
  additionalPhones?: string | undefined;
  address?: string | undefined;
  street?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  zip?: string | undefined;
  birthDate?: string | undefined;
}

export interface Sunfire_Consumer {
  id: string;
  contactId?: string | undefined;
  firstName?: string | undefined;
  middleInitial?: string | undefined;
  lastName?: string | undefined;
  phone?: string | undefined;
  email?: string | undefined;
  gender?: string | undefined;
  hicn?: string | undefined;
  medicareNumber?: string | undefined;
  additionalEmails: string[];
  additionalPhones: string[];
  address?: string | undefined;
  street?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  zip?: string | undefined;
  birthDate?: string | undefined;
}

export interface Sunfire_Agent {
  id?: string | undefined;
  agentId?: string | undefined;
  npn?: string | undefined;
  name?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  username?: string | undefined;
}

function createBaseSunfire(): Sunfire {
  return {};
}

export const Sunfire = {
  encode(_: Sunfire, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Sunfire {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSunfire();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Sunfire {
    return {};
  },

  toJSON(_: Sunfire): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Sunfire>, I>>(base?: I): Sunfire {
    return Sunfire.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Sunfire>, I>>(_: I): Sunfire {
    const message = createBaseSunfire();
    return message;
  },
};

function createBaseSunfire_Plan(): Sunfire_Plan {
  return {
    id: "",
    contractId: undefined,
    carrierName: undefined,
    planName: undefined,
    carrierId: undefined,
    planId: undefined,
    enrollmentPlanYear: undefined,
    planYear: undefined,
    effectiveDate: undefined,
    appSubmitDate: undefined,
    submitTime: undefined,
    primaryCarePhysician: undefined,
    pcpName: undefined,
    electionPeriod: undefined,
    sepReasonCode: undefined,
    cmsPlanId: undefined,
    cmsContractId: undefined,
    planCarrierId: undefined,
    confirmationNumber: undefined,
    writingId: undefined,
    heapAgencyAffiliation: undefined,
    cmsSegmentId: undefined,
    genderSpecified: undefined,
    poaAddressCity: undefined,
    poaAddressCountyName: undefined,
    replacingOtherMedicareCoverage: undefined,
    otherGroupCoverageAddressState: undefined,
    ltcAddressLine2: undefined,
    ltcAddressCountyName: undefined,
    otherGroupCoverageAddressCountyName: undefined,
    ltcAddressFips: undefined,
    premiumDeducted: undefined,
    ltcAddressState: undefined,
    canEmailTheEnrollee: undefined,
    creditCardExpiration: undefined,
    siteId: undefined,
    premiumWithhold: undefined,
    dateOfBirthMonth: undefined,
    ltcName: undefined,
    pcpIsEstablishedPatient: undefined,
    otherGroupCoverageBeingReplaced: undefined,
    ltcAddressCity: undefined,
    otherDrugCoverageMemberName: undefined,
    language: undefined,
    oldSubmitDate: undefined,
    bankAccountNumber: undefined,
    sfId: undefined,
    otherGroupCoverageAddressLine1: undefined,
    otherDrugCoveragePolicyId: undefined,
    otherGroupCoverageEndDateMonth: undefined,
    otherMedicareCoverageStartDateYear: undefined,
    pcpAddress: undefined,
    otherGroupCoverageStartDateYear: undefined,
    medicaidPaidPremium: undefined,
    otherGroupCoverageAddressZip: undefined,
    emergencyContactPhone: undefined,
    otherMedicareCoverageEndDateYear: undefined,
    otherGroupCoverageStartDateMonth: undefined,
    otherCoverage: undefined,
    ltcPhone: undefined,
    hasOtherDrugCoverage: undefined,
    otherGroupCoverageEndDateDay: undefined,
    nephrologistName: undefined,
    ethnicity: undefined,
    otherMedicareCoverageEndDateMonth: undefined,
    ltcAdmitDateMonth: undefined,
    applicantEsignature: undefined,
    paymentType: undefined,
    dateOfBirth: undefined,
    currentPlan: undefined,
    planType: undefined,
    hasOtherGroupCoverage: undefined,
    wishToDesignatePcp: undefined,
    otherMedicareCoverageEndDateDay: undefined,
    emergencyContactName: undefined,
    creditCardType: undefined,
    otherGroupCoveragePolicyId: undefined,
    otherGroupCoverageIncludesPrescriptionDrugCoverage: undefined,
    parameters: undefined,
    emergencyContactRelationship: undefined,
    premiumDirectPay: undefined,
    otherGroupCoverageStartDateDay: undefined,
    longTerm: undefined,
    otherDrugCoverageMemberId: undefined,
    otherGroupCoverageEndDate: undefined,
    ltcAdmitDateDay: undefined,
    dsnpVerificationCode: undefined,
    hasOtherMedicareCoverage: undefined,
    otherGroupCoverageAddressFips: undefined,
    enrolledWithCarrierAlready: undefined,
    homeAddressFips: undefined,
    ipAddress: undefined,
    needsContinuityOfCare: undefined,
    dateOfBirthYear: undefined,
    ltcAddressZip: undefined,
    currentMemberId: undefined,
    otherGroupCoverageAddressCity: undefined,
    creditCardNumber: undefined,
    ltcAddressLine1: undefined,
    bankAccountType: undefined,
    otherMedicareCoverageStillCovered: undefined,
    isCurrentMember: undefined,
    bankAccountHolderName: undefined,
    alternatePhoneNumber: undefined,
    dateOfBirthDay: undefined,
    otherGroupCoverageAddressLine2: undefined,
    otherMedicareCoverageStartDateMonth: undefined,
    otherMedicareCoverageDroppingMedSupp: undefined,
    otherGroupCoverageCarrierName: undefined,
    sunFirePlanId: undefined,
    medicaidAddBenefits: undefined,
    otherMedicareCoveragePolicyId: undefined,
    otherMedicareCoverageFirstTime: undefined,
    otherMedicareCoverageName: undefined,
    otherMedicareCoverageStartDateDay: undefined,
    workStatus: undefined,
    creditCardHolder: undefined,
    paymentType2: undefined,
    pcpId: undefined,
    ltcAdmitDateYear: undefined,
    marketingId: undefined,
    county: undefined,
    hasCopd: undefined,
    copdHasEmphysema: undefined,
    copdTakesDrugsForBreathing: undefined,
    copdHasAsthma: undefined,
    copdHasDifficultyBreathing: undefined,
    copdNeedsOxygenSupport: undefined,
    hasChf: undefined,
    chfHasBodyFluid: undefined,
    chfTakesDrugsForLungFluid: undefined,
    chfHasLungFluid: undefined,
    hasDiabetes: undefined,
    diabetesChecksBloodSugar: undefined,
    diabetesTakesDrugsForBloodSugar: undefined,
    diabetesHasHighBloodSugar: undefined,
    hasEsrd: undefined,
    esrd: undefined,
    hadSuccessfulTransplant: undefined,
    requiresRegularDialysis: undefined,
    dialysisCenterName: undefined,
    dialysisCid: undefined,
    hasCvd: undefined,
    cvdHasChestOrLegPain: undefined,
    cvdTakesDrugsForHeart: undefined,
    cvdHadHeartAttack: undefined,
    hasCardiovascularDisorder: undefined,
    isInLongTermCareFacility: undefined,
    medicarePartADate: undefined,
    medicarePartBDate: undefined,
    oldPartA: undefined,
    oldPartB: undefined,
    isOnMedicaid: undefined,
    stateMedicaid: undefined,
    medicaidNumber: undefined,
    lastNote: undefined,
    latitude: undefined,
    longitude: undefined,
    agentId: undefined,
    agent: undefined,
    consumer: undefined,
    contactId: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    email: undefined,
    gender: undefined,
    hicn: undefined,
    medicareNumber: undefined,
    additionalEmails: undefined,
    additionalPhones: undefined,
    address: undefined,
    street: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    birthDate: undefined,
  };
}

export const Sunfire_Plan = {
  encode(message: Sunfire_Plan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.contractId !== undefined) {
      writer.uint32(18).string(message.contractId);
    }
    if (message.carrierName !== undefined) {
      writer.uint32(26).string(message.carrierName);
    }
    if (message.planName !== undefined) {
      writer.uint32(34).string(message.planName);
    }
    if (message.carrierId !== undefined) {
      writer.uint32(42).string(message.carrierId);
    }
    if (message.planId !== undefined) {
      writer.uint32(50).string(message.planId);
    }
    if (message.enrollmentPlanYear !== undefined) {
      writer.uint32(58).string(message.enrollmentPlanYear);
    }
    if (message.planYear !== undefined) {
      writer.uint32(66).string(message.planYear);
    }
    if (message.effectiveDate !== undefined) {
      writer.uint32(74).string(message.effectiveDate);
    }
    if (message.appSubmitDate !== undefined) {
      writer.uint32(82).string(message.appSubmitDate);
    }
    if (message.submitTime !== undefined) {
      writer.uint32(90).string(message.submitTime);
    }
    if (message.primaryCarePhysician !== undefined) {
      writer.uint32(98).string(message.primaryCarePhysician);
    }
    if (message.pcpName !== undefined) {
      writer.uint32(106).string(message.pcpName);
    }
    if (message.electionPeriod !== undefined) {
      writer.uint32(114).string(message.electionPeriod);
    }
    if (message.sepReasonCode !== undefined) {
      writer.uint32(122).string(message.sepReasonCode);
    }
    if (message.cmsPlanId !== undefined) {
      writer.uint32(130).string(message.cmsPlanId);
    }
    if (message.cmsContractId !== undefined) {
      writer.uint32(138).string(message.cmsContractId);
    }
    if (message.planCarrierId !== undefined) {
      writer.uint32(146).string(message.planCarrierId);
    }
    if (message.confirmationNumber !== undefined) {
      writer.uint32(154).string(message.confirmationNumber);
    }
    if (message.writingId !== undefined) {
      writer.uint32(162).string(message.writingId);
    }
    if (message.heapAgencyAffiliation !== undefined) {
      writer.uint32(170).string(message.heapAgencyAffiliation);
    }
    if (message.cmsSegmentId !== undefined) {
      writer.uint32(178).string(message.cmsSegmentId);
    }
    if (message.genderSpecified !== undefined) {
      writer.uint32(186).string(message.genderSpecified);
    }
    if (message.poaAddressCity !== undefined) {
      writer.uint32(194).string(message.poaAddressCity);
    }
    if (message.poaAddressCountyName !== undefined) {
      writer.uint32(202).string(message.poaAddressCountyName);
    }
    if (message.replacingOtherMedicareCoverage !== undefined) {
      writer.uint32(210).string(message.replacingOtherMedicareCoverage);
    }
    if (message.otherGroupCoverageAddressState !== undefined) {
      writer.uint32(218).string(message.otherGroupCoverageAddressState);
    }
    if (message.ltcAddressLine2 !== undefined) {
      writer.uint32(226).string(message.ltcAddressLine2);
    }
    if (message.ltcAddressCountyName !== undefined) {
      writer.uint32(234).string(message.ltcAddressCountyName);
    }
    if (message.otherGroupCoverageAddressCountyName !== undefined) {
      writer.uint32(242).string(message.otherGroupCoverageAddressCountyName);
    }
    if (message.ltcAddressFips !== undefined) {
      writer.uint32(250).string(message.ltcAddressFips);
    }
    if (message.premiumDeducted !== undefined) {
      writer.uint32(258).string(message.premiumDeducted);
    }
    if (message.ltcAddressState !== undefined) {
      writer.uint32(266).string(message.ltcAddressState);
    }
    if (message.canEmailTheEnrollee !== undefined) {
      writer.uint32(272).bool(message.canEmailTheEnrollee);
    }
    if (message.creditCardExpiration !== undefined) {
      writer.uint32(282).string(message.creditCardExpiration);
    }
    if (message.siteId !== undefined) {
      writer.uint32(290).string(message.siteId);
    }
    if (message.premiumWithhold !== undefined) {
      writer.uint32(298).string(message.premiumWithhold);
    }
    if (message.dateOfBirthMonth !== undefined) {
      writer.uint32(306).string(message.dateOfBirthMonth);
    }
    if (message.ltcName !== undefined) {
      writer.uint32(314).string(message.ltcName);
    }
    if (message.pcpIsEstablishedPatient !== undefined) {
      writer.uint32(320).bool(message.pcpIsEstablishedPatient);
    }
    if (message.otherGroupCoverageBeingReplaced !== undefined) {
      writer.uint32(330).string(message.otherGroupCoverageBeingReplaced);
    }
    if (message.ltcAddressCity !== undefined) {
      writer.uint32(338).string(message.ltcAddressCity);
    }
    if (message.otherDrugCoverageMemberName !== undefined) {
      writer.uint32(346).string(message.otherDrugCoverageMemberName);
    }
    if (message.language !== undefined) {
      writer.uint32(354).string(message.language);
    }
    if (message.oldSubmitDate !== undefined) {
      writer.uint32(362).string(message.oldSubmitDate);
    }
    if (message.bankAccountNumber !== undefined) {
      writer.uint32(370).string(message.bankAccountNumber);
    }
    if (message.sfId !== undefined) {
      writer.uint32(378).string(message.sfId);
    }
    if (message.otherGroupCoverageAddressLine1 !== undefined) {
      writer.uint32(386).string(message.otherGroupCoverageAddressLine1);
    }
    if (message.otherDrugCoveragePolicyId !== undefined) {
      writer.uint32(394).string(message.otherDrugCoveragePolicyId);
    }
    if (message.otherGroupCoverageEndDateMonth !== undefined) {
      writer.uint32(402).string(message.otherGroupCoverageEndDateMonth);
    }
    if (message.otherMedicareCoverageStartDateYear !== undefined) {
      writer.uint32(410).string(message.otherMedicareCoverageStartDateYear);
    }
    if (message.pcpAddress !== undefined) {
      writer.uint32(418).string(message.pcpAddress);
    }
    if (message.otherGroupCoverageStartDateYear !== undefined) {
      writer.uint32(426).string(message.otherGroupCoverageStartDateYear);
    }
    if (message.medicaidPaidPremium !== undefined) {
      writer.uint32(434).string(message.medicaidPaidPremium);
    }
    if (message.otherGroupCoverageAddressZip !== undefined) {
      writer.uint32(442).string(message.otherGroupCoverageAddressZip);
    }
    if (message.emergencyContactPhone !== undefined) {
      writer.uint32(450).string(message.emergencyContactPhone);
    }
    if (message.otherMedicareCoverageEndDateYear !== undefined) {
      writer.uint32(458).string(message.otherMedicareCoverageEndDateYear);
    }
    if (message.otherGroupCoverageStartDateMonth !== undefined) {
      writer.uint32(466).string(message.otherGroupCoverageStartDateMonth);
    }
    if (message.otherCoverage !== undefined) {
      writer.uint32(474).string(message.otherCoverage);
    }
    if (message.ltcPhone !== undefined) {
      writer.uint32(482).string(message.ltcPhone);
    }
    if (message.hasOtherDrugCoverage !== undefined) {
      writer.uint32(488).bool(message.hasOtherDrugCoverage);
    }
    if (message.otherGroupCoverageEndDateDay !== undefined) {
      writer.uint32(498).string(message.otherGroupCoverageEndDateDay);
    }
    if (message.nephrologistName !== undefined) {
      writer.uint32(506).string(message.nephrologistName);
    }
    if (message.ethnicity !== undefined) {
      writer.uint32(514).string(message.ethnicity);
    }
    if (message.otherMedicareCoverageEndDateMonth !== undefined) {
      writer.uint32(522).string(message.otherMedicareCoverageEndDateMonth);
    }
    if (message.ltcAdmitDateMonth !== undefined) {
      writer.uint32(530).string(message.ltcAdmitDateMonth);
    }
    if (message.applicantEsignature !== undefined) {
      writer.uint32(538).string(message.applicantEsignature);
    }
    if (message.paymentType !== undefined) {
      writer.uint32(546).string(message.paymentType);
    }
    if (message.dateOfBirth !== undefined) {
      writer.uint32(554).string(message.dateOfBirth);
    }
    if (message.currentPlan !== undefined) {
      writer.uint32(562).string(message.currentPlan);
    }
    if (message.planType !== undefined) {
      writer.uint32(570).string(message.planType);
    }
    if (message.hasOtherGroupCoverage !== undefined) {
      writer.uint32(578).string(message.hasOtherGroupCoverage);
    }
    if (message.wishToDesignatePcp !== undefined) {
      writer.uint32(586).string(message.wishToDesignatePcp);
    }
    if (message.otherMedicareCoverageEndDateDay !== undefined) {
      writer.uint32(594).string(message.otherMedicareCoverageEndDateDay);
    }
    if (message.emergencyContactName !== undefined) {
      writer.uint32(602).string(message.emergencyContactName);
    }
    if (message.creditCardType !== undefined) {
      writer.uint32(610).string(message.creditCardType);
    }
    if (message.otherGroupCoveragePolicyId !== undefined) {
      writer.uint32(618).string(message.otherGroupCoveragePolicyId);
    }
    if (message.otherGroupCoverageIncludesPrescriptionDrugCoverage !== undefined) {
      writer.uint32(626).string(message.otherGroupCoverageIncludesPrescriptionDrugCoverage);
    }
    if (message.parameters !== undefined) {
      writer.uint32(634).string(message.parameters);
    }
    if (message.emergencyContactRelationship !== undefined) {
      writer.uint32(642).string(message.emergencyContactRelationship);
    }
    if (message.premiumDirectPay !== undefined) {
      writer.uint32(650).string(message.premiumDirectPay);
    }
    if (message.otherGroupCoverageStartDateDay !== undefined) {
      writer.uint32(658).string(message.otherGroupCoverageStartDateDay);
    }
    if (message.longTerm !== undefined) {
      writer.uint32(666).string(message.longTerm);
    }
    if (message.otherDrugCoverageMemberId !== undefined) {
      writer.uint32(674).string(message.otherDrugCoverageMemberId);
    }
    if (message.otherGroupCoverageEndDate !== undefined) {
      writer.uint32(682).string(message.otherGroupCoverageEndDate);
    }
    if (message.ltcAdmitDateDay !== undefined) {
      writer.uint32(690).string(message.ltcAdmitDateDay);
    }
    if (message.dsnpVerificationCode !== undefined) {
      writer.uint32(698).string(message.dsnpVerificationCode);
    }
    if (message.hasOtherMedicareCoverage !== undefined) {
      writer.uint32(706).string(message.hasOtherMedicareCoverage);
    }
    if (message.otherGroupCoverageAddressFips !== undefined) {
      writer.uint32(714).string(message.otherGroupCoverageAddressFips);
    }
    if (message.enrolledWithCarrierAlready !== undefined) {
      writer.uint32(722).string(message.enrolledWithCarrierAlready);
    }
    if (message.homeAddressFips !== undefined) {
      writer.uint32(730).string(message.homeAddressFips);
    }
    if (message.ipAddress !== undefined) {
      writer.uint32(738).string(message.ipAddress);
    }
    if (message.needsContinuityOfCare !== undefined) {
      writer.uint32(746).string(message.needsContinuityOfCare);
    }
    if (message.dateOfBirthYear !== undefined) {
      writer.uint32(754).string(message.dateOfBirthYear);
    }
    if (message.ltcAddressZip !== undefined) {
      writer.uint32(762).string(message.ltcAddressZip);
    }
    if (message.currentMemberId !== undefined) {
      writer.uint32(770).string(message.currentMemberId);
    }
    if (message.otherGroupCoverageAddressCity !== undefined) {
      writer.uint32(778).string(message.otherGroupCoverageAddressCity);
    }
    if (message.creditCardNumber !== undefined) {
      writer.uint32(786).string(message.creditCardNumber);
    }
    if (message.ltcAddressLine1 !== undefined) {
      writer.uint32(794).string(message.ltcAddressLine1);
    }
    if (message.bankAccountType !== undefined) {
      writer.uint32(802).string(message.bankAccountType);
    }
    if (message.otherMedicareCoverageStillCovered !== undefined) {
      writer.uint32(810).string(message.otherMedicareCoverageStillCovered);
    }
    if (message.isCurrentMember !== undefined) {
      writer.uint32(818).string(message.isCurrentMember);
    }
    if (message.bankAccountHolderName !== undefined) {
      writer.uint32(826).string(message.bankAccountHolderName);
    }
    if (message.alternatePhoneNumber !== undefined) {
      writer.uint32(834).string(message.alternatePhoneNumber);
    }
    if (message.dateOfBirthDay !== undefined) {
      writer.uint32(842).string(message.dateOfBirthDay);
    }
    if (message.otherGroupCoverageAddressLine2 !== undefined) {
      writer.uint32(850).string(message.otherGroupCoverageAddressLine2);
    }
    if (message.otherMedicareCoverageStartDateMonth !== undefined) {
      writer.uint32(858).string(message.otherMedicareCoverageStartDateMonth);
    }
    if (message.otherMedicareCoverageDroppingMedSupp !== undefined) {
      writer.uint32(866).string(message.otherMedicareCoverageDroppingMedSupp);
    }
    if (message.otherGroupCoverageCarrierName !== undefined) {
      writer.uint32(874).string(message.otherGroupCoverageCarrierName);
    }
    if (message.sunFirePlanId !== undefined) {
      writer.uint32(882).string(message.sunFirePlanId);
    }
    if (message.medicaidAddBenefits !== undefined) {
      writer.uint32(890).string(message.medicaidAddBenefits);
    }
    if (message.otherMedicareCoveragePolicyId !== undefined) {
      writer.uint32(898).string(message.otherMedicareCoveragePolicyId);
    }
    if (message.otherMedicareCoverageFirstTime !== undefined) {
      writer.uint32(906).string(message.otherMedicareCoverageFirstTime);
    }
    if (message.otherMedicareCoverageName !== undefined) {
      writer.uint32(914).string(message.otherMedicareCoverageName);
    }
    if (message.otherMedicareCoverageStartDateDay !== undefined) {
      writer.uint32(922).string(message.otherMedicareCoverageStartDateDay);
    }
    if (message.workStatus !== undefined) {
      writer.uint32(928).bool(message.workStatus);
    }
    if (message.creditCardHolder !== undefined) {
      writer.uint32(938).string(message.creditCardHolder);
    }
    if (message.paymentType2 !== undefined) {
      writer.uint32(946).string(message.paymentType2);
    }
    if (message.pcpId !== undefined) {
      writer.uint32(954).string(message.pcpId);
    }
    if (message.ltcAdmitDateYear !== undefined) {
      writer.uint32(962).string(message.ltcAdmitDateYear);
    }
    if (message.marketingId !== undefined) {
      writer.uint32(970).string(message.marketingId);
    }
    if (message.county !== undefined) {
      writer.uint32(978).string(message.county);
    }
    if (message.hasCopd !== undefined) {
      writer.uint32(984).bool(message.hasCopd);
    }
    if (message.copdHasEmphysema !== undefined) {
      writer.uint32(992).bool(message.copdHasEmphysema);
    }
    if (message.copdTakesDrugsForBreathing !== undefined) {
      writer.uint32(1000).bool(message.copdTakesDrugsForBreathing);
    }
    if (message.copdHasAsthma !== undefined) {
      writer.uint32(1008).bool(message.copdHasAsthma);
    }
    if (message.copdHasDifficultyBreathing !== undefined) {
      writer.uint32(1016).bool(message.copdHasDifficultyBreathing);
    }
    if (message.copdNeedsOxygenSupport !== undefined) {
      writer.uint32(1024).bool(message.copdNeedsOxygenSupport);
    }
    if (message.hasChf !== undefined) {
      writer.uint32(1032).bool(message.hasChf);
    }
    if (message.chfHasBodyFluid !== undefined) {
      writer.uint32(1040).bool(message.chfHasBodyFluid);
    }
    if (message.chfTakesDrugsForLungFluid !== undefined) {
      writer.uint32(1050).string(message.chfTakesDrugsForLungFluid);
    }
    if (message.chfHasLungFluid !== undefined) {
      writer.uint32(1056).bool(message.chfHasLungFluid);
    }
    if (message.hasDiabetes !== undefined) {
      writer.uint32(1064).bool(message.hasDiabetes);
    }
    if (message.diabetesChecksBloodSugar !== undefined) {
      writer.uint32(1074).string(message.diabetesChecksBloodSugar);
    }
    if (message.diabetesTakesDrugsForBloodSugar !== undefined) {
      writer.uint32(1082).string(message.diabetesTakesDrugsForBloodSugar);
    }
    if (message.diabetesHasHighBloodSugar !== undefined) {
      writer.uint32(1090).string(message.diabetesHasHighBloodSugar);
    }
    if (message.hasEsrd !== undefined) {
      writer.uint32(1096).bool(message.hasEsrd);
    }
    if (message.esrd !== undefined) {
      writer.uint32(1106).string(message.esrd);
    }
    if (message.hadSuccessfulTransplant !== undefined) {
      writer.uint32(1112).bool(message.hadSuccessfulTransplant);
    }
    if (message.requiresRegularDialysis !== undefined) {
      writer.uint32(1120).bool(message.requiresRegularDialysis);
    }
    if (message.dialysisCenterName !== undefined) {
      writer.uint32(1130).string(message.dialysisCenterName);
    }
    if (message.dialysisCid !== undefined) {
      writer.uint32(1138).string(message.dialysisCid);
    }
    if (message.hasCvd !== undefined) {
      writer.uint32(1144).bool(message.hasCvd);
    }
    if (message.cvdHasChestOrLegPain !== undefined) {
      writer.uint32(1152).bool(message.cvdHasChestOrLegPain);
    }
    if (message.cvdTakesDrugsForHeart !== undefined) {
      writer.uint32(1160).bool(message.cvdTakesDrugsForHeart);
    }
    if (message.cvdHadHeartAttack !== undefined) {
      writer.uint32(1168).bool(message.cvdHadHeartAttack);
    }
    if (message.hasCardiovascularDisorder !== undefined) {
      writer.uint32(1176).bool(message.hasCardiovascularDisorder);
    }
    if (message.isInLongTermCareFacility !== undefined) {
      writer.uint32(1184).bool(message.isInLongTermCareFacility);
    }
    if (message.medicarePartADate !== undefined) {
      writer.uint32(1194).string(message.medicarePartADate);
    }
    if (message.medicarePartBDate !== undefined) {
      writer.uint32(1202).string(message.medicarePartBDate);
    }
    if (message.oldPartA !== undefined) {
      writer.uint32(1210).string(message.oldPartA);
    }
    if (message.oldPartB !== undefined) {
      writer.uint32(1218).string(message.oldPartB);
    }
    if (message.isOnMedicaid !== undefined) {
      writer.uint32(1224).bool(message.isOnMedicaid);
    }
    if (message.stateMedicaid !== undefined) {
      writer.uint32(1234).string(message.stateMedicaid);
    }
    if (message.medicaidNumber !== undefined) {
      writer.uint32(1242).string(message.medicaidNumber);
    }
    if (message.lastNote !== undefined) {
      writer.uint32(1250).string(message.lastNote);
    }
    if (message.latitude !== undefined) {
      writer.uint32(1257).double(message.latitude);
    }
    if (message.longitude !== undefined) {
      writer.uint32(1265).double(message.longitude);
    }
    if (message.agentId !== undefined) {
      writer.uint32(1274).string(message.agentId);
    }
    if (message.agent !== undefined) {
      Sunfire_Agent.encode(message.agent, writer.uint32(1282).fork()).ldelim();
    }
    if (message.consumer !== undefined) {
      Sunfire_Consumer.encode(message.consumer, writer.uint32(1290).fork()).ldelim();
    }
    if (message.contactId !== undefined) {
      writer.uint32(1298).string(message.contactId);
    }
    if (message.firstName !== undefined) {
      writer.uint32(1306).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(1314).string(message.lastName);
    }
    if (message.phone !== undefined) {
      writer.uint32(1322).string(message.phone);
    }
    if (message.email !== undefined) {
      writer.uint32(1330).string(message.email);
    }
    if (message.gender !== undefined) {
      writer.uint32(1338).string(message.gender);
    }
    if (message.hicn !== undefined) {
      writer.uint32(1346).string(message.hicn);
    }
    if (message.medicareNumber !== undefined) {
      writer.uint32(1354).string(message.medicareNumber);
    }
    if (message.additionalEmails !== undefined) {
      writer.uint32(1362).string(message.additionalEmails);
    }
    if (message.additionalPhones !== undefined) {
      writer.uint32(1370).string(message.additionalPhones);
    }
    if (message.address !== undefined) {
      writer.uint32(1378).string(message.address);
    }
    if (message.street !== undefined) {
      writer.uint32(1386).string(message.street);
    }
    if (message.city !== undefined) {
      writer.uint32(1394).string(message.city);
    }
    if (message.state !== undefined) {
      writer.uint32(1402).string(message.state);
    }
    if (message.zip !== undefined) {
      writer.uint32(1410).string(message.zip);
    }
    if (message.birthDate !== undefined) {
      writer.uint32(1418).string(message.birthDate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Sunfire_Plan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSunfire_Plan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.contractId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.carrierName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.planName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.carrierId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.planId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.enrollmentPlanYear = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.planYear = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.effectiveDate = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.appSubmitDate = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.submitTime = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.primaryCarePhysician = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.pcpName = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.electionPeriod = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.sepReasonCode = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.cmsPlanId = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.cmsContractId = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.planCarrierId = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.confirmationNumber = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.writingId = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.heapAgencyAffiliation = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.cmsSegmentId = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.genderSpecified = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.poaAddressCity = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.poaAddressCountyName = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.replacingOtherMedicareCoverage = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.otherGroupCoverageAddressState = reader.string();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.ltcAddressLine2 = reader.string();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.ltcAddressCountyName = reader.string();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.otherGroupCoverageAddressCountyName = reader.string();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.ltcAddressFips = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.premiumDeducted = reader.string();
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.ltcAddressState = reader.string();
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.canEmailTheEnrollee = reader.bool();
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.creditCardExpiration = reader.string();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.siteId = reader.string();
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.premiumWithhold = reader.string();
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.dateOfBirthMonth = reader.string();
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.ltcName = reader.string();
          continue;
        case 40:
          if (tag !== 320) {
            break;
          }

          message.pcpIsEstablishedPatient = reader.bool();
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.otherGroupCoverageBeingReplaced = reader.string();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.ltcAddressCity = reader.string();
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.otherDrugCoverageMemberName = reader.string();
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.language = reader.string();
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.oldSubmitDate = reader.string();
          continue;
        case 46:
          if (tag !== 370) {
            break;
          }

          message.bankAccountNumber = reader.string();
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.sfId = reader.string();
          continue;
        case 48:
          if (tag !== 386) {
            break;
          }

          message.otherGroupCoverageAddressLine1 = reader.string();
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.otherDrugCoveragePolicyId = reader.string();
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.otherGroupCoverageEndDateMonth = reader.string();
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.otherMedicareCoverageStartDateYear = reader.string();
          continue;
        case 52:
          if (tag !== 418) {
            break;
          }

          message.pcpAddress = reader.string();
          continue;
        case 53:
          if (tag !== 426) {
            break;
          }

          message.otherGroupCoverageStartDateYear = reader.string();
          continue;
        case 54:
          if (tag !== 434) {
            break;
          }

          message.medicaidPaidPremium = reader.string();
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.otherGroupCoverageAddressZip = reader.string();
          continue;
        case 56:
          if (tag !== 450) {
            break;
          }

          message.emergencyContactPhone = reader.string();
          continue;
        case 57:
          if (tag !== 458) {
            break;
          }

          message.otherMedicareCoverageEndDateYear = reader.string();
          continue;
        case 58:
          if (tag !== 466) {
            break;
          }

          message.otherGroupCoverageStartDateMonth = reader.string();
          continue;
        case 59:
          if (tag !== 474) {
            break;
          }

          message.otherCoverage = reader.string();
          continue;
        case 60:
          if (tag !== 482) {
            break;
          }

          message.ltcPhone = reader.string();
          continue;
        case 61:
          if (tag !== 488) {
            break;
          }

          message.hasOtherDrugCoverage = reader.bool();
          continue;
        case 62:
          if (tag !== 498) {
            break;
          }

          message.otherGroupCoverageEndDateDay = reader.string();
          continue;
        case 63:
          if (tag !== 506) {
            break;
          }

          message.nephrologistName = reader.string();
          continue;
        case 64:
          if (tag !== 514) {
            break;
          }

          message.ethnicity = reader.string();
          continue;
        case 65:
          if (tag !== 522) {
            break;
          }

          message.otherMedicareCoverageEndDateMonth = reader.string();
          continue;
        case 66:
          if (tag !== 530) {
            break;
          }

          message.ltcAdmitDateMonth = reader.string();
          continue;
        case 67:
          if (tag !== 538) {
            break;
          }

          message.applicantEsignature = reader.string();
          continue;
        case 68:
          if (tag !== 546) {
            break;
          }

          message.paymentType = reader.string();
          continue;
        case 69:
          if (tag !== 554) {
            break;
          }

          message.dateOfBirth = reader.string();
          continue;
        case 70:
          if (tag !== 562) {
            break;
          }

          message.currentPlan = reader.string();
          continue;
        case 71:
          if (tag !== 570) {
            break;
          }

          message.planType = reader.string();
          continue;
        case 72:
          if (tag !== 578) {
            break;
          }

          message.hasOtherGroupCoverage = reader.string();
          continue;
        case 73:
          if (tag !== 586) {
            break;
          }

          message.wishToDesignatePcp = reader.string();
          continue;
        case 74:
          if (tag !== 594) {
            break;
          }

          message.otherMedicareCoverageEndDateDay = reader.string();
          continue;
        case 75:
          if (tag !== 602) {
            break;
          }

          message.emergencyContactName = reader.string();
          continue;
        case 76:
          if (tag !== 610) {
            break;
          }

          message.creditCardType = reader.string();
          continue;
        case 77:
          if (tag !== 618) {
            break;
          }

          message.otherGroupCoveragePolicyId = reader.string();
          continue;
        case 78:
          if (tag !== 626) {
            break;
          }

          message.otherGroupCoverageIncludesPrescriptionDrugCoverage = reader.string();
          continue;
        case 79:
          if (tag !== 634) {
            break;
          }

          message.parameters = reader.string();
          continue;
        case 80:
          if (tag !== 642) {
            break;
          }

          message.emergencyContactRelationship = reader.string();
          continue;
        case 81:
          if (tag !== 650) {
            break;
          }

          message.premiumDirectPay = reader.string();
          continue;
        case 82:
          if (tag !== 658) {
            break;
          }

          message.otherGroupCoverageStartDateDay = reader.string();
          continue;
        case 83:
          if (tag !== 666) {
            break;
          }

          message.longTerm = reader.string();
          continue;
        case 84:
          if (tag !== 674) {
            break;
          }

          message.otherDrugCoverageMemberId = reader.string();
          continue;
        case 85:
          if (tag !== 682) {
            break;
          }

          message.otherGroupCoverageEndDate = reader.string();
          continue;
        case 86:
          if (tag !== 690) {
            break;
          }

          message.ltcAdmitDateDay = reader.string();
          continue;
        case 87:
          if (tag !== 698) {
            break;
          }

          message.dsnpVerificationCode = reader.string();
          continue;
        case 88:
          if (tag !== 706) {
            break;
          }

          message.hasOtherMedicareCoverage = reader.string();
          continue;
        case 89:
          if (tag !== 714) {
            break;
          }

          message.otherGroupCoverageAddressFips = reader.string();
          continue;
        case 90:
          if (tag !== 722) {
            break;
          }

          message.enrolledWithCarrierAlready = reader.string();
          continue;
        case 91:
          if (tag !== 730) {
            break;
          }

          message.homeAddressFips = reader.string();
          continue;
        case 92:
          if (tag !== 738) {
            break;
          }

          message.ipAddress = reader.string();
          continue;
        case 93:
          if (tag !== 746) {
            break;
          }

          message.needsContinuityOfCare = reader.string();
          continue;
        case 94:
          if (tag !== 754) {
            break;
          }

          message.dateOfBirthYear = reader.string();
          continue;
        case 95:
          if (tag !== 762) {
            break;
          }

          message.ltcAddressZip = reader.string();
          continue;
        case 96:
          if (tag !== 770) {
            break;
          }

          message.currentMemberId = reader.string();
          continue;
        case 97:
          if (tag !== 778) {
            break;
          }

          message.otherGroupCoverageAddressCity = reader.string();
          continue;
        case 98:
          if (tag !== 786) {
            break;
          }

          message.creditCardNumber = reader.string();
          continue;
        case 99:
          if (tag !== 794) {
            break;
          }

          message.ltcAddressLine1 = reader.string();
          continue;
        case 100:
          if (tag !== 802) {
            break;
          }

          message.bankAccountType = reader.string();
          continue;
        case 101:
          if (tag !== 810) {
            break;
          }

          message.otherMedicareCoverageStillCovered = reader.string();
          continue;
        case 102:
          if (tag !== 818) {
            break;
          }

          message.isCurrentMember = reader.string();
          continue;
        case 103:
          if (tag !== 826) {
            break;
          }

          message.bankAccountHolderName = reader.string();
          continue;
        case 104:
          if (tag !== 834) {
            break;
          }

          message.alternatePhoneNumber = reader.string();
          continue;
        case 105:
          if (tag !== 842) {
            break;
          }

          message.dateOfBirthDay = reader.string();
          continue;
        case 106:
          if (tag !== 850) {
            break;
          }

          message.otherGroupCoverageAddressLine2 = reader.string();
          continue;
        case 107:
          if (tag !== 858) {
            break;
          }

          message.otherMedicareCoverageStartDateMonth = reader.string();
          continue;
        case 108:
          if (tag !== 866) {
            break;
          }

          message.otherMedicareCoverageDroppingMedSupp = reader.string();
          continue;
        case 109:
          if (tag !== 874) {
            break;
          }

          message.otherGroupCoverageCarrierName = reader.string();
          continue;
        case 110:
          if (tag !== 882) {
            break;
          }

          message.sunFirePlanId = reader.string();
          continue;
        case 111:
          if (tag !== 890) {
            break;
          }

          message.medicaidAddBenefits = reader.string();
          continue;
        case 112:
          if (tag !== 898) {
            break;
          }

          message.otherMedicareCoveragePolicyId = reader.string();
          continue;
        case 113:
          if (tag !== 906) {
            break;
          }

          message.otherMedicareCoverageFirstTime = reader.string();
          continue;
        case 114:
          if (tag !== 914) {
            break;
          }

          message.otherMedicareCoverageName = reader.string();
          continue;
        case 115:
          if (tag !== 922) {
            break;
          }

          message.otherMedicareCoverageStartDateDay = reader.string();
          continue;
        case 116:
          if (tag !== 928) {
            break;
          }

          message.workStatus = reader.bool();
          continue;
        case 117:
          if (tag !== 938) {
            break;
          }

          message.creditCardHolder = reader.string();
          continue;
        case 118:
          if (tag !== 946) {
            break;
          }

          message.paymentType2 = reader.string();
          continue;
        case 119:
          if (tag !== 954) {
            break;
          }

          message.pcpId = reader.string();
          continue;
        case 120:
          if (tag !== 962) {
            break;
          }

          message.ltcAdmitDateYear = reader.string();
          continue;
        case 121:
          if (tag !== 970) {
            break;
          }

          message.marketingId = reader.string();
          continue;
        case 122:
          if (tag !== 978) {
            break;
          }

          message.county = reader.string();
          continue;
        case 123:
          if (tag !== 984) {
            break;
          }

          message.hasCopd = reader.bool();
          continue;
        case 124:
          if (tag !== 992) {
            break;
          }

          message.copdHasEmphysema = reader.bool();
          continue;
        case 125:
          if (tag !== 1000) {
            break;
          }

          message.copdTakesDrugsForBreathing = reader.bool();
          continue;
        case 126:
          if (tag !== 1008) {
            break;
          }

          message.copdHasAsthma = reader.bool();
          continue;
        case 127:
          if (tag !== 1016) {
            break;
          }

          message.copdHasDifficultyBreathing = reader.bool();
          continue;
        case 128:
          if (tag !== 1024) {
            break;
          }

          message.copdNeedsOxygenSupport = reader.bool();
          continue;
        case 129:
          if (tag !== 1032) {
            break;
          }

          message.hasChf = reader.bool();
          continue;
        case 130:
          if (tag !== 1040) {
            break;
          }

          message.chfHasBodyFluid = reader.bool();
          continue;
        case 131:
          if (tag !== 1050) {
            break;
          }

          message.chfTakesDrugsForLungFluid = reader.string();
          continue;
        case 132:
          if (tag !== 1056) {
            break;
          }

          message.chfHasLungFluid = reader.bool();
          continue;
        case 133:
          if (tag !== 1064) {
            break;
          }

          message.hasDiabetes = reader.bool();
          continue;
        case 134:
          if (tag !== 1074) {
            break;
          }

          message.diabetesChecksBloodSugar = reader.string();
          continue;
        case 135:
          if (tag !== 1082) {
            break;
          }

          message.diabetesTakesDrugsForBloodSugar = reader.string();
          continue;
        case 136:
          if (tag !== 1090) {
            break;
          }

          message.diabetesHasHighBloodSugar = reader.string();
          continue;
        case 137:
          if (tag !== 1096) {
            break;
          }

          message.hasEsrd = reader.bool();
          continue;
        case 138:
          if (tag !== 1106) {
            break;
          }

          message.esrd = reader.string();
          continue;
        case 139:
          if (tag !== 1112) {
            break;
          }

          message.hadSuccessfulTransplant = reader.bool();
          continue;
        case 140:
          if (tag !== 1120) {
            break;
          }

          message.requiresRegularDialysis = reader.bool();
          continue;
        case 141:
          if (tag !== 1130) {
            break;
          }

          message.dialysisCenterName = reader.string();
          continue;
        case 142:
          if (tag !== 1138) {
            break;
          }

          message.dialysisCid = reader.string();
          continue;
        case 143:
          if (tag !== 1144) {
            break;
          }

          message.hasCvd = reader.bool();
          continue;
        case 144:
          if (tag !== 1152) {
            break;
          }

          message.cvdHasChestOrLegPain = reader.bool();
          continue;
        case 145:
          if (tag !== 1160) {
            break;
          }

          message.cvdTakesDrugsForHeart = reader.bool();
          continue;
        case 146:
          if (tag !== 1168) {
            break;
          }

          message.cvdHadHeartAttack = reader.bool();
          continue;
        case 147:
          if (tag !== 1176) {
            break;
          }

          message.hasCardiovascularDisorder = reader.bool();
          continue;
        case 148:
          if (tag !== 1184) {
            break;
          }

          message.isInLongTermCareFacility = reader.bool();
          continue;
        case 149:
          if (tag !== 1194) {
            break;
          }

          message.medicarePartADate = reader.string();
          continue;
        case 150:
          if (tag !== 1202) {
            break;
          }

          message.medicarePartBDate = reader.string();
          continue;
        case 151:
          if (tag !== 1210) {
            break;
          }

          message.oldPartA = reader.string();
          continue;
        case 152:
          if (tag !== 1218) {
            break;
          }

          message.oldPartB = reader.string();
          continue;
        case 153:
          if (tag !== 1224) {
            break;
          }

          message.isOnMedicaid = reader.bool();
          continue;
        case 154:
          if (tag !== 1234) {
            break;
          }

          message.stateMedicaid = reader.string();
          continue;
        case 155:
          if (tag !== 1242) {
            break;
          }

          message.medicaidNumber = reader.string();
          continue;
        case 156:
          if (tag !== 1250) {
            break;
          }

          message.lastNote = reader.string();
          continue;
        case 157:
          if (tag !== 1257) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 158:
          if (tag !== 1265) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 159:
          if (tag !== 1274) {
            break;
          }

          message.agentId = reader.string();
          continue;
        case 160:
          if (tag !== 1282) {
            break;
          }

          message.agent = Sunfire_Agent.decode(reader, reader.uint32());
          continue;
        case 161:
          if (tag !== 1290) {
            break;
          }

          message.consumer = Sunfire_Consumer.decode(reader, reader.uint32());
          continue;
        case 162:
          if (tag !== 1298) {
            break;
          }

          message.contactId = reader.string();
          continue;
        case 163:
          if (tag !== 1306) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 164:
          if (tag !== 1314) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 165:
          if (tag !== 1322) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 166:
          if (tag !== 1330) {
            break;
          }

          message.email = reader.string();
          continue;
        case 167:
          if (tag !== 1338) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 168:
          if (tag !== 1346) {
            break;
          }

          message.hicn = reader.string();
          continue;
        case 169:
          if (tag !== 1354) {
            break;
          }

          message.medicareNumber = reader.string();
          continue;
        case 170:
          if (tag !== 1362) {
            break;
          }

          message.additionalEmails = reader.string();
          continue;
        case 171:
          if (tag !== 1370) {
            break;
          }

          message.additionalPhones = reader.string();
          continue;
        case 172:
          if (tag !== 1378) {
            break;
          }

          message.address = reader.string();
          continue;
        case 173:
          if (tag !== 1386) {
            break;
          }

          message.street = reader.string();
          continue;
        case 174:
          if (tag !== 1394) {
            break;
          }

          message.city = reader.string();
          continue;
        case 175:
          if (tag !== 1402) {
            break;
          }

          message.state = reader.string();
          continue;
        case 176:
          if (tag !== 1410) {
            break;
          }

          message.zip = reader.string();
          continue;
        case 177:
          if (tag !== 1418) {
            break;
          }

          message.birthDate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Sunfire_Plan {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      contractId: isSet(object.contractId) ? String(object.contractId) : undefined,
      carrierName: isSet(object.carrierName) ? String(object.carrierName) : undefined,
      planName: isSet(object.planName) ? String(object.planName) : undefined,
      carrierId: isSet(object.carrierId) ? String(object.carrierId) : undefined,
      planId: isSet(object.planId) ? String(object.planId) : undefined,
      enrollmentPlanYear: isSet(object.enrollmentPlanYear) ? String(object.enrollmentPlanYear) : undefined,
      planYear: isSet(object.planYear) ? String(object.planYear) : undefined,
      effectiveDate: isSet(object.effectiveDate) ? String(object.effectiveDate) : undefined,
      appSubmitDate: isSet(object.appSubmitDate) ? String(object.appSubmitDate) : undefined,
      submitTime: isSet(object.submitTime) ? String(object.submitTime) : undefined,
      primaryCarePhysician: isSet(object.primaryCarePhysician) ? String(object.primaryCarePhysician) : undefined,
      pcpName: isSet(object.pcpName) ? String(object.pcpName) : undefined,
      electionPeriod: isSet(object.electionPeriod) ? String(object.electionPeriod) : undefined,
      sepReasonCode: isSet(object.sepReasonCode) ? String(object.sepReasonCode) : undefined,
      cmsPlanId: isSet(object.cmsPlanId) ? String(object.cmsPlanId) : undefined,
      cmsContractId: isSet(object.cmsContractId) ? String(object.cmsContractId) : undefined,
      planCarrierId: isSet(object.planCarrierId) ? String(object.planCarrierId) : undefined,
      confirmationNumber: isSet(object.confirmationNumber) ? String(object.confirmationNumber) : undefined,
      writingId: isSet(object.writingId) ? String(object.writingId) : undefined,
      heapAgencyAffiliation: isSet(object.heapAgencyAffiliation) ? String(object.heapAgencyAffiliation) : undefined,
      cmsSegmentId: isSet(object.cmsSegmentId) ? String(object.cmsSegmentId) : undefined,
      genderSpecified: isSet(object.genderSpecified) ? String(object.genderSpecified) : undefined,
      poaAddressCity: isSet(object.poaAddressCity) ? String(object.poaAddressCity) : undefined,
      poaAddressCountyName: isSet(object.poaAddressCountyName) ? String(object.poaAddressCountyName) : undefined,
      replacingOtherMedicareCoverage: isSet(object.replacingOtherMedicareCoverage)
        ? String(object.replacingOtherMedicareCoverage)
        : undefined,
      otherGroupCoverageAddressState: isSet(object.otherGroupCoverageAddressState)
        ? String(object.otherGroupCoverageAddressState)
        : undefined,
      ltcAddressLine2: isSet(object.ltcAddressLine2) ? String(object.ltcAddressLine2) : undefined,
      ltcAddressCountyName: isSet(object.ltcAddressCountyName) ? String(object.ltcAddressCountyName) : undefined,
      otherGroupCoverageAddressCountyName: isSet(object.otherGroupCoverageAddressCountyName)
        ? String(object.otherGroupCoverageAddressCountyName)
        : undefined,
      ltcAddressFips: isSet(object.ltcAddressFips) ? String(object.ltcAddressFips) : undefined,
      premiumDeducted: isSet(object.premiumDeducted) ? String(object.premiumDeducted) : undefined,
      ltcAddressState: isSet(object.ltcAddressState) ? String(object.ltcAddressState) : undefined,
      canEmailTheEnrollee: isSet(object.canEmailTheEnrollee) ? Boolean(object.canEmailTheEnrollee) : undefined,
      creditCardExpiration: isSet(object.creditCardExpiration) ? String(object.creditCardExpiration) : undefined,
      siteId: isSet(object.siteId) ? String(object.siteId) : undefined,
      premiumWithhold: isSet(object.premiumWithhold) ? String(object.premiumWithhold) : undefined,
      dateOfBirthMonth: isSet(object.dateOfBirthMonth) ? String(object.dateOfBirthMonth) : undefined,
      ltcName: isSet(object.ltcName) ? String(object.ltcName) : undefined,
      pcpIsEstablishedPatient: isSet(object.pcpIsEstablishedPatient)
        ? Boolean(object.pcpIsEstablishedPatient)
        : undefined,
      otherGroupCoverageBeingReplaced: isSet(object.otherGroupCoverageBeingReplaced)
        ? String(object.otherGroupCoverageBeingReplaced)
        : undefined,
      ltcAddressCity: isSet(object.ltcAddressCity) ? String(object.ltcAddressCity) : undefined,
      otherDrugCoverageMemberName: isSet(object.otherDrugCoverageMemberName)
        ? String(object.otherDrugCoverageMemberName)
        : undefined,
      language: isSet(object.language) ? String(object.language) : undefined,
      oldSubmitDate: isSet(object.oldSubmitDate) ? String(object.oldSubmitDate) : undefined,
      bankAccountNumber: isSet(object.bankAccountNumber) ? String(object.bankAccountNumber) : undefined,
      sfId: isSet(object.sfId) ? String(object.sfId) : undefined,
      otherGroupCoverageAddressLine1: isSet(object.otherGroupCoverageAddressLine1)
        ? String(object.otherGroupCoverageAddressLine1)
        : undefined,
      otherDrugCoveragePolicyId: isSet(object.otherDrugCoveragePolicyId)
        ? String(object.otherDrugCoveragePolicyId)
        : undefined,
      otherGroupCoverageEndDateMonth: isSet(object.otherGroupCoverageEndDateMonth)
        ? String(object.otherGroupCoverageEndDateMonth)
        : undefined,
      otherMedicareCoverageStartDateYear: isSet(object.otherMedicareCoverageStartDateYear)
        ? String(object.otherMedicareCoverageStartDateYear)
        : undefined,
      pcpAddress: isSet(object.pcpAddress) ? String(object.pcpAddress) : undefined,
      otherGroupCoverageStartDateYear: isSet(object.otherGroupCoverageStartDateYear)
        ? String(object.otherGroupCoverageStartDateYear)
        : undefined,
      medicaidPaidPremium: isSet(object.medicaidPaidPremium) ? String(object.medicaidPaidPremium) : undefined,
      otherGroupCoverageAddressZip: isSet(object.otherGroupCoverageAddressZip)
        ? String(object.otherGroupCoverageAddressZip)
        : undefined,
      emergencyContactPhone: isSet(object.emergencyContactPhone) ? String(object.emergencyContactPhone) : undefined,
      otherMedicareCoverageEndDateYear: isSet(object.otherMedicareCoverageEndDateYear)
        ? String(object.otherMedicareCoverageEndDateYear)
        : undefined,
      otherGroupCoverageStartDateMonth: isSet(object.otherGroupCoverageStartDateMonth)
        ? String(object.otherGroupCoverageStartDateMonth)
        : undefined,
      otherCoverage: isSet(object.otherCoverage) ? String(object.otherCoverage) : undefined,
      ltcPhone: isSet(object.ltcPhone) ? String(object.ltcPhone) : undefined,
      hasOtherDrugCoverage: isSet(object.hasOtherDrugCoverage) ? Boolean(object.hasOtherDrugCoverage) : undefined,
      otherGroupCoverageEndDateDay: isSet(object.otherGroupCoverageEndDateDay)
        ? String(object.otherGroupCoverageEndDateDay)
        : undefined,
      nephrologistName: isSet(object.nephrologistName) ? String(object.nephrologistName) : undefined,
      ethnicity: isSet(object.ethnicity) ? String(object.ethnicity) : undefined,
      otherMedicareCoverageEndDateMonth: isSet(object.otherMedicareCoverageEndDateMonth)
        ? String(object.otherMedicareCoverageEndDateMonth)
        : undefined,
      ltcAdmitDateMonth: isSet(object.ltcAdmitDateMonth) ? String(object.ltcAdmitDateMonth) : undefined,
      applicantEsignature: isSet(object.applicantEsignature) ? String(object.applicantEsignature) : undefined,
      paymentType: isSet(object.paymentType) ? String(object.paymentType) : undefined,
      dateOfBirth: isSet(object.dateOfBirth) ? String(object.dateOfBirth) : undefined,
      currentPlan: isSet(object.currentPlan) ? String(object.currentPlan) : undefined,
      planType: isSet(object.planType) ? String(object.planType) : undefined,
      hasOtherGroupCoverage: isSet(object.hasOtherGroupCoverage) ? String(object.hasOtherGroupCoverage) : undefined,
      wishToDesignatePcp: isSet(object.wishToDesignatePcp) ? String(object.wishToDesignatePcp) : undefined,
      otherMedicareCoverageEndDateDay: isSet(object.otherMedicareCoverageEndDateDay)
        ? String(object.otherMedicareCoverageEndDateDay)
        : undefined,
      emergencyContactName: isSet(object.emergencyContactName) ? String(object.emergencyContactName) : undefined,
      creditCardType: isSet(object.creditCardType) ? String(object.creditCardType) : undefined,
      otherGroupCoveragePolicyId: isSet(object.otherGroupCoveragePolicyId)
        ? String(object.otherGroupCoveragePolicyId)
        : undefined,
      otherGroupCoverageIncludesPrescriptionDrugCoverage:
        isSet(object.otherGroupCoverageIncludesPrescriptionDrugCoverage)
          ? String(object.otherGroupCoverageIncludesPrescriptionDrugCoverage)
          : undefined,
      parameters: isSet(object.parameters) ? String(object.parameters) : undefined,
      emergencyContactRelationship: isSet(object.emergencyContactRelationship)
        ? String(object.emergencyContactRelationship)
        : undefined,
      premiumDirectPay: isSet(object.premiumDirectPay) ? String(object.premiumDirectPay) : undefined,
      otherGroupCoverageStartDateDay: isSet(object.otherGroupCoverageStartDateDay)
        ? String(object.otherGroupCoverageStartDateDay)
        : undefined,
      longTerm: isSet(object.longTerm) ? String(object.longTerm) : undefined,
      otherDrugCoverageMemberId: isSet(object.otherDrugCoverageMemberId)
        ? String(object.otherDrugCoverageMemberId)
        : undefined,
      otherGroupCoverageEndDate: isSet(object.otherGroupCoverageEndDate)
        ? String(object.otherGroupCoverageEndDate)
        : undefined,
      ltcAdmitDateDay: isSet(object.ltcAdmitDateDay) ? String(object.ltcAdmitDateDay) : undefined,
      dsnpVerificationCode: isSet(object.dsnpVerificationCode) ? String(object.dsnpVerificationCode) : undefined,
      hasOtherMedicareCoverage: isSet(object.hasOtherMedicareCoverage)
        ? String(object.hasOtherMedicareCoverage)
        : undefined,
      otherGroupCoverageAddressFips: isSet(object.otherGroupCoverageAddressFips)
        ? String(object.otherGroupCoverageAddressFips)
        : undefined,
      enrolledWithCarrierAlready: isSet(object.enrolledWithCarrierAlready)
        ? String(object.enrolledWithCarrierAlready)
        : undefined,
      homeAddressFips: isSet(object.homeAddressFips) ? String(object.homeAddressFips) : undefined,
      ipAddress: isSet(object.ipAddress) ? String(object.ipAddress) : undefined,
      needsContinuityOfCare: isSet(object.needsContinuityOfCare) ? String(object.needsContinuityOfCare) : undefined,
      dateOfBirthYear: isSet(object.dateOfBirthYear) ? String(object.dateOfBirthYear) : undefined,
      ltcAddressZip: isSet(object.ltcAddressZip) ? String(object.ltcAddressZip) : undefined,
      currentMemberId: isSet(object.currentMemberId) ? String(object.currentMemberId) : undefined,
      otherGroupCoverageAddressCity: isSet(object.otherGroupCoverageAddressCity)
        ? String(object.otherGroupCoverageAddressCity)
        : undefined,
      creditCardNumber: isSet(object.creditCardNumber) ? String(object.creditCardNumber) : undefined,
      ltcAddressLine1: isSet(object.ltcAddressLine1) ? String(object.ltcAddressLine1) : undefined,
      bankAccountType: isSet(object.bankAccountType) ? String(object.bankAccountType) : undefined,
      otherMedicareCoverageStillCovered: isSet(object.otherMedicareCoverageStillCovered)
        ? String(object.otherMedicareCoverageStillCovered)
        : undefined,
      isCurrentMember: isSet(object.isCurrentMember) ? String(object.isCurrentMember) : undefined,
      bankAccountHolderName: isSet(object.bankAccountHolderName) ? String(object.bankAccountHolderName) : undefined,
      alternatePhoneNumber: isSet(object.alternatePhoneNumber) ? String(object.alternatePhoneNumber) : undefined,
      dateOfBirthDay: isSet(object.dateOfBirthDay) ? String(object.dateOfBirthDay) : undefined,
      otherGroupCoverageAddressLine2: isSet(object.otherGroupCoverageAddressLine2)
        ? String(object.otherGroupCoverageAddressLine2)
        : undefined,
      otherMedicareCoverageStartDateMonth: isSet(object.otherMedicareCoverageStartDateMonth)
        ? String(object.otherMedicareCoverageStartDateMonth)
        : undefined,
      otherMedicareCoverageDroppingMedSupp: isSet(object.otherMedicareCoverageDroppingMedSupp)
        ? String(object.otherMedicareCoverageDroppingMedSupp)
        : undefined,
      otherGroupCoverageCarrierName: isSet(object.otherGroupCoverageCarrierName)
        ? String(object.otherGroupCoverageCarrierName)
        : undefined,
      sunFirePlanId: isSet(object.sunFirePlanId) ? String(object.sunFirePlanId) : undefined,
      medicaidAddBenefits: isSet(object.medicaidAddBenefits) ? String(object.medicaidAddBenefits) : undefined,
      otherMedicareCoveragePolicyId: isSet(object.otherMedicareCoveragePolicyId)
        ? String(object.otherMedicareCoveragePolicyId)
        : undefined,
      otherMedicareCoverageFirstTime: isSet(object.otherMedicareCoverageFirstTime)
        ? String(object.otherMedicareCoverageFirstTime)
        : undefined,
      otherMedicareCoverageName: isSet(object.otherMedicareCoverageName)
        ? String(object.otherMedicareCoverageName)
        : undefined,
      otherMedicareCoverageStartDateDay: isSet(object.otherMedicareCoverageStartDateDay)
        ? String(object.otherMedicareCoverageStartDateDay)
        : undefined,
      workStatus: isSet(object.workStatus) ? Boolean(object.workStatus) : undefined,
      creditCardHolder: isSet(object.creditCardHolder) ? String(object.creditCardHolder) : undefined,
      paymentType2: isSet(object.paymentType2) ? String(object.paymentType2) : undefined,
      pcpId: isSet(object.pcpId) ? String(object.pcpId) : undefined,
      ltcAdmitDateYear: isSet(object.ltcAdmitDateYear) ? String(object.ltcAdmitDateYear) : undefined,
      marketingId: isSet(object.marketingId) ? String(object.marketingId) : undefined,
      county: isSet(object.county) ? String(object.county) : undefined,
      hasCopd: isSet(object.hasCopd) ? Boolean(object.hasCopd) : undefined,
      copdHasEmphysema: isSet(object.copdHasEmphysema) ? Boolean(object.copdHasEmphysema) : undefined,
      copdTakesDrugsForBreathing: isSet(object.copdTakesDrugsForBreathing)
        ? Boolean(object.copdTakesDrugsForBreathing)
        : undefined,
      copdHasAsthma: isSet(object.copdHasAsthma) ? Boolean(object.copdHasAsthma) : undefined,
      copdHasDifficultyBreathing: isSet(object.copdHasDifficultyBreathing)
        ? Boolean(object.copdHasDifficultyBreathing)
        : undefined,
      copdNeedsOxygenSupport: isSet(object.copdNeedsOxygenSupport) ? Boolean(object.copdNeedsOxygenSupport) : undefined,
      hasChf: isSet(object.hasChf) ? Boolean(object.hasChf) : undefined,
      chfHasBodyFluid: isSet(object.chfHasBodyFluid) ? Boolean(object.chfHasBodyFluid) : undefined,
      chfTakesDrugsForLungFluid: isSet(object.chfTakesDrugsForLungFluid)
        ? String(object.chfTakesDrugsForLungFluid)
        : undefined,
      chfHasLungFluid: isSet(object.chfHasLungFluid) ? Boolean(object.chfHasLungFluid) : undefined,
      hasDiabetes: isSet(object.hasDiabetes) ? Boolean(object.hasDiabetes) : undefined,
      diabetesChecksBloodSugar: isSet(object.diabetesChecksBloodSugar)
        ? String(object.diabetesChecksBloodSugar)
        : undefined,
      diabetesTakesDrugsForBloodSugar: isSet(object.diabetesTakesDrugsForBloodSugar)
        ? String(object.diabetesTakesDrugsForBloodSugar)
        : undefined,
      diabetesHasHighBloodSugar: isSet(object.diabetesHasHighBloodSugar)
        ? String(object.diabetesHasHighBloodSugar)
        : undefined,
      hasEsrd: isSet(object.hasEsrd) ? Boolean(object.hasEsrd) : undefined,
      esrd: isSet(object.esrd) ? String(object.esrd) : undefined,
      hadSuccessfulTransplant: isSet(object.hadSuccessfulTransplant)
        ? Boolean(object.hadSuccessfulTransplant)
        : undefined,
      requiresRegularDialysis: isSet(object.requiresRegularDialysis)
        ? Boolean(object.requiresRegularDialysis)
        : undefined,
      dialysisCenterName: isSet(object.dialysisCenterName) ? String(object.dialysisCenterName) : undefined,
      dialysisCid: isSet(object.dialysisCid) ? String(object.dialysisCid) : undefined,
      hasCvd: isSet(object.hasCvd) ? Boolean(object.hasCvd) : undefined,
      cvdHasChestOrLegPain: isSet(object.cvdHasChestOrLegPain) ? Boolean(object.cvdHasChestOrLegPain) : undefined,
      cvdTakesDrugsForHeart: isSet(object.cvdTakesDrugsForHeart) ? Boolean(object.cvdTakesDrugsForHeart) : undefined,
      cvdHadHeartAttack: isSet(object.cvdHadHeartAttack) ? Boolean(object.cvdHadHeartAttack) : undefined,
      hasCardiovascularDisorder: isSet(object.hasCardiovascularDisorder)
        ? Boolean(object.hasCardiovascularDisorder)
        : undefined,
      isInLongTermCareFacility: isSet(object.isInLongTermCareFacility)
        ? Boolean(object.isInLongTermCareFacility)
        : undefined,
      medicarePartADate: isSet(object.medicarePartADate) ? String(object.medicarePartADate) : undefined,
      medicarePartBDate: isSet(object.medicarePartBDate) ? String(object.medicarePartBDate) : undefined,
      oldPartA: isSet(object.oldPartA) ? String(object.oldPartA) : undefined,
      oldPartB: isSet(object.oldPartB) ? String(object.oldPartB) : undefined,
      isOnMedicaid: isSet(object.isOnMedicaid) ? Boolean(object.isOnMedicaid) : undefined,
      stateMedicaid: isSet(object.stateMedicaid) ? String(object.stateMedicaid) : undefined,
      medicaidNumber: isSet(object.medicaidNumber) ? String(object.medicaidNumber) : undefined,
      lastNote: isSet(object.lastNote) ? String(object.lastNote) : undefined,
      latitude: isSet(object.latitude) ? Number(object.latitude) : undefined,
      longitude: isSet(object.longitude) ? Number(object.longitude) : undefined,
      agentId: isSet(object.agentId) ? String(object.agentId) : undefined,
      agent: isSet(object.agent) ? Sunfire_Agent.fromJSON(object.agent) : undefined,
      consumer: isSet(object.consumer) ? Sunfire_Consumer.fromJSON(object.consumer) : undefined,
      contactId: isSet(object.contactId) ? String(object.contactId) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      gender: isSet(object.gender) ? String(object.gender) : undefined,
      hicn: isSet(object.hicn) ? String(object.hicn) : undefined,
      medicareNumber: isSet(object.medicareNumber) ? String(object.medicareNumber) : undefined,
      additionalEmails: isSet(object.additionalEmails) ? String(object.additionalEmails) : undefined,
      additionalPhones: isSet(object.additionalPhones) ? String(object.additionalPhones) : undefined,
      address: isSet(object.address) ? String(object.address) : undefined,
      street: isSet(object.street) ? String(object.street) : undefined,
      city: isSet(object.city) ? String(object.city) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      zip: isSet(object.zip) ? String(object.zip) : undefined,
      birthDate: isSet(object.birthDate) ? String(object.birthDate) : undefined,
    };
  },

  toJSON(message: Sunfire_Plan): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.contractId !== undefined && (obj.contractId = message.contractId);
    message.carrierName !== undefined && (obj.carrierName = message.carrierName);
    message.planName !== undefined && (obj.planName = message.planName);
    message.carrierId !== undefined && (obj.carrierId = message.carrierId);
    message.planId !== undefined && (obj.planId = message.planId);
    message.enrollmentPlanYear !== undefined && (obj.enrollmentPlanYear = message.enrollmentPlanYear);
    message.planYear !== undefined && (obj.planYear = message.planYear);
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate);
    message.appSubmitDate !== undefined && (obj.appSubmitDate = message.appSubmitDate);
    message.submitTime !== undefined && (obj.submitTime = message.submitTime);
    message.primaryCarePhysician !== undefined && (obj.primaryCarePhysician = message.primaryCarePhysician);
    message.pcpName !== undefined && (obj.pcpName = message.pcpName);
    message.electionPeriod !== undefined && (obj.electionPeriod = message.electionPeriod);
    message.sepReasonCode !== undefined && (obj.sepReasonCode = message.sepReasonCode);
    message.cmsPlanId !== undefined && (obj.cmsPlanId = message.cmsPlanId);
    message.cmsContractId !== undefined && (obj.cmsContractId = message.cmsContractId);
    message.planCarrierId !== undefined && (obj.planCarrierId = message.planCarrierId);
    message.confirmationNumber !== undefined && (obj.confirmationNumber = message.confirmationNumber);
    message.writingId !== undefined && (obj.writingId = message.writingId);
    message.heapAgencyAffiliation !== undefined && (obj.heapAgencyAffiliation = message.heapAgencyAffiliation);
    message.cmsSegmentId !== undefined && (obj.cmsSegmentId = message.cmsSegmentId);
    message.genderSpecified !== undefined && (obj.genderSpecified = message.genderSpecified);
    message.poaAddressCity !== undefined && (obj.poaAddressCity = message.poaAddressCity);
    message.poaAddressCountyName !== undefined && (obj.poaAddressCountyName = message.poaAddressCountyName);
    message.replacingOtherMedicareCoverage !== undefined &&
      (obj.replacingOtherMedicareCoverage = message.replacingOtherMedicareCoverage);
    message.otherGroupCoverageAddressState !== undefined &&
      (obj.otherGroupCoverageAddressState = message.otherGroupCoverageAddressState);
    message.ltcAddressLine2 !== undefined && (obj.ltcAddressLine2 = message.ltcAddressLine2);
    message.ltcAddressCountyName !== undefined && (obj.ltcAddressCountyName = message.ltcAddressCountyName);
    message.otherGroupCoverageAddressCountyName !== undefined &&
      (obj.otherGroupCoverageAddressCountyName = message.otherGroupCoverageAddressCountyName);
    message.ltcAddressFips !== undefined && (obj.ltcAddressFips = message.ltcAddressFips);
    message.premiumDeducted !== undefined && (obj.premiumDeducted = message.premiumDeducted);
    message.ltcAddressState !== undefined && (obj.ltcAddressState = message.ltcAddressState);
    message.canEmailTheEnrollee !== undefined && (obj.canEmailTheEnrollee = message.canEmailTheEnrollee);
    message.creditCardExpiration !== undefined && (obj.creditCardExpiration = message.creditCardExpiration);
    message.siteId !== undefined && (obj.siteId = message.siteId);
    message.premiumWithhold !== undefined && (obj.premiumWithhold = message.premiumWithhold);
    message.dateOfBirthMonth !== undefined && (obj.dateOfBirthMonth = message.dateOfBirthMonth);
    message.ltcName !== undefined && (obj.ltcName = message.ltcName);
    message.pcpIsEstablishedPatient !== undefined && (obj.pcpIsEstablishedPatient = message.pcpIsEstablishedPatient);
    message.otherGroupCoverageBeingReplaced !== undefined &&
      (obj.otherGroupCoverageBeingReplaced = message.otherGroupCoverageBeingReplaced);
    message.ltcAddressCity !== undefined && (obj.ltcAddressCity = message.ltcAddressCity);
    message.otherDrugCoverageMemberName !== undefined &&
      (obj.otherDrugCoverageMemberName = message.otherDrugCoverageMemberName);
    message.language !== undefined && (obj.language = message.language);
    message.oldSubmitDate !== undefined && (obj.oldSubmitDate = message.oldSubmitDate);
    message.bankAccountNumber !== undefined && (obj.bankAccountNumber = message.bankAccountNumber);
    message.sfId !== undefined && (obj.sfId = message.sfId);
    message.otherGroupCoverageAddressLine1 !== undefined &&
      (obj.otherGroupCoverageAddressLine1 = message.otherGroupCoverageAddressLine1);
    message.otherDrugCoveragePolicyId !== undefined &&
      (obj.otherDrugCoveragePolicyId = message.otherDrugCoveragePolicyId);
    message.otherGroupCoverageEndDateMonth !== undefined &&
      (obj.otherGroupCoverageEndDateMonth = message.otherGroupCoverageEndDateMonth);
    message.otherMedicareCoverageStartDateYear !== undefined &&
      (obj.otherMedicareCoverageStartDateYear = message.otherMedicareCoverageStartDateYear);
    message.pcpAddress !== undefined && (obj.pcpAddress = message.pcpAddress);
    message.otherGroupCoverageStartDateYear !== undefined &&
      (obj.otherGroupCoverageStartDateYear = message.otherGroupCoverageStartDateYear);
    message.medicaidPaidPremium !== undefined && (obj.medicaidPaidPremium = message.medicaidPaidPremium);
    message.otherGroupCoverageAddressZip !== undefined &&
      (obj.otherGroupCoverageAddressZip = message.otherGroupCoverageAddressZip);
    message.emergencyContactPhone !== undefined && (obj.emergencyContactPhone = message.emergencyContactPhone);
    message.otherMedicareCoverageEndDateYear !== undefined &&
      (obj.otherMedicareCoverageEndDateYear = message.otherMedicareCoverageEndDateYear);
    message.otherGroupCoverageStartDateMonth !== undefined &&
      (obj.otherGroupCoverageStartDateMonth = message.otherGroupCoverageStartDateMonth);
    message.otherCoverage !== undefined && (obj.otherCoverage = message.otherCoverage);
    message.ltcPhone !== undefined && (obj.ltcPhone = message.ltcPhone);
    message.hasOtherDrugCoverage !== undefined && (obj.hasOtherDrugCoverage = message.hasOtherDrugCoverage);
    message.otherGroupCoverageEndDateDay !== undefined &&
      (obj.otherGroupCoverageEndDateDay = message.otherGroupCoverageEndDateDay);
    message.nephrologistName !== undefined && (obj.nephrologistName = message.nephrologistName);
    message.ethnicity !== undefined && (obj.ethnicity = message.ethnicity);
    message.otherMedicareCoverageEndDateMonth !== undefined &&
      (obj.otherMedicareCoverageEndDateMonth = message.otherMedicareCoverageEndDateMonth);
    message.ltcAdmitDateMonth !== undefined && (obj.ltcAdmitDateMonth = message.ltcAdmitDateMonth);
    message.applicantEsignature !== undefined && (obj.applicantEsignature = message.applicantEsignature);
    message.paymentType !== undefined && (obj.paymentType = message.paymentType);
    message.dateOfBirth !== undefined && (obj.dateOfBirth = message.dateOfBirth);
    message.currentPlan !== undefined && (obj.currentPlan = message.currentPlan);
    message.planType !== undefined && (obj.planType = message.planType);
    message.hasOtherGroupCoverage !== undefined && (obj.hasOtherGroupCoverage = message.hasOtherGroupCoverage);
    message.wishToDesignatePcp !== undefined && (obj.wishToDesignatePcp = message.wishToDesignatePcp);
    message.otherMedicareCoverageEndDateDay !== undefined &&
      (obj.otherMedicareCoverageEndDateDay = message.otherMedicareCoverageEndDateDay);
    message.emergencyContactName !== undefined && (obj.emergencyContactName = message.emergencyContactName);
    message.creditCardType !== undefined && (obj.creditCardType = message.creditCardType);
    message.otherGroupCoveragePolicyId !== undefined &&
      (obj.otherGroupCoveragePolicyId = message.otherGroupCoveragePolicyId);
    message.otherGroupCoverageIncludesPrescriptionDrugCoverage !== undefined &&
      (obj.otherGroupCoverageIncludesPrescriptionDrugCoverage =
        message.otherGroupCoverageIncludesPrescriptionDrugCoverage);
    message.parameters !== undefined && (obj.parameters = message.parameters);
    message.emergencyContactRelationship !== undefined &&
      (obj.emergencyContactRelationship = message.emergencyContactRelationship);
    message.premiumDirectPay !== undefined && (obj.premiumDirectPay = message.premiumDirectPay);
    message.otherGroupCoverageStartDateDay !== undefined &&
      (obj.otherGroupCoverageStartDateDay = message.otherGroupCoverageStartDateDay);
    message.longTerm !== undefined && (obj.longTerm = message.longTerm);
    message.otherDrugCoverageMemberId !== undefined &&
      (obj.otherDrugCoverageMemberId = message.otherDrugCoverageMemberId);
    message.otherGroupCoverageEndDate !== undefined &&
      (obj.otherGroupCoverageEndDate = message.otherGroupCoverageEndDate);
    message.ltcAdmitDateDay !== undefined && (obj.ltcAdmitDateDay = message.ltcAdmitDateDay);
    message.dsnpVerificationCode !== undefined && (obj.dsnpVerificationCode = message.dsnpVerificationCode);
    message.hasOtherMedicareCoverage !== undefined && (obj.hasOtherMedicareCoverage = message.hasOtherMedicareCoverage);
    message.otherGroupCoverageAddressFips !== undefined &&
      (obj.otherGroupCoverageAddressFips = message.otherGroupCoverageAddressFips);
    message.enrolledWithCarrierAlready !== undefined &&
      (obj.enrolledWithCarrierAlready = message.enrolledWithCarrierAlready);
    message.homeAddressFips !== undefined && (obj.homeAddressFips = message.homeAddressFips);
    message.ipAddress !== undefined && (obj.ipAddress = message.ipAddress);
    message.needsContinuityOfCare !== undefined && (obj.needsContinuityOfCare = message.needsContinuityOfCare);
    message.dateOfBirthYear !== undefined && (obj.dateOfBirthYear = message.dateOfBirthYear);
    message.ltcAddressZip !== undefined && (obj.ltcAddressZip = message.ltcAddressZip);
    message.currentMemberId !== undefined && (obj.currentMemberId = message.currentMemberId);
    message.otherGroupCoverageAddressCity !== undefined &&
      (obj.otherGroupCoverageAddressCity = message.otherGroupCoverageAddressCity);
    message.creditCardNumber !== undefined && (obj.creditCardNumber = message.creditCardNumber);
    message.ltcAddressLine1 !== undefined && (obj.ltcAddressLine1 = message.ltcAddressLine1);
    message.bankAccountType !== undefined && (obj.bankAccountType = message.bankAccountType);
    message.otherMedicareCoverageStillCovered !== undefined &&
      (obj.otherMedicareCoverageStillCovered = message.otherMedicareCoverageStillCovered);
    message.isCurrentMember !== undefined && (obj.isCurrentMember = message.isCurrentMember);
    message.bankAccountHolderName !== undefined && (obj.bankAccountHolderName = message.bankAccountHolderName);
    message.alternatePhoneNumber !== undefined && (obj.alternatePhoneNumber = message.alternatePhoneNumber);
    message.dateOfBirthDay !== undefined && (obj.dateOfBirthDay = message.dateOfBirthDay);
    message.otherGroupCoverageAddressLine2 !== undefined &&
      (obj.otherGroupCoverageAddressLine2 = message.otherGroupCoverageAddressLine2);
    message.otherMedicareCoverageStartDateMonth !== undefined &&
      (obj.otherMedicareCoverageStartDateMonth = message.otherMedicareCoverageStartDateMonth);
    message.otherMedicareCoverageDroppingMedSupp !== undefined &&
      (obj.otherMedicareCoverageDroppingMedSupp = message.otherMedicareCoverageDroppingMedSupp);
    message.otherGroupCoverageCarrierName !== undefined &&
      (obj.otherGroupCoverageCarrierName = message.otherGroupCoverageCarrierName);
    message.sunFirePlanId !== undefined && (obj.sunFirePlanId = message.sunFirePlanId);
    message.medicaidAddBenefits !== undefined && (obj.medicaidAddBenefits = message.medicaidAddBenefits);
    message.otherMedicareCoveragePolicyId !== undefined &&
      (obj.otherMedicareCoveragePolicyId = message.otherMedicareCoveragePolicyId);
    message.otherMedicareCoverageFirstTime !== undefined &&
      (obj.otherMedicareCoverageFirstTime = message.otherMedicareCoverageFirstTime);
    message.otherMedicareCoverageName !== undefined &&
      (obj.otherMedicareCoverageName = message.otherMedicareCoverageName);
    message.otherMedicareCoverageStartDateDay !== undefined &&
      (obj.otherMedicareCoverageStartDateDay = message.otherMedicareCoverageStartDateDay);
    message.workStatus !== undefined && (obj.workStatus = message.workStatus);
    message.creditCardHolder !== undefined && (obj.creditCardHolder = message.creditCardHolder);
    message.paymentType2 !== undefined && (obj.paymentType2 = message.paymentType2);
    message.pcpId !== undefined && (obj.pcpId = message.pcpId);
    message.ltcAdmitDateYear !== undefined && (obj.ltcAdmitDateYear = message.ltcAdmitDateYear);
    message.marketingId !== undefined && (obj.marketingId = message.marketingId);
    message.county !== undefined && (obj.county = message.county);
    message.hasCopd !== undefined && (obj.hasCopd = message.hasCopd);
    message.copdHasEmphysema !== undefined && (obj.copdHasEmphysema = message.copdHasEmphysema);
    message.copdTakesDrugsForBreathing !== undefined &&
      (obj.copdTakesDrugsForBreathing = message.copdTakesDrugsForBreathing);
    message.copdHasAsthma !== undefined && (obj.copdHasAsthma = message.copdHasAsthma);
    message.copdHasDifficultyBreathing !== undefined &&
      (obj.copdHasDifficultyBreathing = message.copdHasDifficultyBreathing);
    message.copdNeedsOxygenSupport !== undefined && (obj.copdNeedsOxygenSupport = message.copdNeedsOxygenSupport);
    message.hasChf !== undefined && (obj.hasChf = message.hasChf);
    message.chfHasBodyFluid !== undefined && (obj.chfHasBodyFluid = message.chfHasBodyFluid);
    message.chfTakesDrugsForLungFluid !== undefined &&
      (obj.chfTakesDrugsForLungFluid = message.chfTakesDrugsForLungFluid);
    message.chfHasLungFluid !== undefined && (obj.chfHasLungFluid = message.chfHasLungFluid);
    message.hasDiabetes !== undefined && (obj.hasDiabetes = message.hasDiabetes);
    message.diabetesChecksBloodSugar !== undefined && (obj.diabetesChecksBloodSugar = message.diabetesChecksBloodSugar);
    message.diabetesTakesDrugsForBloodSugar !== undefined &&
      (obj.diabetesTakesDrugsForBloodSugar = message.diabetesTakesDrugsForBloodSugar);
    message.diabetesHasHighBloodSugar !== undefined &&
      (obj.diabetesHasHighBloodSugar = message.diabetesHasHighBloodSugar);
    message.hasEsrd !== undefined && (obj.hasEsrd = message.hasEsrd);
    message.esrd !== undefined && (obj.esrd = message.esrd);
    message.hadSuccessfulTransplant !== undefined && (obj.hadSuccessfulTransplant = message.hadSuccessfulTransplant);
    message.requiresRegularDialysis !== undefined && (obj.requiresRegularDialysis = message.requiresRegularDialysis);
    message.dialysisCenterName !== undefined && (obj.dialysisCenterName = message.dialysisCenterName);
    message.dialysisCid !== undefined && (obj.dialysisCid = message.dialysisCid);
    message.hasCvd !== undefined && (obj.hasCvd = message.hasCvd);
    message.cvdHasChestOrLegPain !== undefined && (obj.cvdHasChestOrLegPain = message.cvdHasChestOrLegPain);
    message.cvdTakesDrugsForHeart !== undefined && (obj.cvdTakesDrugsForHeart = message.cvdTakesDrugsForHeart);
    message.cvdHadHeartAttack !== undefined && (obj.cvdHadHeartAttack = message.cvdHadHeartAttack);
    message.hasCardiovascularDisorder !== undefined &&
      (obj.hasCardiovascularDisorder = message.hasCardiovascularDisorder);
    message.isInLongTermCareFacility !== undefined && (obj.isInLongTermCareFacility = message.isInLongTermCareFacility);
    message.medicarePartADate !== undefined && (obj.medicarePartADate = message.medicarePartADate);
    message.medicarePartBDate !== undefined && (obj.medicarePartBDate = message.medicarePartBDate);
    message.oldPartA !== undefined && (obj.oldPartA = message.oldPartA);
    message.oldPartB !== undefined && (obj.oldPartB = message.oldPartB);
    message.isOnMedicaid !== undefined && (obj.isOnMedicaid = message.isOnMedicaid);
    message.stateMedicaid !== undefined && (obj.stateMedicaid = message.stateMedicaid);
    message.medicaidNumber !== undefined && (obj.medicaidNumber = message.medicaidNumber);
    message.lastNote !== undefined && (obj.lastNote = message.lastNote);
    message.latitude !== undefined && (obj.latitude = message.latitude);
    message.longitude !== undefined && (obj.longitude = message.longitude);
    message.agentId !== undefined && (obj.agentId = message.agentId);
    message.agent !== undefined && (obj.agent = message.agent ? Sunfire_Agent.toJSON(message.agent) : undefined);
    message.consumer !== undefined &&
      (obj.consumer = message.consumer ? Sunfire_Consumer.toJSON(message.consumer) : undefined);
    message.contactId !== undefined && (obj.contactId = message.contactId);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.phone !== undefined && (obj.phone = message.phone);
    message.email !== undefined && (obj.email = message.email);
    message.gender !== undefined && (obj.gender = message.gender);
    message.hicn !== undefined && (obj.hicn = message.hicn);
    message.medicareNumber !== undefined && (obj.medicareNumber = message.medicareNumber);
    message.additionalEmails !== undefined && (obj.additionalEmails = message.additionalEmails);
    message.additionalPhones !== undefined && (obj.additionalPhones = message.additionalPhones);
    message.address !== undefined && (obj.address = message.address);
    message.street !== undefined && (obj.street = message.street);
    message.city !== undefined && (obj.city = message.city);
    message.state !== undefined && (obj.state = message.state);
    message.zip !== undefined && (obj.zip = message.zip);
    message.birthDate !== undefined && (obj.birthDate = message.birthDate);
    return obj;
  },

  create<I extends Exact<DeepPartial<Sunfire_Plan>, I>>(base?: I): Sunfire_Plan {
    return Sunfire_Plan.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Sunfire_Plan>, I>>(object: I): Sunfire_Plan {
    const message = createBaseSunfire_Plan();
    message.id = object.id ?? "";
    message.contractId = object.contractId ?? undefined;
    message.carrierName = object.carrierName ?? undefined;
    message.planName = object.planName ?? undefined;
    message.carrierId = object.carrierId ?? undefined;
    message.planId = object.planId ?? undefined;
    message.enrollmentPlanYear = object.enrollmentPlanYear ?? undefined;
    message.planYear = object.planYear ?? undefined;
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.appSubmitDate = object.appSubmitDate ?? undefined;
    message.submitTime = object.submitTime ?? undefined;
    message.primaryCarePhysician = object.primaryCarePhysician ?? undefined;
    message.pcpName = object.pcpName ?? undefined;
    message.electionPeriod = object.electionPeriod ?? undefined;
    message.sepReasonCode = object.sepReasonCode ?? undefined;
    message.cmsPlanId = object.cmsPlanId ?? undefined;
    message.cmsContractId = object.cmsContractId ?? undefined;
    message.planCarrierId = object.planCarrierId ?? undefined;
    message.confirmationNumber = object.confirmationNumber ?? undefined;
    message.writingId = object.writingId ?? undefined;
    message.heapAgencyAffiliation = object.heapAgencyAffiliation ?? undefined;
    message.cmsSegmentId = object.cmsSegmentId ?? undefined;
    message.genderSpecified = object.genderSpecified ?? undefined;
    message.poaAddressCity = object.poaAddressCity ?? undefined;
    message.poaAddressCountyName = object.poaAddressCountyName ?? undefined;
    message.replacingOtherMedicareCoverage = object.replacingOtherMedicareCoverage ?? undefined;
    message.otherGroupCoverageAddressState = object.otherGroupCoverageAddressState ?? undefined;
    message.ltcAddressLine2 = object.ltcAddressLine2 ?? undefined;
    message.ltcAddressCountyName = object.ltcAddressCountyName ?? undefined;
    message.otherGroupCoverageAddressCountyName = object.otherGroupCoverageAddressCountyName ?? undefined;
    message.ltcAddressFips = object.ltcAddressFips ?? undefined;
    message.premiumDeducted = object.premiumDeducted ?? undefined;
    message.ltcAddressState = object.ltcAddressState ?? undefined;
    message.canEmailTheEnrollee = object.canEmailTheEnrollee ?? undefined;
    message.creditCardExpiration = object.creditCardExpiration ?? undefined;
    message.siteId = object.siteId ?? undefined;
    message.premiumWithhold = object.premiumWithhold ?? undefined;
    message.dateOfBirthMonth = object.dateOfBirthMonth ?? undefined;
    message.ltcName = object.ltcName ?? undefined;
    message.pcpIsEstablishedPatient = object.pcpIsEstablishedPatient ?? undefined;
    message.otherGroupCoverageBeingReplaced = object.otherGroupCoverageBeingReplaced ?? undefined;
    message.ltcAddressCity = object.ltcAddressCity ?? undefined;
    message.otherDrugCoverageMemberName = object.otherDrugCoverageMemberName ?? undefined;
    message.language = object.language ?? undefined;
    message.oldSubmitDate = object.oldSubmitDate ?? undefined;
    message.bankAccountNumber = object.bankAccountNumber ?? undefined;
    message.sfId = object.sfId ?? undefined;
    message.otherGroupCoverageAddressLine1 = object.otherGroupCoverageAddressLine1 ?? undefined;
    message.otherDrugCoveragePolicyId = object.otherDrugCoveragePolicyId ?? undefined;
    message.otherGroupCoverageEndDateMonth = object.otherGroupCoverageEndDateMonth ?? undefined;
    message.otherMedicareCoverageStartDateYear = object.otherMedicareCoverageStartDateYear ?? undefined;
    message.pcpAddress = object.pcpAddress ?? undefined;
    message.otherGroupCoverageStartDateYear = object.otherGroupCoverageStartDateYear ?? undefined;
    message.medicaidPaidPremium = object.medicaidPaidPremium ?? undefined;
    message.otherGroupCoverageAddressZip = object.otherGroupCoverageAddressZip ?? undefined;
    message.emergencyContactPhone = object.emergencyContactPhone ?? undefined;
    message.otherMedicareCoverageEndDateYear = object.otherMedicareCoverageEndDateYear ?? undefined;
    message.otherGroupCoverageStartDateMonth = object.otherGroupCoverageStartDateMonth ?? undefined;
    message.otherCoverage = object.otherCoverage ?? undefined;
    message.ltcPhone = object.ltcPhone ?? undefined;
    message.hasOtherDrugCoverage = object.hasOtherDrugCoverage ?? undefined;
    message.otherGroupCoverageEndDateDay = object.otherGroupCoverageEndDateDay ?? undefined;
    message.nephrologistName = object.nephrologistName ?? undefined;
    message.ethnicity = object.ethnicity ?? undefined;
    message.otherMedicareCoverageEndDateMonth = object.otherMedicareCoverageEndDateMonth ?? undefined;
    message.ltcAdmitDateMonth = object.ltcAdmitDateMonth ?? undefined;
    message.applicantEsignature = object.applicantEsignature ?? undefined;
    message.paymentType = object.paymentType ?? undefined;
    message.dateOfBirth = object.dateOfBirth ?? undefined;
    message.currentPlan = object.currentPlan ?? undefined;
    message.planType = object.planType ?? undefined;
    message.hasOtherGroupCoverage = object.hasOtherGroupCoverage ?? undefined;
    message.wishToDesignatePcp = object.wishToDesignatePcp ?? undefined;
    message.otherMedicareCoverageEndDateDay = object.otherMedicareCoverageEndDateDay ?? undefined;
    message.emergencyContactName = object.emergencyContactName ?? undefined;
    message.creditCardType = object.creditCardType ?? undefined;
    message.otherGroupCoveragePolicyId = object.otherGroupCoveragePolicyId ?? undefined;
    message.otherGroupCoverageIncludesPrescriptionDrugCoverage =
      object.otherGroupCoverageIncludesPrescriptionDrugCoverage ?? undefined;
    message.parameters = object.parameters ?? undefined;
    message.emergencyContactRelationship = object.emergencyContactRelationship ?? undefined;
    message.premiumDirectPay = object.premiumDirectPay ?? undefined;
    message.otherGroupCoverageStartDateDay = object.otherGroupCoverageStartDateDay ?? undefined;
    message.longTerm = object.longTerm ?? undefined;
    message.otherDrugCoverageMemberId = object.otherDrugCoverageMemberId ?? undefined;
    message.otherGroupCoverageEndDate = object.otherGroupCoverageEndDate ?? undefined;
    message.ltcAdmitDateDay = object.ltcAdmitDateDay ?? undefined;
    message.dsnpVerificationCode = object.dsnpVerificationCode ?? undefined;
    message.hasOtherMedicareCoverage = object.hasOtherMedicareCoverage ?? undefined;
    message.otherGroupCoverageAddressFips = object.otherGroupCoverageAddressFips ?? undefined;
    message.enrolledWithCarrierAlready = object.enrolledWithCarrierAlready ?? undefined;
    message.homeAddressFips = object.homeAddressFips ?? undefined;
    message.ipAddress = object.ipAddress ?? undefined;
    message.needsContinuityOfCare = object.needsContinuityOfCare ?? undefined;
    message.dateOfBirthYear = object.dateOfBirthYear ?? undefined;
    message.ltcAddressZip = object.ltcAddressZip ?? undefined;
    message.currentMemberId = object.currentMemberId ?? undefined;
    message.otherGroupCoverageAddressCity = object.otherGroupCoverageAddressCity ?? undefined;
    message.creditCardNumber = object.creditCardNumber ?? undefined;
    message.ltcAddressLine1 = object.ltcAddressLine1 ?? undefined;
    message.bankAccountType = object.bankAccountType ?? undefined;
    message.otherMedicareCoverageStillCovered = object.otherMedicareCoverageStillCovered ?? undefined;
    message.isCurrentMember = object.isCurrentMember ?? undefined;
    message.bankAccountHolderName = object.bankAccountHolderName ?? undefined;
    message.alternatePhoneNumber = object.alternatePhoneNumber ?? undefined;
    message.dateOfBirthDay = object.dateOfBirthDay ?? undefined;
    message.otherGroupCoverageAddressLine2 = object.otherGroupCoverageAddressLine2 ?? undefined;
    message.otherMedicareCoverageStartDateMonth = object.otherMedicareCoverageStartDateMonth ?? undefined;
    message.otherMedicareCoverageDroppingMedSupp = object.otherMedicareCoverageDroppingMedSupp ?? undefined;
    message.otherGroupCoverageCarrierName = object.otherGroupCoverageCarrierName ?? undefined;
    message.sunFirePlanId = object.sunFirePlanId ?? undefined;
    message.medicaidAddBenefits = object.medicaidAddBenefits ?? undefined;
    message.otherMedicareCoveragePolicyId = object.otherMedicareCoveragePolicyId ?? undefined;
    message.otherMedicareCoverageFirstTime = object.otherMedicareCoverageFirstTime ?? undefined;
    message.otherMedicareCoverageName = object.otherMedicareCoverageName ?? undefined;
    message.otherMedicareCoverageStartDateDay = object.otherMedicareCoverageStartDateDay ?? undefined;
    message.workStatus = object.workStatus ?? undefined;
    message.creditCardHolder = object.creditCardHolder ?? undefined;
    message.paymentType2 = object.paymentType2 ?? undefined;
    message.pcpId = object.pcpId ?? undefined;
    message.ltcAdmitDateYear = object.ltcAdmitDateYear ?? undefined;
    message.marketingId = object.marketingId ?? undefined;
    message.county = object.county ?? undefined;
    message.hasCopd = object.hasCopd ?? undefined;
    message.copdHasEmphysema = object.copdHasEmphysema ?? undefined;
    message.copdTakesDrugsForBreathing = object.copdTakesDrugsForBreathing ?? undefined;
    message.copdHasAsthma = object.copdHasAsthma ?? undefined;
    message.copdHasDifficultyBreathing = object.copdHasDifficultyBreathing ?? undefined;
    message.copdNeedsOxygenSupport = object.copdNeedsOxygenSupport ?? undefined;
    message.hasChf = object.hasChf ?? undefined;
    message.chfHasBodyFluid = object.chfHasBodyFluid ?? undefined;
    message.chfTakesDrugsForLungFluid = object.chfTakesDrugsForLungFluid ?? undefined;
    message.chfHasLungFluid = object.chfHasLungFluid ?? undefined;
    message.hasDiabetes = object.hasDiabetes ?? undefined;
    message.diabetesChecksBloodSugar = object.diabetesChecksBloodSugar ?? undefined;
    message.diabetesTakesDrugsForBloodSugar = object.diabetesTakesDrugsForBloodSugar ?? undefined;
    message.diabetesHasHighBloodSugar = object.diabetesHasHighBloodSugar ?? undefined;
    message.hasEsrd = object.hasEsrd ?? undefined;
    message.esrd = object.esrd ?? undefined;
    message.hadSuccessfulTransplant = object.hadSuccessfulTransplant ?? undefined;
    message.requiresRegularDialysis = object.requiresRegularDialysis ?? undefined;
    message.dialysisCenterName = object.dialysisCenterName ?? undefined;
    message.dialysisCid = object.dialysisCid ?? undefined;
    message.hasCvd = object.hasCvd ?? undefined;
    message.cvdHasChestOrLegPain = object.cvdHasChestOrLegPain ?? undefined;
    message.cvdTakesDrugsForHeart = object.cvdTakesDrugsForHeart ?? undefined;
    message.cvdHadHeartAttack = object.cvdHadHeartAttack ?? undefined;
    message.hasCardiovascularDisorder = object.hasCardiovascularDisorder ?? undefined;
    message.isInLongTermCareFacility = object.isInLongTermCareFacility ?? undefined;
    message.medicarePartADate = object.medicarePartADate ?? undefined;
    message.medicarePartBDate = object.medicarePartBDate ?? undefined;
    message.oldPartA = object.oldPartA ?? undefined;
    message.oldPartB = object.oldPartB ?? undefined;
    message.isOnMedicaid = object.isOnMedicaid ?? undefined;
    message.stateMedicaid = object.stateMedicaid ?? undefined;
    message.medicaidNumber = object.medicaidNumber ?? undefined;
    message.lastNote = object.lastNote ?? undefined;
    message.latitude = object.latitude ?? undefined;
    message.longitude = object.longitude ?? undefined;
    message.agentId = object.agentId ?? undefined;
    message.agent = (object.agent !== undefined && object.agent !== null)
      ? Sunfire_Agent.fromPartial(object.agent)
      : undefined;
    message.consumer = (object.consumer !== undefined && object.consumer !== null)
      ? Sunfire_Consumer.fromPartial(object.consumer)
      : undefined;
    message.contactId = object.contactId ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.phone = object.phone ?? undefined;
    message.email = object.email ?? undefined;
    message.gender = object.gender ?? undefined;
    message.hicn = object.hicn ?? undefined;
    message.medicareNumber = object.medicareNumber ?? undefined;
    message.additionalEmails = object.additionalEmails ?? undefined;
    message.additionalPhones = object.additionalPhones ?? undefined;
    message.address = object.address ?? undefined;
    message.street = object.street ?? undefined;
    message.city = object.city ?? undefined;
    message.state = object.state ?? undefined;
    message.zip = object.zip ?? undefined;
    message.birthDate = object.birthDate ?? undefined;
    return message;
  },
};

function createBaseSunfire_Consumer(): Sunfire_Consumer {
  return {
    id: "",
    contactId: undefined,
    firstName: undefined,
    middleInitial: undefined,
    lastName: undefined,
    phone: undefined,
    email: undefined,
    gender: undefined,
    hicn: undefined,
    medicareNumber: undefined,
    additionalEmails: [],
    additionalPhones: [],
    address: undefined,
    street: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    birthDate: undefined,
  };
}

export const Sunfire_Consumer = {
  encode(message: Sunfire_Consumer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.contactId !== undefined) {
      writer.uint32(18).string(message.contactId);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.middleInitial !== undefined) {
      writer.uint32(34).string(message.middleInitial);
    }
    if (message.lastName !== undefined) {
      writer.uint32(42).string(message.lastName);
    }
    if (message.phone !== undefined) {
      writer.uint32(50).string(message.phone);
    }
    if (message.email !== undefined) {
      writer.uint32(58).string(message.email);
    }
    if (message.gender !== undefined) {
      writer.uint32(66).string(message.gender);
    }
    if (message.hicn !== undefined) {
      writer.uint32(74).string(message.hicn);
    }
    if (message.medicareNumber !== undefined) {
      writer.uint32(82).string(message.medicareNumber);
    }
    for (const v of message.additionalEmails) {
      writer.uint32(90).string(v!);
    }
    for (const v of message.additionalPhones) {
      writer.uint32(98).string(v!);
    }
    if (message.address !== undefined) {
      writer.uint32(106).string(message.address);
    }
    if (message.street !== undefined) {
      writer.uint32(114).string(message.street);
    }
    if (message.city !== undefined) {
      writer.uint32(122).string(message.city);
    }
    if (message.state !== undefined) {
      writer.uint32(130).string(message.state);
    }
    if (message.zip !== undefined) {
      writer.uint32(138).string(message.zip);
    }
    if (message.birthDate !== undefined) {
      writer.uint32(146).string(message.birthDate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Sunfire_Consumer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSunfire_Consumer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.contactId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.middleInitial = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.email = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.hicn = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.medicareNumber = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.additionalEmails.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.additionalPhones.push(reader.string());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.address = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.street = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.city = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.state = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.zip = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.birthDate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Sunfire_Consumer {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      contactId: isSet(object.contactId) ? String(object.contactId) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      middleInitial: isSet(object.middleInitial) ? String(object.middleInitial) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      gender: isSet(object.gender) ? String(object.gender) : undefined,
      hicn: isSet(object.hicn) ? String(object.hicn) : undefined,
      medicareNumber: isSet(object.medicareNumber) ? String(object.medicareNumber) : undefined,
      additionalEmails: Array.isArray(object?.additionalEmails)
        ? object.additionalEmails.map((e: any) => String(e))
        : [],
      additionalPhones: Array.isArray(object?.additionalPhones)
        ? object.additionalPhones.map((e: any) => String(e))
        : [],
      address: isSet(object.address) ? String(object.address) : undefined,
      street: isSet(object.street) ? String(object.street) : undefined,
      city: isSet(object.city) ? String(object.city) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      zip: isSet(object.zip) ? String(object.zip) : undefined,
      birthDate: isSet(object.birthDate) ? String(object.birthDate) : undefined,
    };
  },

  toJSON(message: Sunfire_Consumer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.contactId !== undefined && (obj.contactId = message.contactId);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.middleInitial !== undefined && (obj.middleInitial = message.middleInitial);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.phone !== undefined && (obj.phone = message.phone);
    message.email !== undefined && (obj.email = message.email);
    message.gender !== undefined && (obj.gender = message.gender);
    message.hicn !== undefined && (obj.hicn = message.hicn);
    message.medicareNumber !== undefined && (obj.medicareNumber = message.medicareNumber);
    if (message.additionalEmails) {
      obj.additionalEmails = message.additionalEmails.map((e) => e);
    } else {
      obj.additionalEmails = [];
    }
    if (message.additionalPhones) {
      obj.additionalPhones = message.additionalPhones.map((e) => e);
    } else {
      obj.additionalPhones = [];
    }
    message.address !== undefined && (obj.address = message.address);
    message.street !== undefined && (obj.street = message.street);
    message.city !== undefined && (obj.city = message.city);
    message.state !== undefined && (obj.state = message.state);
    message.zip !== undefined && (obj.zip = message.zip);
    message.birthDate !== undefined && (obj.birthDate = message.birthDate);
    return obj;
  },

  create<I extends Exact<DeepPartial<Sunfire_Consumer>, I>>(base?: I): Sunfire_Consumer {
    return Sunfire_Consumer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Sunfire_Consumer>, I>>(object: I): Sunfire_Consumer {
    const message = createBaseSunfire_Consumer();
    message.id = object.id ?? "";
    message.contactId = object.contactId ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.middleInitial = object.middleInitial ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.phone = object.phone ?? undefined;
    message.email = object.email ?? undefined;
    message.gender = object.gender ?? undefined;
    message.hicn = object.hicn ?? undefined;
    message.medicareNumber = object.medicareNumber ?? undefined;
    message.additionalEmails = object.additionalEmails?.map((e) => e) || [];
    message.additionalPhones = object.additionalPhones?.map((e) => e) || [];
    message.address = object.address ?? undefined;
    message.street = object.street ?? undefined;
    message.city = object.city ?? undefined;
    message.state = object.state ?? undefined;
    message.zip = object.zip ?? undefined;
    message.birthDate = object.birthDate ?? undefined;
    return message;
  },
};

function createBaseSunfire_Agent(): Sunfire_Agent {
  return {
    id: undefined,
    agentId: undefined,
    npn: undefined,
    name: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    username: undefined,
  };
}

export const Sunfire_Agent = {
  encode(message: Sunfire_Agent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.agentId !== undefined) {
      writer.uint32(18).string(message.agentId);
    }
    if (message.npn !== undefined) {
      writer.uint32(26).string(message.npn);
    }
    if (message.name !== undefined) {
      writer.uint32(34).string(message.name);
    }
    if (message.firstName !== undefined) {
      writer.uint32(42).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(50).string(message.lastName);
    }
    if (message.email !== undefined) {
      writer.uint32(58).string(message.email);
    }
    if (message.username !== undefined) {
      writer.uint32(66).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Sunfire_Agent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSunfire_Agent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agentId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.email = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Sunfire_Agent {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      agentId: isSet(object.agentId) ? String(object.agentId) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      username: isSet(object.username) ? String(object.username) : undefined,
    };
  },

  toJSON(message: Sunfire_Agent): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.agentId !== undefined && (obj.agentId = message.agentId);
    message.npn !== undefined && (obj.npn = message.npn);
    message.name !== undefined && (obj.name = message.name);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.email !== undefined && (obj.email = message.email);
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<Sunfire_Agent>, I>>(base?: I): Sunfire_Agent {
    return Sunfire_Agent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Sunfire_Agent>, I>>(object: I): Sunfire_Agent {
    const message = createBaseSunfire_Agent();
    message.id = object.id ?? undefined;
    message.agentId = object.agentId ?? undefined;
    message.npn = object.npn ?? undefined;
    message.name = object.name ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.email = object.email ?? undefined;
    message.username = object.username ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
