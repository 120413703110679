/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";
import { Note } from "./lead-note";
import {
  Address,
  DateContainer,
  Gender,
  genderFromJSON,
  genderToJSON,
  IdentifierContainer,
  Source,
  sourceFromJSON,
  sourceToJSON,
} from "./misc";

export const protobufPackage = "";

export enum MarketType {
  NO_MARKET_TYPE = 0,
  MEDICARE = 1,
  UNRECOGNIZED = -1,
}

export function marketTypeFromJSON(object: any): MarketType {
  switch (object) {
    case 0:
    case "NO_MARKET_TYPE":
      return MarketType.NO_MARKET_TYPE;
    case 1:
    case "MEDICARE":
      return MarketType.MEDICARE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarketType.UNRECOGNIZED;
  }
}

export function marketTypeToJSON(object: MarketType): string {
  switch (object) {
    case MarketType.NO_MARKET_TYPE:
      return "NO_MARKET_TYPE";
    case MarketType.MEDICARE:
      return "MEDICARE";
    case MarketType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum LeadType {
  NO_LEAD_TYPE = 0,
  DIRECT_MAIL = 1,
  UNRECOGNIZED = -1,
}

export function leadTypeFromJSON(object: any): LeadType {
  switch (object) {
    case 0:
    case "NO_LEAD_TYPE":
      return LeadType.NO_LEAD_TYPE;
    case 1:
    case "DIRECT_MAIL":
      return LeadType.DIRECT_MAIL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LeadType.UNRECOGNIZED;
  }
}

export function leadTypeToJSON(object: LeadType): string {
  switch (object) {
    case LeadType.NO_LEAD_TYPE:
      return "NO_LEAD_TYPE";
    case LeadType.DIRECT_MAIL:
      return "DIRECT_MAIL";
    case LeadType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Consumer {
  id?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  nameSuffix?: string | undefined;
  email?: string | undefined;
  phone?: string | undefined;
  address?: Address | undefined;
  birthDate?: DateContainer | undefined;
  ssn?: string | undefined;
  gender?: Gender | undefined;
  okToEmail?: boolean | undefined;
  profileImage?: string | undefined;
  leadDate?: string | undefined;
  marketType?: MarketType | undefined;
  leadType?: LeadType | undefined;
  agency?: Consumer_Agency | undefined;
  campaign?: Consumer_Campaign | undefined;
  dialer?: Consumer_Dialer | undefined;
  leadList?: Consumer_LeadList | undefined;
  leadDateTimestamp?: number | undefined;
  medicare?: Consumer_Medicare | undefined;
  leadSource?: Consumer_LeadSource | undefined;
  veteranStatus?: Consumer_VeteranStatus | undefined;
  workStatus?: Consumer_WorkStatus | undefined;
  benefits: Benefit[];
  language?: string | undefined;
  createdAt?: number | undefined;
  timezone?: string | undefined;
  identifiers?: IdentifierContainer | undefined;
  source?: Source | undefined;
  sourceCustom?: string | undefined;
  phoneInvalid?: boolean | undefined;
  notes?:
    | Note
    | undefined;
  /**
   * deprecated
   *
   * @deprecated
   */
  dateOfBirth?: Date | undefined;
}

export enum Consumer_LeadSource {
  NO_LEAD_SOURCE = 0,
  UNRECOGNIZED = -1,
}

export function consumer_LeadSourceFromJSON(object: any): Consumer_LeadSource {
  switch (object) {
    case 0:
    case "NO_LEAD_SOURCE":
      return Consumer_LeadSource.NO_LEAD_SOURCE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_LeadSource.UNRECOGNIZED;
  }
}

export function consumer_LeadSourceToJSON(object: Consumer_LeadSource): string {
  switch (object) {
    case Consumer_LeadSource.NO_LEAD_SOURCE:
      return "NO_LEAD_SOURCE";
    case Consumer_LeadSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Consumer_VeteranStatus {
  VS_NO_VETERAN_STATUS = 0,
  VS_NO_VETERAN = 1,
  VS_VETERAN = 2,
  VS_SPOUSE = 3,
  VS_DEPENDENT = 4,
  UNRECOGNIZED = -1,
}

export function consumer_VeteranStatusFromJSON(object: any): Consumer_VeteranStatus {
  switch (object) {
    case 0:
    case "VS_NO_VETERAN_STATUS":
      return Consumer_VeteranStatus.VS_NO_VETERAN_STATUS;
    case 1:
    case "VS_NO_VETERAN":
      return Consumer_VeteranStatus.VS_NO_VETERAN;
    case 2:
    case "VS_VETERAN":
      return Consumer_VeteranStatus.VS_VETERAN;
    case 3:
    case "VS_SPOUSE":
      return Consumer_VeteranStatus.VS_SPOUSE;
    case 4:
    case "VS_DEPENDENT":
      return Consumer_VeteranStatus.VS_DEPENDENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_VeteranStatus.UNRECOGNIZED;
  }
}

export function consumer_VeteranStatusToJSON(object: Consumer_VeteranStatus): string {
  switch (object) {
    case Consumer_VeteranStatus.VS_NO_VETERAN_STATUS:
      return "VS_NO_VETERAN_STATUS";
    case Consumer_VeteranStatus.VS_NO_VETERAN:
      return "VS_NO_VETERAN";
    case Consumer_VeteranStatus.VS_VETERAN:
      return "VS_VETERAN";
    case Consumer_VeteranStatus.VS_SPOUSE:
      return "VS_SPOUSE";
    case Consumer_VeteranStatus.VS_DEPENDENT:
      return "VS_DEPENDENT";
    case Consumer_VeteranStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Consumer_WorkStatus {
  WS_NO_WORK_STATUS = 0,
  WS_EMPLOYED = 1,
  WS_UNEMPLOYED = 2,
  WS_RETIRED = 3,
  WS_DISABLED = 4,
  UNRECOGNIZED = -1,
}

export function consumer_WorkStatusFromJSON(object: any): Consumer_WorkStatus {
  switch (object) {
    case 0:
    case "WS_NO_WORK_STATUS":
      return Consumer_WorkStatus.WS_NO_WORK_STATUS;
    case 1:
    case "WS_EMPLOYED":
      return Consumer_WorkStatus.WS_EMPLOYED;
    case 2:
    case "WS_UNEMPLOYED":
      return Consumer_WorkStatus.WS_UNEMPLOYED;
    case 3:
    case "WS_RETIRED":
      return Consumer_WorkStatus.WS_RETIRED;
    case 4:
    case "WS_DISABLED":
      return Consumer_WorkStatus.WS_DISABLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_WorkStatus.UNRECOGNIZED;
  }
}

export function consumer_WorkStatusToJSON(object: Consumer_WorkStatus): string {
  switch (object) {
    case Consumer_WorkStatus.WS_NO_WORK_STATUS:
      return "WS_NO_WORK_STATUS";
    case Consumer_WorkStatus.WS_EMPLOYED:
      return "WS_EMPLOYED";
    case Consumer_WorkStatus.WS_UNEMPLOYED:
      return "WS_UNEMPLOYED";
    case Consumer_WorkStatus.WS_RETIRED:
      return "WS_RETIRED";
    case Consumer_WorkStatus.WS_DISABLED:
      return "WS_DISABLED";
    case Consumer_WorkStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Consumer_Dialer {
  resultCategory?: Consumer_Dialer_ResultCategory | undefined;
  result?: Consumer_Dialer_Result | undefined;
  resultCategoryAgent?: Consumer_Dialer_ResultCategory | undefined;
  resultAgent?: Consumer_Dialer_Result | undefined;
  lastCallTry?: number | undefined;
  lastCallConnected?: number | undefined;
  lastCallEnded?: number | undefined;
  lastAddedToHopper?: number | undefined;
  lastCallState?: Consumer_Dialer_CallState | undefined;
  from?: string | undefined;
  totalCalls?: number | undefined;
  totalCallsInboundAnswered?: number | undefined;
  lastCallInboundAnswered?: number | undefined;
  totalCallsInboundBillable?: number | undefined;
  lastCallInboundBillable?: number | undefined;
  totalCallsInbound?: number | undefined;
  lastCallInbound?: number | undefined;
  totalCallsOutbound?: number | undefined;
  lastCallOutbound?: number | undefined;
}

export enum Consumer_Dialer_ResultCategory {
  NO_STATUS_CATEGORY = 0,
  SUCCESS = 1,
  RECYCLE = 2,
  TRASH = 3,
  UNRECOGNIZED = -1,
}

export function consumer_Dialer_ResultCategoryFromJSON(object: any): Consumer_Dialer_ResultCategory {
  switch (object) {
    case 0:
    case "NO_STATUS_CATEGORY":
      return Consumer_Dialer_ResultCategory.NO_STATUS_CATEGORY;
    case 1:
    case "SUCCESS":
      return Consumer_Dialer_ResultCategory.SUCCESS;
    case 2:
    case "RECYCLE":
      return Consumer_Dialer_ResultCategory.RECYCLE;
    case 3:
    case "TRASH":
      return Consumer_Dialer_ResultCategory.TRASH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_Dialer_ResultCategory.UNRECOGNIZED;
  }
}

export function consumer_Dialer_ResultCategoryToJSON(object: Consumer_Dialer_ResultCategory): string {
  switch (object) {
    case Consumer_Dialer_ResultCategory.NO_STATUS_CATEGORY:
      return "NO_STATUS_CATEGORY";
    case Consumer_Dialer_ResultCategory.SUCCESS:
      return "SUCCESS";
    case Consumer_Dialer_ResultCategory.RECYCLE:
      return "RECYCLE";
    case Consumer_Dialer_ResultCategory.TRASH:
      return "TRASH";
    case Consumer_Dialer_ResultCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Consumer_Dialer_Result {
  NO_STATUS = 0,
  S_AGENT_TO_AGENT = 1,
  S_APP_SUBMITTED = 2,
  S_APP_SUBMITTED_DOUBLE_SALE = 3,
  S_EXISTING_CLIENT_CALLBACK = 4,
  R_CALL_BACK = 11,
  R_CLIENT_HANGUP = 12,
  R_CLIENT_HANGUP_DURING_TRANSFER = 13,
  R_CLIENT_UNWILLING_SWITCH = 14,
  R_MISSED_OPPORTUNITY = 15,
  R_DROPPED_CALL = 16,
  R_ANSWERING_MACHINE = 17,
  R_NOT_AVAILABLE = 18,
  T_CLIENT_NO_A_B = 21,
  T_CLIENT_REFUSES_GIVE_INFO = 22,
  T_DO_NOT_CALL = 23,
  T_LANGUAGE_BARRIER = 24,
  T_MEDICARE_SUPPLEMENT_PLAN = 25,
  T_NOT_DECISION_MAKER = 26,
  T_RETIREMENT_PLAN = 27,
  T_EXISTING_CLIENT = 28,
  T_NOT_INTERESTED = 29,
  T_UNDER_65 = 30,
  T_WRONG_NUMBER = 31,
  UNRECOGNIZED = -1,
}

export function consumer_Dialer_ResultFromJSON(object: any): Consumer_Dialer_Result {
  switch (object) {
    case 0:
    case "NO_STATUS":
      return Consumer_Dialer_Result.NO_STATUS;
    case 1:
    case "S_AGENT_TO_AGENT":
      return Consumer_Dialer_Result.S_AGENT_TO_AGENT;
    case 2:
    case "S_APP_SUBMITTED":
      return Consumer_Dialer_Result.S_APP_SUBMITTED;
    case 3:
    case "S_APP_SUBMITTED_DOUBLE_SALE":
      return Consumer_Dialer_Result.S_APP_SUBMITTED_DOUBLE_SALE;
    case 4:
    case "S_EXISTING_CLIENT_CALLBACK":
      return Consumer_Dialer_Result.S_EXISTING_CLIENT_CALLBACK;
    case 11:
    case "R_CALL_BACK":
      return Consumer_Dialer_Result.R_CALL_BACK;
    case 12:
    case "R_CLIENT_HANGUP":
      return Consumer_Dialer_Result.R_CLIENT_HANGUP;
    case 13:
    case "R_CLIENT_HANGUP_DURING_TRANSFER":
      return Consumer_Dialer_Result.R_CLIENT_HANGUP_DURING_TRANSFER;
    case 14:
    case "R_CLIENT_UNWILLING_SWITCH":
      return Consumer_Dialer_Result.R_CLIENT_UNWILLING_SWITCH;
    case 15:
    case "R_MISSED_OPPORTUNITY":
      return Consumer_Dialer_Result.R_MISSED_OPPORTUNITY;
    case 16:
    case "R_DROPPED_CALL":
      return Consumer_Dialer_Result.R_DROPPED_CALL;
    case 17:
    case "R_ANSWERING_MACHINE":
      return Consumer_Dialer_Result.R_ANSWERING_MACHINE;
    case 18:
    case "R_NOT_AVAILABLE":
      return Consumer_Dialer_Result.R_NOT_AVAILABLE;
    case 21:
    case "T_CLIENT_NO_A_B":
      return Consumer_Dialer_Result.T_CLIENT_NO_A_B;
    case 22:
    case "T_CLIENT_REFUSES_GIVE_INFO":
      return Consumer_Dialer_Result.T_CLIENT_REFUSES_GIVE_INFO;
    case 23:
    case "T_DO_NOT_CALL":
      return Consumer_Dialer_Result.T_DO_NOT_CALL;
    case 24:
    case "T_LANGUAGE_BARRIER":
      return Consumer_Dialer_Result.T_LANGUAGE_BARRIER;
    case 25:
    case "T_MEDICARE_SUPPLEMENT_PLAN":
      return Consumer_Dialer_Result.T_MEDICARE_SUPPLEMENT_PLAN;
    case 26:
    case "T_NOT_DECISION_MAKER":
      return Consumer_Dialer_Result.T_NOT_DECISION_MAKER;
    case 27:
    case "T_RETIREMENT_PLAN":
      return Consumer_Dialer_Result.T_RETIREMENT_PLAN;
    case 28:
    case "T_EXISTING_CLIENT":
      return Consumer_Dialer_Result.T_EXISTING_CLIENT;
    case 29:
    case "T_NOT_INTERESTED":
      return Consumer_Dialer_Result.T_NOT_INTERESTED;
    case 30:
    case "T_UNDER_65":
      return Consumer_Dialer_Result.T_UNDER_65;
    case 31:
    case "T_WRONG_NUMBER":
      return Consumer_Dialer_Result.T_WRONG_NUMBER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_Dialer_Result.UNRECOGNIZED;
  }
}

export function consumer_Dialer_ResultToJSON(object: Consumer_Dialer_Result): string {
  switch (object) {
    case Consumer_Dialer_Result.NO_STATUS:
      return "NO_STATUS";
    case Consumer_Dialer_Result.S_AGENT_TO_AGENT:
      return "S_AGENT_TO_AGENT";
    case Consumer_Dialer_Result.S_APP_SUBMITTED:
      return "S_APP_SUBMITTED";
    case Consumer_Dialer_Result.S_APP_SUBMITTED_DOUBLE_SALE:
      return "S_APP_SUBMITTED_DOUBLE_SALE";
    case Consumer_Dialer_Result.S_EXISTING_CLIENT_CALLBACK:
      return "S_EXISTING_CLIENT_CALLBACK";
    case Consumer_Dialer_Result.R_CALL_BACK:
      return "R_CALL_BACK";
    case Consumer_Dialer_Result.R_CLIENT_HANGUP:
      return "R_CLIENT_HANGUP";
    case Consumer_Dialer_Result.R_CLIENT_HANGUP_DURING_TRANSFER:
      return "R_CLIENT_HANGUP_DURING_TRANSFER";
    case Consumer_Dialer_Result.R_CLIENT_UNWILLING_SWITCH:
      return "R_CLIENT_UNWILLING_SWITCH";
    case Consumer_Dialer_Result.R_MISSED_OPPORTUNITY:
      return "R_MISSED_OPPORTUNITY";
    case Consumer_Dialer_Result.R_DROPPED_CALL:
      return "R_DROPPED_CALL";
    case Consumer_Dialer_Result.R_ANSWERING_MACHINE:
      return "R_ANSWERING_MACHINE";
    case Consumer_Dialer_Result.R_NOT_AVAILABLE:
      return "R_NOT_AVAILABLE";
    case Consumer_Dialer_Result.T_CLIENT_NO_A_B:
      return "T_CLIENT_NO_A_B";
    case Consumer_Dialer_Result.T_CLIENT_REFUSES_GIVE_INFO:
      return "T_CLIENT_REFUSES_GIVE_INFO";
    case Consumer_Dialer_Result.T_DO_NOT_CALL:
      return "T_DO_NOT_CALL";
    case Consumer_Dialer_Result.T_LANGUAGE_BARRIER:
      return "T_LANGUAGE_BARRIER";
    case Consumer_Dialer_Result.T_MEDICARE_SUPPLEMENT_PLAN:
      return "T_MEDICARE_SUPPLEMENT_PLAN";
    case Consumer_Dialer_Result.T_NOT_DECISION_MAKER:
      return "T_NOT_DECISION_MAKER";
    case Consumer_Dialer_Result.T_RETIREMENT_PLAN:
      return "T_RETIREMENT_PLAN";
    case Consumer_Dialer_Result.T_EXISTING_CLIENT:
      return "T_EXISTING_CLIENT";
    case Consumer_Dialer_Result.T_NOT_INTERESTED:
      return "T_NOT_INTERESTED";
    case Consumer_Dialer_Result.T_UNDER_65:
      return "T_UNDER_65";
    case Consumer_Dialer_Result.T_WRONG_NUMBER:
      return "T_WRONG_NUMBER";
    case Consumer_Dialer_Result.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Consumer_Dialer_CallState {
  NO_CALL_STATE = 0,
  RINGING = 1,
  CONNECTED = 2,
  FINISHED = 3,
  IN_HOPPER = 4,
  ANSWERING_MACHINE = 5,
  UNRECOGNIZED = -1,
}

export function consumer_Dialer_CallStateFromJSON(object: any): Consumer_Dialer_CallState {
  switch (object) {
    case 0:
    case "NO_CALL_STATE":
      return Consumer_Dialer_CallState.NO_CALL_STATE;
    case 1:
    case "RINGING":
      return Consumer_Dialer_CallState.RINGING;
    case 2:
    case "CONNECTED":
      return Consumer_Dialer_CallState.CONNECTED;
    case 3:
    case "FINISHED":
      return Consumer_Dialer_CallState.FINISHED;
    case 4:
    case "IN_HOPPER":
      return Consumer_Dialer_CallState.IN_HOPPER;
    case 5:
    case "ANSWERING_MACHINE":
      return Consumer_Dialer_CallState.ANSWERING_MACHINE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Consumer_Dialer_CallState.UNRECOGNIZED;
  }
}

export function consumer_Dialer_CallStateToJSON(object: Consumer_Dialer_CallState): string {
  switch (object) {
    case Consumer_Dialer_CallState.NO_CALL_STATE:
      return "NO_CALL_STATE";
    case Consumer_Dialer_CallState.RINGING:
      return "RINGING";
    case Consumer_Dialer_CallState.CONNECTED:
      return "CONNECTED";
    case Consumer_Dialer_CallState.FINISHED:
      return "FINISHED";
    case Consumer_Dialer_CallState.IN_HOPPER:
      return "IN_HOPPER";
    case Consumer_Dialer_CallState.ANSWERING_MACHINE:
      return "ANSWERING_MACHINE";
    case Consumer_Dialer_CallState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Consumer_Agency {
  id?: string | undefined;
  name?: string | undefined;
}

export interface Consumer_Campaign {
  id?: string | undefined;
  name?: string | undefined;
}

export interface Consumer_LeadList {
  id?: string | undefined;
  name?: string | undefined;
}

export interface Consumer_Medicare {
  number?: string | undefined;
}

export interface Benefit {
  id: string;
  name: string;
  active?: boolean | undefined;
}

function createBaseConsumer(): Consumer {
  return {
    id: undefined,
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    nameSuffix: undefined,
    email: undefined,
    phone: undefined,
    address: undefined,
    birthDate: undefined,
    ssn: undefined,
    gender: undefined,
    okToEmail: undefined,
    profileImage: undefined,
    leadDate: undefined,
    marketType: undefined,
    leadType: undefined,
    agency: undefined,
    campaign: undefined,
    dialer: undefined,
    leadList: undefined,
    leadDateTimestamp: undefined,
    medicare: undefined,
    leadSource: undefined,
    veteranStatus: undefined,
    workStatus: undefined,
    benefits: [],
    language: undefined,
    createdAt: undefined,
    timezone: undefined,
    identifiers: undefined,
    source: undefined,
    sourceCustom: undefined,
    phoneInvalid: undefined,
    notes: undefined,
    dateOfBirth: undefined,
  };
}

export const Consumer = {
  encode(message: Consumer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.firstName !== undefined) {
      writer.uint32(18).string(message.firstName);
    }
    if (message.middleName !== undefined) {
      writer.uint32(26).string(message.middleName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.nameSuffix !== undefined) {
      writer.uint32(42).string(message.nameSuffix);
    }
    if (message.email !== undefined) {
      writer.uint32(50).string(message.email);
    }
    if (message.phone !== undefined) {
      writer.uint32(58).string(message.phone);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(66).fork()).ldelim();
    }
    if (message.birthDate !== undefined) {
      DateContainer.encode(message.birthDate, writer.uint32(74).fork()).ldelim();
    }
    if (message.ssn !== undefined) {
      writer.uint32(90).string(message.ssn);
    }
    if (message.gender !== undefined) {
      writer.uint32(96).int32(message.gender);
    }
    if (message.okToEmail !== undefined) {
      writer.uint32(104).bool(message.okToEmail);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(114).string(message.profileImage);
    }
    if (message.leadDate !== undefined) {
      writer.uint32(122).string(message.leadDate);
    }
    if (message.marketType !== undefined) {
      writer.uint32(128).int32(message.marketType);
    }
    if (message.leadType !== undefined) {
      writer.uint32(136).int32(message.leadType);
    }
    if (message.agency !== undefined) {
      Consumer_Agency.encode(message.agency, writer.uint32(146).fork()).ldelim();
    }
    if (message.campaign !== undefined) {
      Consumer_Campaign.encode(message.campaign, writer.uint32(154).fork()).ldelim();
    }
    if (message.dialer !== undefined) {
      Consumer_Dialer.encode(message.dialer, writer.uint32(162).fork()).ldelim();
    }
    if (message.leadList !== undefined) {
      Consumer_LeadList.encode(message.leadList, writer.uint32(170).fork()).ldelim();
    }
    if (message.leadDateTimestamp !== undefined) {
      writer.uint32(176).int64(message.leadDateTimestamp);
    }
    if (message.medicare !== undefined) {
      Consumer_Medicare.encode(message.medicare, writer.uint32(186).fork()).ldelim();
    }
    if (message.leadSource !== undefined) {
      writer.uint32(192).int32(message.leadSource);
    }
    if (message.veteranStatus !== undefined) {
      writer.uint32(200).int32(message.veteranStatus);
    }
    if (message.workStatus !== undefined) {
      writer.uint32(208).int32(message.workStatus);
    }
    for (const v of message.benefits) {
      Benefit.encode(v!, writer.uint32(218).fork()).ldelim();
    }
    if (message.language !== undefined) {
      writer.uint32(226).string(message.language);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(232).int32(message.createdAt);
    }
    if (message.timezone !== undefined) {
      writer.uint32(242).string(message.timezone);
    }
    if (message.identifiers !== undefined) {
      IdentifierContainer.encode(message.identifiers, writer.uint32(250).fork()).ldelim();
    }
    if (message.source !== undefined) {
      writer.uint32(256).int32(message.source);
    }
    if (message.sourceCustom !== undefined) {
      writer.uint32(266).string(message.sourceCustom);
    }
    if (message.phoneInvalid !== undefined) {
      writer.uint32(272).bool(message.phoneInvalid);
    }
    if (message.notes !== undefined) {
      Note.encode(message.notes, writer.uint32(282).fork()).ldelim();
    }
    if (message.dateOfBirth !== undefined) {
      Timestamp.encode(toTimestamp(message.dateOfBirth), writer.uint32(722).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.middleName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nameSuffix = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.email = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.birthDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.gender = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.okToEmail = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.leadDate = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.marketType = reader.int32() as any;
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.leadType = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.agency = Consumer_Agency.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.campaign = Consumer_Campaign.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.dialer = Consumer_Dialer.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.leadList = Consumer_LeadList.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.leadDateTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.medicare = Consumer_Medicare.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.leadSource = reader.int32() as any;
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.veteranStatus = reader.int32() as any;
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.workStatus = reader.int32() as any;
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.benefits.push(Benefit.decode(reader, reader.uint32()));
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.language = reader.string();
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.createdAt = reader.int32();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.timezone = reader.string();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.identifiers = IdentifierContainer.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.sourceCustom = reader.string();
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.phoneInvalid = reader.bool();
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.notes = Note.decode(reader, reader.uint32());
          continue;
        case 90:
          if (tag !== 722) {
            break;
          }

          message.dateOfBirth = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      middleName: isSet(object.middleName) ? String(object.middleName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      nameSuffix: isSet(object.nameSuffix) ? String(object.nameSuffix) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      address: isSet(object.address) ? Address.fromJSON(object.address) : undefined,
      birthDate: isSet(object.birthDate) ? DateContainer.fromJSON(object.birthDate) : undefined,
      ssn: isSet(object.ssn) ? String(object.ssn) : undefined,
      gender: isSet(object.gender) ? genderFromJSON(object.gender) : undefined,
      okToEmail: isSet(object.okToEmail) ? Boolean(object.okToEmail) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      leadDate: isSet(object.leadDate) ? String(object.leadDate) : undefined,
      marketType: isSet(object.marketType) ? marketTypeFromJSON(object.marketType) : undefined,
      leadType: isSet(object.leadType) ? leadTypeFromJSON(object.leadType) : undefined,
      agency: isSet(object.agency) ? Consumer_Agency.fromJSON(object.agency) : undefined,
      campaign: isSet(object.campaign) ? Consumer_Campaign.fromJSON(object.campaign) : undefined,
      dialer: isSet(object.dialer) ? Consumer_Dialer.fromJSON(object.dialer) : undefined,
      leadList: isSet(object.leadList) ? Consumer_LeadList.fromJSON(object.leadList) : undefined,
      leadDateTimestamp: isSet(object.leadDateTimestamp) ? Number(object.leadDateTimestamp) : undefined,
      medicare: isSet(object.medicare) ? Consumer_Medicare.fromJSON(object.medicare) : undefined,
      leadSource: isSet(object.leadSource) ? consumer_LeadSourceFromJSON(object.leadSource) : undefined,
      veteranStatus: isSet(object.veteranStatus) ? consumer_VeteranStatusFromJSON(object.veteranStatus) : undefined,
      workStatus: isSet(object.workStatus) ? consumer_WorkStatusFromJSON(object.workStatus) : undefined,
      benefits: Array.isArray(object?.benefits) ? object.benefits.map((e: any) => Benefit.fromJSON(e)) : [],
      language: isSet(object.language) ? String(object.language) : undefined,
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : undefined,
      timezone: isSet(object.timezone) ? String(object.timezone) : undefined,
      identifiers: isSet(object.identifiers) ? IdentifierContainer.fromJSON(object.identifiers) : undefined,
      source: isSet(object.source) ? sourceFromJSON(object.source) : undefined,
      sourceCustom: isSet(object.sourceCustom) ? String(object.sourceCustom) : undefined,
      phoneInvalid: isSet(object.phoneInvalid) ? Boolean(object.phoneInvalid) : undefined,
      notes: isSet(object.notes) ? Note.fromJSON(object.notes) : undefined,
      dateOfBirth: isSet(object.dateOfBirth) ? fromJsonTimestamp(object.dateOfBirth) : undefined,
    };
  },

  toJSON(message: Consumer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.middleName !== undefined && (obj.middleName = message.middleName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.nameSuffix !== undefined && (obj.nameSuffix = message.nameSuffix);
    message.email !== undefined && (obj.email = message.email);
    message.phone !== undefined && (obj.phone = message.phone);
    message.address !== undefined && (obj.address = message.address ? Address.toJSON(message.address) : undefined);
    message.birthDate !== undefined &&
      (obj.birthDate = message.birthDate ? DateContainer.toJSON(message.birthDate) : undefined);
    message.ssn !== undefined && (obj.ssn = message.ssn);
    message.gender !== undefined &&
      (obj.gender = message.gender !== undefined ? genderToJSON(message.gender) : undefined);
    message.okToEmail !== undefined && (obj.okToEmail = message.okToEmail);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.leadDate !== undefined && (obj.leadDate = message.leadDate);
    message.marketType !== undefined &&
      (obj.marketType = message.marketType !== undefined ? marketTypeToJSON(message.marketType) : undefined);
    message.leadType !== undefined &&
      (obj.leadType = message.leadType !== undefined ? leadTypeToJSON(message.leadType) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? Consumer_Agency.toJSON(message.agency) : undefined);
    message.campaign !== undefined &&
      (obj.campaign = message.campaign ? Consumer_Campaign.toJSON(message.campaign) : undefined);
    message.dialer !== undefined && (obj.dialer = message.dialer ? Consumer_Dialer.toJSON(message.dialer) : undefined);
    message.leadList !== undefined &&
      (obj.leadList = message.leadList ? Consumer_LeadList.toJSON(message.leadList) : undefined);
    message.leadDateTimestamp !== undefined && (obj.leadDateTimestamp = Math.round(message.leadDateTimestamp));
    message.medicare !== undefined &&
      (obj.medicare = message.medicare ? Consumer_Medicare.toJSON(message.medicare) : undefined);
    message.leadSource !== undefined &&
      (obj.leadSource = message.leadSource !== undefined ? consumer_LeadSourceToJSON(message.leadSource) : undefined);
    message.veteranStatus !== undefined && (obj.veteranStatus = message.veteranStatus !== undefined
      ? consumer_VeteranStatusToJSON(message.veteranStatus)
      : undefined);
    message.workStatus !== undefined &&
      (obj.workStatus = message.workStatus !== undefined ? consumer_WorkStatusToJSON(message.workStatus) : undefined);
    if (message.benefits) {
      obj.benefits = message.benefits.map((e) => e ? Benefit.toJSON(e) : undefined);
    } else {
      obj.benefits = [];
    }
    message.language !== undefined && (obj.language = message.language);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.timezone !== undefined && (obj.timezone = message.timezone);
    message.identifiers !== undefined &&
      (obj.identifiers = message.identifiers ? IdentifierContainer.toJSON(message.identifiers) : undefined);
    message.source !== undefined &&
      (obj.source = message.source !== undefined ? sourceToJSON(message.source) : undefined);
    message.sourceCustom !== undefined && (obj.sourceCustom = message.sourceCustom);
    message.phoneInvalid !== undefined && (obj.phoneInvalid = message.phoneInvalid);
    message.notes !== undefined && (obj.notes = message.notes ? Note.toJSON(message.notes) : undefined);
    message.dateOfBirth !== undefined && (obj.dateOfBirth = message.dateOfBirth.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer>, I>>(base?: I): Consumer {
    return Consumer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer>, I>>(object: I): Consumer {
    const message = createBaseConsumer();
    message.id = object.id ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.middleName = object.middleName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.nameSuffix = object.nameSuffix ?? undefined;
    message.email = object.email ?? undefined;
    message.phone = object.phone ?? undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    message.birthDate = (object.birthDate !== undefined && object.birthDate !== null)
      ? DateContainer.fromPartial(object.birthDate)
      : undefined;
    message.ssn = object.ssn ?? undefined;
    message.gender = object.gender ?? undefined;
    message.okToEmail = object.okToEmail ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.leadDate = object.leadDate ?? undefined;
    message.marketType = object.marketType ?? undefined;
    message.leadType = object.leadType ?? undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? Consumer_Agency.fromPartial(object.agency)
      : undefined;
    message.campaign = (object.campaign !== undefined && object.campaign !== null)
      ? Consumer_Campaign.fromPartial(object.campaign)
      : undefined;
    message.dialer = (object.dialer !== undefined && object.dialer !== null)
      ? Consumer_Dialer.fromPartial(object.dialer)
      : undefined;
    message.leadList = (object.leadList !== undefined && object.leadList !== null)
      ? Consumer_LeadList.fromPartial(object.leadList)
      : undefined;
    message.leadDateTimestamp = object.leadDateTimestamp ?? undefined;
    message.medicare = (object.medicare !== undefined && object.medicare !== null)
      ? Consumer_Medicare.fromPartial(object.medicare)
      : undefined;
    message.leadSource = object.leadSource ?? undefined;
    message.veteranStatus = object.veteranStatus ?? undefined;
    message.workStatus = object.workStatus ?? undefined;
    message.benefits = object.benefits?.map((e) => Benefit.fromPartial(e)) || [];
    message.language = object.language ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.timezone = object.timezone ?? undefined;
    message.identifiers = (object.identifiers !== undefined && object.identifiers !== null)
      ? IdentifierContainer.fromPartial(object.identifiers)
      : undefined;
    message.source = object.source ?? undefined;
    message.sourceCustom = object.sourceCustom ?? undefined;
    message.phoneInvalid = object.phoneInvalid ?? undefined;
    message.notes = (object.notes !== undefined && object.notes !== null) ? Note.fromPartial(object.notes) : undefined;
    message.dateOfBirth = object.dateOfBirth ?? undefined;
    return message;
  },
};

function createBaseConsumer_Dialer(): Consumer_Dialer {
  return {
    resultCategory: undefined,
    result: undefined,
    resultCategoryAgent: undefined,
    resultAgent: undefined,
    lastCallTry: undefined,
    lastCallConnected: undefined,
    lastCallEnded: undefined,
    lastAddedToHopper: undefined,
    lastCallState: undefined,
    from: undefined,
    totalCalls: undefined,
    totalCallsInboundAnswered: undefined,
    lastCallInboundAnswered: undefined,
    totalCallsInboundBillable: undefined,
    lastCallInboundBillable: undefined,
    totalCallsInbound: undefined,
    lastCallInbound: undefined,
    totalCallsOutbound: undefined,
    lastCallOutbound: undefined,
  };
}

export const Consumer_Dialer = {
  encode(message: Consumer_Dialer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultCategory !== undefined) {
      writer.uint32(8).int32(message.resultCategory);
    }
    if (message.result !== undefined) {
      writer.uint32(16).int32(message.result);
    }
    if (message.resultCategoryAgent !== undefined) {
      writer.uint32(24).int32(message.resultCategoryAgent);
    }
    if (message.resultAgent !== undefined) {
      writer.uint32(32).int32(message.resultAgent);
    }
    if (message.lastCallTry !== undefined) {
      writer.uint32(40).int64(message.lastCallTry);
    }
    if (message.lastCallConnected !== undefined) {
      writer.uint32(48).int64(message.lastCallConnected);
    }
    if (message.lastCallEnded !== undefined) {
      writer.uint32(56).int64(message.lastCallEnded);
    }
    if (message.lastAddedToHopper !== undefined) {
      writer.uint32(64).int64(message.lastAddedToHopper);
    }
    if (message.lastCallState !== undefined) {
      writer.uint32(72).int32(message.lastCallState);
    }
    if (message.from !== undefined) {
      writer.uint32(82).string(message.from);
    }
    if (message.totalCalls !== undefined) {
      writer.uint32(88).int64(message.totalCalls);
    }
    if (message.totalCallsInboundAnswered !== undefined) {
      writer.uint32(96).int32(message.totalCallsInboundAnswered);
    }
    if (message.lastCallInboundAnswered !== undefined) {
      writer.uint32(104).int64(message.lastCallInboundAnswered);
    }
    if (message.totalCallsInboundBillable !== undefined) {
      writer.uint32(112).int32(message.totalCallsInboundBillable);
    }
    if (message.lastCallInboundBillable !== undefined) {
      writer.uint32(120).int64(message.lastCallInboundBillable);
    }
    if (message.totalCallsInbound !== undefined) {
      writer.uint32(128).int32(message.totalCallsInbound);
    }
    if (message.lastCallInbound !== undefined) {
      writer.uint32(136).int64(message.lastCallInbound);
    }
    if (message.totalCallsOutbound !== undefined) {
      writer.uint32(144).int32(message.totalCallsOutbound);
    }
    if (message.lastCallOutbound !== undefined) {
      writer.uint32(152).int64(message.lastCallOutbound);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Dialer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Dialer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resultCategory = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.resultCategoryAgent = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.resultAgent = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lastCallTry = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.lastCallConnected = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.lastCallEnded = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.lastAddedToHopper = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.lastCallState = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.from = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.totalCalls = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.totalCallsInboundAnswered = reader.int32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.lastCallInboundAnswered = longToNumber(reader.int64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.totalCallsInboundBillable = reader.int32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.lastCallInboundBillable = longToNumber(reader.int64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.totalCallsInbound = reader.int32();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.lastCallInbound = longToNumber(reader.int64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.totalCallsOutbound = reader.int32();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.lastCallOutbound = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Dialer {
    return {
      resultCategory: isSet(object.resultCategory)
        ? consumer_Dialer_ResultCategoryFromJSON(object.resultCategory)
        : undefined,
      result: isSet(object.result) ? consumer_Dialer_ResultFromJSON(object.result) : undefined,
      resultCategoryAgent: isSet(object.resultCategoryAgent)
        ? consumer_Dialer_ResultCategoryFromJSON(object.resultCategoryAgent)
        : undefined,
      resultAgent: isSet(object.resultAgent) ? consumer_Dialer_ResultFromJSON(object.resultAgent) : undefined,
      lastCallTry: isSet(object.lastCallTry) ? Number(object.lastCallTry) : undefined,
      lastCallConnected: isSet(object.lastCallConnected) ? Number(object.lastCallConnected) : undefined,
      lastCallEnded: isSet(object.lastCallEnded) ? Number(object.lastCallEnded) : undefined,
      lastAddedToHopper: isSet(object.lastAddedToHopper) ? Number(object.lastAddedToHopper) : undefined,
      lastCallState: isSet(object.lastCallState) ? consumer_Dialer_CallStateFromJSON(object.lastCallState) : undefined,
      from: isSet(object.from) ? String(object.from) : undefined,
      totalCalls: isSet(object.totalCalls) ? Number(object.totalCalls) : undefined,
      totalCallsInboundAnswered: isSet(object.totalCallsInboundAnswered)
        ? Number(object.totalCallsInboundAnswered)
        : undefined,
      lastCallInboundAnswered: isSet(object.lastCallInboundAnswered)
        ? Number(object.lastCallInboundAnswered)
        : undefined,
      totalCallsInboundBillable: isSet(object.totalCallsInboundBillable)
        ? Number(object.totalCallsInboundBillable)
        : undefined,
      lastCallInboundBillable: isSet(object.lastCallInboundBillable)
        ? Number(object.lastCallInboundBillable)
        : undefined,
      totalCallsInbound: isSet(object.totalCallsInbound) ? Number(object.totalCallsInbound) : undefined,
      lastCallInbound: isSet(object.lastCallInbound) ? Number(object.lastCallInbound) : undefined,
      totalCallsOutbound: isSet(object.totalCallsOutbound) ? Number(object.totalCallsOutbound) : undefined,
      lastCallOutbound: isSet(object.lastCallOutbound) ? Number(object.lastCallOutbound) : undefined,
    };
  },

  toJSON(message: Consumer_Dialer): unknown {
    const obj: any = {};
    message.resultCategory !== undefined && (obj.resultCategory = message.resultCategory !== undefined
      ? consumer_Dialer_ResultCategoryToJSON(message.resultCategory)
      : undefined);
    message.result !== undefined &&
      (obj.result = message.result !== undefined ? consumer_Dialer_ResultToJSON(message.result) : undefined);
    message.resultCategoryAgent !== undefined && (obj.resultCategoryAgent = message.resultCategoryAgent !== undefined
      ? consumer_Dialer_ResultCategoryToJSON(message.resultCategoryAgent)
      : undefined);
    message.resultAgent !== undefined && (obj.resultAgent = message.resultAgent !== undefined
      ? consumer_Dialer_ResultToJSON(message.resultAgent)
      : undefined);
    message.lastCallTry !== undefined && (obj.lastCallTry = Math.round(message.lastCallTry));
    message.lastCallConnected !== undefined && (obj.lastCallConnected = Math.round(message.lastCallConnected));
    message.lastCallEnded !== undefined && (obj.lastCallEnded = Math.round(message.lastCallEnded));
    message.lastAddedToHopper !== undefined && (obj.lastAddedToHopper = Math.round(message.lastAddedToHopper));
    message.lastCallState !== undefined && (obj.lastCallState = message.lastCallState !== undefined
      ? consumer_Dialer_CallStateToJSON(message.lastCallState)
      : undefined);
    message.from !== undefined && (obj.from = message.from);
    message.totalCalls !== undefined && (obj.totalCalls = Math.round(message.totalCalls));
    message.totalCallsInboundAnswered !== undefined &&
      (obj.totalCallsInboundAnswered = Math.round(message.totalCallsInboundAnswered));
    message.lastCallInboundAnswered !== undefined &&
      (obj.lastCallInboundAnswered = Math.round(message.lastCallInboundAnswered));
    message.totalCallsInboundBillable !== undefined &&
      (obj.totalCallsInboundBillable = Math.round(message.totalCallsInboundBillable));
    message.lastCallInboundBillable !== undefined &&
      (obj.lastCallInboundBillable = Math.round(message.lastCallInboundBillable));
    message.totalCallsInbound !== undefined && (obj.totalCallsInbound = Math.round(message.totalCallsInbound));
    message.lastCallInbound !== undefined && (obj.lastCallInbound = Math.round(message.lastCallInbound));
    message.totalCallsOutbound !== undefined && (obj.totalCallsOutbound = Math.round(message.totalCallsOutbound));
    message.lastCallOutbound !== undefined && (obj.lastCallOutbound = Math.round(message.lastCallOutbound));
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Dialer>, I>>(base?: I): Consumer_Dialer {
    return Consumer_Dialer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Dialer>, I>>(object: I): Consumer_Dialer {
    const message = createBaseConsumer_Dialer();
    message.resultCategory = object.resultCategory ?? undefined;
    message.result = object.result ?? undefined;
    message.resultCategoryAgent = object.resultCategoryAgent ?? undefined;
    message.resultAgent = object.resultAgent ?? undefined;
    message.lastCallTry = object.lastCallTry ?? undefined;
    message.lastCallConnected = object.lastCallConnected ?? undefined;
    message.lastCallEnded = object.lastCallEnded ?? undefined;
    message.lastAddedToHopper = object.lastAddedToHopper ?? undefined;
    message.lastCallState = object.lastCallState ?? undefined;
    message.from = object.from ?? undefined;
    message.totalCalls = object.totalCalls ?? undefined;
    message.totalCallsInboundAnswered = object.totalCallsInboundAnswered ?? undefined;
    message.lastCallInboundAnswered = object.lastCallInboundAnswered ?? undefined;
    message.totalCallsInboundBillable = object.totalCallsInboundBillable ?? undefined;
    message.lastCallInboundBillable = object.lastCallInboundBillable ?? undefined;
    message.totalCallsInbound = object.totalCallsInbound ?? undefined;
    message.lastCallInbound = object.lastCallInbound ?? undefined;
    message.totalCallsOutbound = object.totalCallsOutbound ?? undefined;
    message.lastCallOutbound = object.lastCallOutbound ?? undefined;
    return message;
  },
};

function createBaseConsumer_Agency(): Consumer_Agency {
  return { id: undefined, name: undefined };
}

export const Consumer_Agency = {
  encode(message: Consumer_Agency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Agency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Agency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Agency {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Consumer_Agency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Agency>, I>>(base?: I): Consumer_Agency {
    return Consumer_Agency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Agency>, I>>(object: I): Consumer_Agency {
    const message = createBaseConsumer_Agency();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseConsumer_Campaign(): Consumer_Campaign {
  return { id: undefined, name: undefined };
}

export const Consumer_Campaign = {
  encode(message: Consumer_Campaign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Campaign {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Campaign();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Campaign {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Consumer_Campaign): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Campaign>, I>>(base?: I): Consumer_Campaign {
    return Consumer_Campaign.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Campaign>, I>>(object: I): Consumer_Campaign {
    const message = createBaseConsumer_Campaign();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseConsumer_LeadList(): Consumer_LeadList {
  return { id: undefined, name: undefined };
}

export const Consumer_LeadList = {
  encode(message: Consumer_LeadList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_LeadList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_LeadList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_LeadList {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Consumer_LeadList): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_LeadList>, I>>(base?: I): Consumer_LeadList {
    return Consumer_LeadList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_LeadList>, I>>(object: I): Consumer_LeadList {
    const message = createBaseConsumer_LeadList();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseConsumer_Medicare(): Consumer_Medicare {
  return { number: undefined };
}

export const Consumer_Medicare = {
  encode(message: Consumer_Medicare, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== undefined) {
      writer.uint32(10).string(message.number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumer_Medicare {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumer_Medicare();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Consumer_Medicare {
    return { number: isSet(object.number) ? String(object.number) : undefined };
  },

  toJSON(message: Consumer_Medicare): unknown {
    const obj: any = {};
    message.number !== undefined && (obj.number = message.number);
    return obj;
  },

  create<I extends Exact<DeepPartial<Consumer_Medicare>, I>>(base?: I): Consumer_Medicare {
    return Consumer_Medicare.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Consumer_Medicare>, I>>(object: I): Consumer_Medicare {
    const message = createBaseConsumer_Medicare();
    message.number = object.number ?? undefined;
    return message;
  },
};

function createBaseBenefit(): Benefit {
  return { id: "", name: "", active: undefined };
}

export const Benefit = {
  encode(message: Benefit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.active !== undefined) {
      writer.uint32(24).bool(message.active);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Benefit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBenefit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.active = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Benefit {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      active: isSet(object.active) ? Boolean(object.active) : undefined,
    };
  },

  toJSON(message: Benefit): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.active !== undefined && (obj.active = message.active);
    return obj;
  },

  create<I extends Exact<DeepPartial<Benefit>, I>>(base?: I): Benefit {
    return Benefit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Benefit>, I>>(object: I): Benefit {
    const message = createBaseBenefit();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.active = object.active ?? undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
